import './App.scss';
import './resources/styles/antd.scss';
import './resources/styles/antd.notification.scss';
import './resources/styles/Baremetrics.scss';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import { axiosAPI } from './utils/axiosAPI';
import { checkIsGlobalAdmin } from './utils/helpers/checkIsGlobalAdmin';

import GlobalAdminRouter from './components/common/router/GlobalAdminRouter';
import Router from './components/common/router/Router';
import UnauthorizedRouter from './components/common/router/Unauthorized/UnauthorizedRouter';

import withAntdConfigProvider from './components/hocs/AntdConfigProvider';
import withAuthenticatorProvider from './components/hocs/AuthenticatorProvider';
import withRouterProvider from './components/hocs/RouterProvider';
import withStoreProvider from './components/hocs/StoreProvider';
import withSubscriptionLimitsProvider from './components/hocs/SubscriptionLimitsProvider';

import { clearBillingSettings } from './components/pages/account/billing/store/billingSettings.actions';
import { setUsers } from './components/pages/account/store/accountSettings.actions';
import { setIsAddURLsActive } from './components/pages/contentMonitor/mainPage/store/contentMonitorMain.actions';
import { setIsImportURLActive } from './components/pages/contentOptimizer/mainPage/store/contentOptimizerMain.actions';

import {
    setErrorStatus,
    setIsSubscriptionExpired,
    setUserData,
} from './userBrowserSettings/store/browserSettings.actions';
import { selectUserData } from './userBrowserSettings/store/browserSettings.selectors';

import { withUppoloProvider } from './components/hocs/UppoloProvider';
import { statusCodes } from './constants/statusCodes';
import { useOrganizationSlug } from './utils/hooks/useOrganizationSlug';

const App = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const userData = useSelector(selectUserData);
    const organizationSlug = useOrganizationSlug();

    axiosAPI.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response.status === statusCodes.unauthorized) {
                if (localStorage.getItem('loggedIn') === 'true') {
                    localStorage.setItem('loggedIn', false);
                    dispatch(setUserData(null));
                    dispatch(setUsers([]));
                    dispatch(clearBillingSettings());
                    dispatch(setIsSubscriptionExpired(false));
                    navigate('/');
                }

                return;
            }

            if (
                error.response.status === statusCodes.forbidden ||
                error.response.status === statusCodes.notFound
            ) {
                dispatch(setErrorStatus(error.response.status));

                return;
            }

            if (error.response.status === statusCodes.subscriptionExpired) {
                dispatch(setIsSubscriptionExpired(true));

                return;
            }

            return Promise.reject(error);
        }
    );

    const getRouter = () => {
        if (userData?.active) {
            return checkIsGlobalAdmin(userData?.roles) ? (
                <GlobalAdminRouter />
            ) : (
                <Router />
            );
        } else {
            return <UnauthorizedRouter />;
        }
    };

    useEffect(() => {
        if (location.pathname !== `/${organizationSlug}/optimizer`) {
            // Optimizer Import URL Content toggle
            dispatch(setIsImportURLActive(false));
        }

        if (location.pathname !== `/${organizationSlug}/monitor`) {
            // Monitor Add URLs toggle
            dispatch(setIsAddURLsActive(false));
        }

        // eslint-disable-next-line
    }, [location.pathname]);

    return (
        <div style={{ height: '100vh' }} className='App'>
            <Helmet>
                <meta name='theme-color' content='#ffffff' />

                {/* The modal window from HelpScout is added here */}
                <script type='text/javascript'>{`!function(e,t,n) { 
                    function a(){ 
                        var e=t.getElementsByTagName("script")[0],n=t.createElement("script");
                        n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)
                    }
                    if(e.Beacon=n=function(t,n,a) {
                        e.Beacon.readyQueue.push({method:t,options:n,data:a})
                    },
                    n.readyQueue=[],"complete"===t.readyState)
                        
                    return a();
                    e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){})`}</script>

                <script type='text/javascript'>{`window.Beacon('init', '744a4ffd-a623-48cd-9322-4c4ad0878337')`}</script>
            </Helmet>
            {getRouter()}
        </div>
    );
};

export default withStoreProvider(
    withAntdConfigProvider(
        withAuthenticatorProvider(
            withRouterProvider(
                withSubscriptionLimitsProvider(withUppoloProvider(App))
            )
        )
    )
);
