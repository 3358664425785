export const getUnfinishedBriefIds = (briefs) => {
    return briefs
        ?.filter(
            (item) =>
                !(
                    item?.queue_status === 'completed' ||
                    item?.queue_status === 'failed' ||
                    !item?.queue_status
                )
        )
        .map((item) => item?.id);
};
