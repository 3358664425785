import React from 'react';
import { useSelector } from 'react-redux';

import { Tooltip, Typography } from 'antd';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectSubscriptionLimits } from '../../../account/billing/store/billingSettings.selectors';

const classes = {
    keywordCredits: 'tabs-credits',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    textDotted: 'text-decoration-dotted',
};

const ContentMonitorCredits = () => {
    const darkMode = useSelector(selectDarkMode);

    const subscriptionLimits = useSelector(selectSubscriptionLimits);

    return (
        <Tooltip
            title={'Content Monitor pages remaining on your subscription.'}
            overlayClassName={`${classes.tooltipOverlay}
                               ${darkMode ? classes.tooltipOverlayDark : ''}`}
        >
            <Typography className={classes.keywordCredits}>
                <span className={classes.textDotted}>
                    {`${
                        subscriptionLimits?.content_monitors -
                            subscriptionLimits?.content_monitors_used <
                        0
                            ? 0
                            : subscriptionLimits?.content_monitors -
                                  subscriptionLimits?.content_monitors_used || 0
                    }/${
                        subscriptionLimits?.content_monitors || 0
                    } monitors remaining`}
                </span>
            </Typography>
        </Tooltip>
    );
};

export default ContentMonitorCredits;
