import React, { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Layout, Switch, Tabs, Typography, theme } from 'antd';

import { contentMonitorTabs } from '../../../../constants/contentMonitorMainTabs';
import {
    errorNotificationMessage,
    notificationType,
} from '../../../../constants/notificationType';
import { contentMonitorPathes } from '../../../../constants/queryPathes';
import { statusCodes } from '../../../../constants/statusCodes';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../utils/helpers/openNotification';
import { useOrganizationSlug } from '../../../../utils/hooks/useOrganizationSlug';

import CommonPageLayout from '../../../common/layout/CommonPageLayout';
import NotificationCard from '../../../common/notifications/NotificationCard';

import ContentMonitorCredits from './content/ContentMonitorCredits';
import ContentMonitorMainContent from './content/ContentMonitorMainContent';
import MonitorsResultsFilters from './content/filters/MonitorsResultsFilters';
import ContentMonitorMainPageHeader from './content/header/ContentMonitorMainPageHeader';
import ExportMonitorButton from './content/ExportMonitorButton';

import {
    clearContentMonitorMain,
    setContentMonitorChartData,
    setContentMonitorData,
    setIsAddURLsActive,
    updateContentMonitorMainTab,
} from './store/contentMonitorMain.actions';
import {
    selectContentMonitorMainTab,
    selectContentMonitorQueryParams,
    selectIsAddURLsActive,
    selectMonitorsSortDescending,
    selectMonitorsSortKey,
} from './store/contentMonitorMain.selectors';

import { selectSubscriptionLimits } from '../../account/billing/store/billingSettings.selectors';

import './ContentMonitorMainPage.scss';

const { Content } = Layout;

const classes = {
    content: 'container content-monitor-container content-optimizer-container',
    contentDark: 'container-dark content-optimizer-container-dark',
    monitorTabExtraContent: 'monitor-tab-extra-content',
    monitorRemainingWrapper: 'monitor-remaining-wrapper',

    extraContentWrapper: 'd-flex justify-content-between align-items-center',
    addURLsSwitchWrapper:
        'd-flex flex-center justify-content-between align-items-center',
    addURLsSwitch:
        'd-flex flex-center justify-content-between align-items-center cursor-pointer',
    importURLButton: 'import-url-button',

    monitorResultsFilterWrapper: 'monitor-results-filter-wrapper',

    addURLsText: 'add-urls-text',

    warningNotification:
        'optimizer-date-warning-notification monitor-date-warning-notification',
    title: 'title',
};

const ContentMonitorMainPage = () => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const currentContentMonitorTabKey = useSelector(
        selectContentMonitorMainTab
    );

    const organizationSlug = useOrganizationSlug();
    const subscriptionLimits = useSelector(selectSubscriptionLimits);

    const isAddURLsActive = useSelector(selectIsAddURLsActive);
    const monitorQueryParams = useSelector(selectContentMonitorQueryParams);
    const monitorsSortKey = useSelector(selectMonitorsSortKey);
    const monitorsSortDescending = useSelector(selectMonitorsSortDescending);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const handleChandeIsAddURLsActive = () => {
        dispatch(setIsAddURLsActive(!isAddURLsActive));
    };

    const tabsItems = [
        {
            key: contentMonitorTabs.rankabilityScore.key,
            label: contentMonitorTabs.rankabilityScore.label,
            children: <ContentMonitorMainContent />,
        },
    ];

    const handleMonitorTabChange = (monitorTabKey) => {
        dispatch(updateContentMonitorMainTab(monitorTabKey));
    };

    useEffect(() => {
        let queryParams = {
            dateRange: monitorQueryParams?.dateRange,
            project: monitorQueryParams?.project,
            search: monitorQueryParams?.search,
            users: JSON.stringify(monitorQueryParams?.users),
            dateRangePicker: JSON.stringify(
                monitorQueryParams?.dateRangePicker
            ),
            sort: monitorsSortKey,
            order: monitorsSortDescending ? 'asc' : 'desc',
        };

        axiosAPI
            .get(
                contentMonitorPathes.baseContentMonitorUrl,
                {
                    params: queryParams,
                },
                {
                    ...getAxiosHeaders(),
                }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    batch(() => {
                        dispatch(
                            setContentMonitorData(result.data?.monitor_list)
                        );
                        dispatch(
                            setContentMonitorChartData(result.data?.chart_data)
                        );
                    });
                }
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            });

        // eslint-disable-next-line
    }, [
        monitorQueryParams,
        currentContentMonitorTabKey,
        monitorsSortKey,
        monitorsSortDescending,
    ]);

    useEffect(() => {
        return () => {
            dispatch(clearContentMonitorMain());
        };

        // eslint-disable-next-line
    }, []);

    const extraContentElement = (
        <div className={classes.extraContentWrapper}>
            <div className={classes.addURLsSwitchWrapper}>
                <div
                    onClick={handleChandeIsAddURLsActive}
                    className={classes.addURLsSwitch}
                >
                    <Typography className={classes.addURLsText}>
                        Add URLs
                    </Typography>
                    <Switch
                        className={classes.importURLButton}
                        checked={isAddURLsActive}
                        onChange={handleChandeIsAddURLsActive}
                        size='small'
                    />
                </div>

                <div className={classes.monitorResultsFilterWrapper}>
                    <MonitorsResultsFilters />
                </div>
            </div>
            <div className={classes.monitorTabExtraContent}>
                <div>
                    <ExportMonitorButton />
                </div>

                <div className={classes.monitorRemainingWrapper}>
                    <ContentMonitorCredits />
                </div>
            </div>
        </div>
    );

    const notificationCardContent = (
        <Typography
            className={`${classes.title} ${darkMode ? 'dark-mode' : ''}`}
        >
            Your current subscription only allows up to{' '}
            {subscriptionLimits?.content_monitors} active Content Monitor pages.
            By downgrading your subscription,{' '}
            {subscriptionLimits?.content_monitors_used -
                subscriptionLimits?.content_monitors}{' '}
            excess Content Monitor pages were disabled below. So If you'd like
            to reactivate all your Content Monitor pages, you can upgrade your
            subscription{' '}
            <Link to={`/${organizationSlug}/account/plans`}>here</Link>. Or
            please choose the {subscriptionLimits?.content_monitors} Content
            Monitor pages you'd like to track and delete the rest.
        </Typography>
    );

    return (
        <>
            <ContentMonitorMainPageHeader />

            <CommonPageLayout>
                {subscriptionLimits?.content_monitors_used >
                    subscriptionLimits?.content_monitors && (
                    <div className={`${classes.warningNotification}`}>
                        <NotificationCard content={notificationCardContent} />
                    </div>
                )}
                <Content
                    style={{
                        background: colorBgContainer,
                    }}
                    className={`${classes.content}
                            ${darkMode ? classes.contentDark : ''}`}
                >
                    <Tabs
                        items={tabsItems}
                        onChange={handleMonitorTabChange}
                        defaultActiveKey={
                            contentMonitorTabs.rankabilityScore.key
                        }
                        activeKey={currentContentMonitorTabKey}
                        tabBarExtraContent={extraContentElement}
                    />
                </Content>
            </CommonPageLayout>
        </>
    );
};

export default ContentMonitorMainPage;
