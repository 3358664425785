import React from 'react';
import { useSelector } from 'react-redux';

import { Col, Row, Typography } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

const classes = {
    chartLabel: 'chart-label',
    mainLabel: 'main-label',
};

const UsageChartLabel = ({ mainLabel }) => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <Row
            justify='space-between'
            className={`${classes.chartLabel} ${darkMode ? 'dark-mode' : ''}`}
        >
            <Col className='d-flex align-items-center'>
                <Typography
                    className={`${classes.mainLabel} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                >
                    {mainLabel}
                </Typography>
            </Col>
        </Row>
    );
};

export default UsageChartLabel;
