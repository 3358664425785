import React from 'react';
import { useSelector } from 'react-redux';

import { Col, Row, Typography } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import { getRandomId } from '../../../utils/helpers/idGenerator';

import CommonPageLayout from '../../common/layout/CommonPageLayout';
import HomeCard from './HomeCard';
import HomePageHeader from './HomePageHeader';

import { homeCardContent } from './constants';

import RankabilityRoundedDarkLogoIcon from '../../../resources/icons/RankabilityRoundedDarkLogoIcon';
import RankabilityRoundedLightLogoIcon from '../../../resources/icons/RankabilityRoundedLightLogoIcon';
import './HomePage.scss';

const { Title } = Typography;

const classes = {
    homeContent: 'home-content-wrapper',
    homePageTitleWrapper:
        'home-title-wrapper page-title-wrapper d-flex flex-column flex-center',
    homePageTitle: 'page-title d-flex',
    homeCardColumn: 'home-card-column',
};

const HomePage = () => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <>
            <HomePageHeader />

            <CommonPageLayout>
                <div className={classes.homePageTitleWrapper}>
                    <Title level={2} className={classes.homePageTitle}>
                        {darkMode ? <RankabilityRoundedDarkLogoIcon /> : <RankabilityRoundedLightLogoIcon/>} 
                        <span id='home-page-title-text'>How can Rankability help you today?</span>
                    </Title>
                </div>

                <div
                    className={`${classes.homeContent} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                >
                    <Row gutter={24}>
                        {homeCardContent?.map((homeItem) => (
                            <Col
                                key={getRandomId()}
                                span={12}
                                className={`${classes.homeCardColumn}`}
                            >
                                <HomeCard
                                    key={getRandomId()}
                                    homeContent={homeItem}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
            </CommonPageLayout>
        </>
    );
};

export default HomePage;
