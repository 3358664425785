import {
    billingPlanIntervals,
    priceCurrency,
} from '../../components/pages/account/billing/BillingConstants';

export const getPriceId = (planData, planType) => {
    const priceId = planData?.prices?.find(
        (price) =>
            price?.currency === priceCurrency.usd &&
            price?.reccuring?.interval === billingPlanIntervals[planType]
    )?.id;

    return priceId || '';
};
