import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Form, Input, Modal, Typography } from 'antd';

import { notificationType } from '../../../../../constants/notificationType';
import { userDataPathes } from '../../../../../constants/queryPathes';
import { statusCodes } from '../../../../../constants/statusCodes';

import CloseIcon from '../../../../../resources/icons/CloseIcon';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import {
    selectDarkMode,
    selectUserData,
} from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { updateBillingEmail } from '../../billing/store/billingSettings.actions';
import { selectCurrentSubscriptionCustomerEmail } from '../../billing/store/billingSettings.selectors';

import { axiosAPI } from '../../../../../utils/axiosAPI';
import { checkIsAdmin } from '../../../../../utils/helpers/checkIsAdmin';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../utils/helpers/openNotification';
import { useOpen } from '../../../../../utils/hooks/useOpen';

import { setUserData } from '../../../../../userBrowserSettings/store/browserSettings.actions';

const { Link } = Typography;

const classes = {
    modalWrapper: 'change-password-modal',
    modalWrapperDark: 'change-password-modal-dark',
    modalFormFieldsWrapper: 'form-fields-wrapper',
    modalFormButtonsWrapper: 'form-buttons-wrapper d-flex justify-content-end',
    link: 'link-hover-highlight',
};

const ChangeEmailModal = ({ notification }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const darkMode = useSelector(selectDarkMode);
    const user = useSelector(selectUserData);
    const billingEmail = useSelector(selectCurrentSubscriptionCustomerEmail);

    const { isOpen, handleOpen } = useOpen();

    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const isAdmin = checkIsAdmin(user?.roles?.[0]?.name);

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    useEffect(() => {
        if (isOpen) {
            form.setFieldsValue({ billing_email: billingEmail || '' });
        }
    }, [isOpen, billingEmail, form]);

    const handleCloseModal = () => {
        form.resetFields();
    };

    const handleChangeEmail = (values) => {
        if (notification) {
        } else {
            setIsLoading(true);

            const updateEmailEndpoint = `${userDataPathes.users}/${user?.id}${
                isAdmin ? userDataPathes.changeAdminEmail : ''
            }`;

            const { email, confirm_email, billing_email } = values;

            const requestData = isAdmin
                ? { email, confirm_email, billing_email }
                : { email };

            const updateAdminEmailSuccessCallback = () => {
                dispatch(updateBillingEmail(billing_email));
            };

            axiosAPI
                .patch(updateEmailEndpoint, requestData, {
                    ...getAxiosHeaders(),
                })
                .then((result) => {
                    if (result?.status === statusCodes.success) {
                        dispatch(setUserData(result.data));
                        isAdmin && updateAdminEmailSuccessCallback();

                        openNotification(
                            notificationType.success,
                            'Success',
                            'Email has been updated successfully'
                        );
                        setIsDisabled(true);
                        handleOpen();
                    }
                })
                .catch((error) => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        error?.response?.data?.errors?.email?.[0] ||
                            error?.response?.data?.message
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleBlur = (_) => {
        if (!form.getFieldsError().flatMap((x) => x.errors).length) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    };

    return (
        <>
            <Link
                underline
                onClick={handleOpen}
                className={`${classes.link}
                            ${darkMode ? 'dark-mode' : ''}`}
            >
                Change email
            </Link>
            <Modal
                centered
                title={
                    notification ? 'Change Notification Email' : 'Change Email'
                }
                open={isOpen}
                onCancel={handleOpen}
                footer={null}
                closeIcon={<CloseIcon color={closeModalIconColor} />}
                wrapClassName={`${classes.modalWrapper}
                                ${darkMode ? classes.modalWrapperDark : ''}`}
                afterClose={handleCloseModal}
            >
                <Form
                    form={form}
                    layout='vertical'
                    className={classes.profileForm}
                    onFinish={handleChangeEmail}
                >
                    <div className={classes.modalFormFieldsWrapper}>
                        <Form.Item
                            label={
                                notification
                                    ? 'New Notification Email'
                                    : 'New email'
                            }
                            name='email'
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please enter your new email address!',
                                },
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email!',
                                },
                            ]}
                        >
                            <Input
                                onBlur={handleBlur}
                                placeholder='Enter your new email address'
                            />
                        </Form.Item>
                        <Form.Item
                            label='Confirm new email'
                            name='confirm_email'
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please confirm your new email address!',
                                },
                                {
                                    type: 'email',
                                    message: 'Please enter a valid email!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            !value ||
                                            getFieldValue('email') === value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                'Email confirmation must match your new email!'
                                            )
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input
                                onBlur={handleBlur}
                                placeholder='Re-enter your new email address'
                            />
                        </Form.Item>
                        {isAdmin && (
                            <Form.Item
                                label='Confirm billing email (receipts are sent here)'
                                name='billing_email'
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please confirm your billing email address!',
                                    },
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email!',
                                    },
                                ]}
                            >
                                <Input
                                    onBlur={handleBlur}
                                    placeholder='Enter your billing email address'
                                />
                            </Form.Item>
                        )}
                    </div>
                    <div className={classes.modalFormButtonsWrapper}>
                        <Button
                            type='primary'
                            size='large'
                            ghost
                            onClick={handleOpen}
                            className={`${darkMode ? 'dark-mode' : ''}`}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='primary'
                            size='large'
                            htmlType='submit'
                            disabled={isDisabled}
                            loading={isLoading}
                        >
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default ChangeEmailModal;
