import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Form, Input, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { authPathes } from '../../../../constants/queryPathes';
import { passwordPattern } from '../../../../constants/regularExpression';
import {
    errorNotificationMessage,
    notificationType,
} from '../../../../constants/notificationType';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../utils/helpers/openNotification';

import './ResetPassword.scss';

const classes = {
    root: 'signin-wrapper',
    loginPageIcon: 'd-flex flex-center login-logo',
    darkModeTypography: 'dark-theme-typography dark-mode',
    loginPageWrapper: 'login-layout-wrapper h-100 d-flex flex-center',
    loginPageRoot: 'login-card-wrapper',
    darkModeAutofill: 'dark-input-autofill',
    customInputs: 'custom-inputs',
    options: 'd-flex singin-options justify-content-between',
};

const ResetPassword = () => {
    const params = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [form] = useForm();

    const handleOnKeyPress = (event) => {
        if (event.key === 'Enter') {
            form.submit();
        }
    };

    const handleSubmit = (values) => {
        setLoading(true);

        axiosAPI
            .post(
                `${authPathes.resetPassword}/${params.token}`,
                {
                    password: values.password,
                },
                {
                    ...getAxiosHeaders(),
                }
            )
            .then(() => {
                navigate(authPathes.login);
                openNotification(
                    notificationType.success,
                    'Success',
                    'Your password has been reset.'
                );
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Form
            name='singInForm'
            form={form}
            onKeyPress={handleOnKeyPress}
            onFinish={handleSubmit}
            autoComplete='off'
            className={classes.root}
        >
            <Typography>Password</Typography>
            <Form.Item
                name='password'
                rules={[
                    {
                        required: true,
                        message: 'Please enter your password!',
                    },
                    {
                        pattern: passwordPattern,
                        message:
                            'Password must contain at least 8 characters, including upper/lowercase letters, numbers & symbols',
                    },
                ]}
                className={classes.customInputs}
            >
                <Input.Password placeholder='Enter your password' />
            </Form.Item>

            <Typography>Confirm password</Typography>
            <Form.Item
                name='confirm-password'
                rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error(
                                    'The new password that you entered do not match!'
                                )
                            );
                        },
                    }),
                ]}
                className={classes.customInputs}
            >
                <Input.Password placeholder='Enter your password' />
            </Form.Item>

            <Form.Item>
                <Button
                    className='mb-0'
                    type='primary'
                    htmlType='submit'
                    loading={loading}
                >
                    Reset password
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ResetPassword;
