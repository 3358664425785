import { urlPattern } from '../../constants/regularExpression';

export const checkAreUrlsStrictlyEqual = (firstUrl, secondUrl) => {
    if (firstUrl && secondUrl) {
        return normalizeUrl(firstUrl) === normalizeUrl(secondUrl);
    }

    return false;
};

export const normalizeUrl = (url) => {
    let strippedUrl = url?.replace(/^https?:\/\//, '');

    strippedUrl = strippedUrl?.replace(/^www\./, '');

    strippedUrl = strippedUrl?.replace(/\/\/+/g, '/');

    strippedUrl = strippedUrl?.replace(/\/$/, '');

    return strippedUrl;
};

export const isUrlValid = (url) => {
    return urlPattern.test(url);
};
