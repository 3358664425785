import React from 'react';

import OptimizerKeywordsExportButton from './OptimizerKeywordsExportButton';
import OptimizerKeywordsSortDropdown from './OptimizerKeywordsSortDropdown';
import AddCustomKeywordModal from './AddCustomKeywordModal';
import OptimizerKeywordsCopyButton from './OptimizerKeywordsCopyButton';
import OptimizerSearchInput from './OptimizerSearchInput';

const classes = {
  topSectionWrapper:
    'd-flex align-items-center w-100 keywords-top-section-wrapper',
};

const OptimizerKeywordsTopSection = () => {
  return (
    <div className={classes.topSectionWrapper}>
      <OptimizerKeywordsSortDropdown />
      <div className='d-flex align-items-center'>
        <AddCustomKeywordModal />
        <OptimizerKeywordsCopyButton />
        <OptimizerKeywordsExportButton />
      </div>
      <div className='mt-1 w-100'>
        <OptimizerSearchInput />
      </div>
    </div>
  );
};

export default OptimizerKeywordsTopSection;
