import React from 'react';
import { useSelector } from 'react-redux';

import { Popover, Typography } from 'antd';

import SiderChevronDownIcon from '../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../resources/icons/SiderChevronUp';
import { colorPalette } from '../../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { useCollapse } from '../../../../utils/hooks/useCollapse';

import VersionsDropdownContent from './VersionsDropdownContent';

import './VersionsContent.scss';

const classes = {
    versionDropdownLabel: 'version-dropdown-label space-no-wrap cursor-pointer',
    versionDropdown: 'version-dropdown',
    versionDropdownOverlay: 'popover-overlay versions-popover-overlay',
    versionDropdownOverlayDark: 'popover-overlay-dark',
    statusText: 'status-text',
};

const VersionDropdown = ({
    loading,
    versions,
    previewVersionId,
    handleActivateVersion,
    createNewVersion,
    handlePreviewVersion,
    newVersionCreating,
    disableCreateNewVersionBtn,
    handleUpdateVersionName,
    handleDeleteVersion,
}) => {
    const darkMode = useSelector(selectDarkMode);

    const { collapsed, handleCollapse } = useCollapse();

    const versionDropdownContent = (
        <VersionsDropdownContent
            loading={loading}
            versions={versions}
            previewVersionId={previewVersionId}
            handleActivateVersion={handleActivateVersion}
            handlePreviewVersion={handlePreviewVersion}
            createNewVersion={createNewVersion}
            handleCollapse={handleCollapse}
            newVersionCreating={newVersionCreating}
            disableCreateNewVersionBtn={disableCreateNewVersionBtn}
            handleUpdateVersionName={handleUpdateVersionName}
            handleDeleteVersion={handleDeleteVersion}
        />
    );

    return (
        <Popover
            destroyTooltipOnHide
            open={collapsed}
            content={versionDropdownContent}
            trigger={['click']}
            placement='bottomRight'
            className={`${classes.versionDropdown} ${darkMode ? 'dark-mode' : ''}`}
            overlayClassName={`${classes.versionDropdownOverlay}
                               ${
                                   darkMode ?
                                   classes.versionDropdownOverlayDark :
                                   ''
                               }`}
            onOpenChange={handleCollapse}
        >
            <div
                className={`${classes.versionDropdownLabel}`}
                onClick={handleCollapse}
            >
                <Typography
                    className={`${classes.statusText} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                >
                    Version
                </Typography>
                {!collapsed ? (
                    <SiderChevronDownIcon
                        color={darkMode ? '#9D9D9F' : '#728192'}
                    />
                ) : (
                    <SiderChevronUpIcon color={colorPalette.colorPrimary} />
                )}
            </div>
        </Popover>
    );
};

export default VersionDropdown;
