import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';

import {
    selectIsGracePeriod,
    selectIsSubscriptionExpired,
} from '../../../userBrowserSettings/store/browserSettings.selectors';
import { selectCurrentSubscription } from '../../pages/account/billing/store/billingSettings.selectors';

import { togglePaymentMethodModal } from '../../pages/account/billing/store/billingSettings.actions';
import {
    updateAccountTab,
    updateScrollSection,
} from '../../pages/account/store/accountSettings.actions';

import { useOrganizationSlug } from '../../../utils/hooks/useOrganizationSlug';

import { accountTabs } from '../../../constants/accountTabs';
import { ScrollIds } from '../../../constants/scrollIds';

import TopPageWarningNotification from './TopPageWarningNotification';
import './Notifications.scss';

const GracePeriodNotification = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isSubscriptionExpired = useSelector(selectIsSubscriptionExpired);
    const isGracePeriod = useSelector(selectIsGracePeriod);

    const currentSubscription = useSelector(selectCurrentSubscription);
    const organizationSlug = useOrganizationSlug();

    const currentDate = moment();
    const subscriptionStartDate = moment(
        currentSubscription?.current_period_start
    );
    const gracePeriod = 10 - currentDate.diff(subscriptionStartDate, 'days');

    const handleUpdateCard = () => {
        navigate(`/${organizationSlug}/account/billing-credits`);
        dispatch(updateAccountTab(accountTabs['billing-credits'].key));
        dispatch(updateScrollSection(ScrollIds.payment));
        dispatch(togglePaymentMethodModal());
    };

    if (isSubscriptionExpired || !isGracePeriod) return null;

    return (
        <TopPageWarningNotification
            notificationTitle={
                <>
                    There’s a problem with your card on file. Please update your
                    details before your data and credits expire in
                    <span className='fw-600'>
                        {' '}
                        {gracePeriod >= 0 ? gracePeriod : 0} days
                    </span>
                </>
            }
            btnText='Update card'
            handleClickButton={handleUpdateCard}
        />
    );
};

export default GracePeriodNotification;
