import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../../../../../constants/notificationType';
import { contentOptimizerPathes } from '../../../../../../../../../../constants/queryPathes';

import { axiosAPI } from '../../../../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../../../../../../utils/helpers/openNotification';
import { useOrganizationSlug } from '../../../../../../../../../../utils/hooks/useOrganizationSlug';

import VersionHistory from '../../../../../../../../../common/versionHistory/VersionHistory';

import { selectContentOptimizerViewOnlyMode } from '../../../../../../../viewOnly/store/viewOnlySettings.selectors';
import {
    selectEditorPreviewVersion,
    selectEditorVersions,
    selectSaveEditorLoading,
} from '../../../../../../../../../common/tiptapEditor/store/tiptapEditor.selectors';
import {
    deleteEditorVersion,
    setEditorPreviewVersion,
    updateCurrentEditorLoading,
    updateEditorVersionName,
} from '../../../../../../../../../common/tiptapEditor/store/tiptapEditor.actions';

const classes = {
    editorVersionHistory: 'editor-version-history',
};

const EditorVersionHistory = ({
    fetchEditorVersions,
    createNewEditorVersion,
    newEditorVersionCreating,
}) => {
    const dispatch = useDispatch();

    const { contentOptimizerId } = useParams();

    const editorVersions = useSelector(selectEditorVersions);
    const currentEditorLoading = useSelector(selectSaveEditorLoading);
    const viewOnlyMode = useSelector(selectContentOptimizerViewOnlyMode);
    const editorPreviewVersion = useSelector(selectEditorPreviewVersion);

    const organizationSlug = useOrganizationSlug();

    const [mappedEditorVersions, setMappedEditorVersions] = useState([]);

    useEffect(() => {
        if (editorVersions?.length > 0) {
            setMappedEditorVersions(
                editorVersions?.map((version) => {
                    return {
                        id: version?.id,
                        name: version?.name,
                        active: version?.active,
                        created_at: version?.created_at,
                        wordCount: version?.word_count,
                        progress: Math.ceil(version?.rankability_score) || 0,
                    };
                })
            );
        }
    }, [editorVersions]);

    const handleActivateEditorVersion = (editorId) => {
        if (contentOptimizerId && editorId) {
            dispatch(updateCurrentEditorLoading(true));

            axiosAPI
                .patch(
                    `${contentOptimizerPathes.getContentOptimizers}/${contentOptimizerId}/versions/${editorId}`,
                    {
                        active: true,
                    },
                    {
                        ...getAxiosHeaders(),
                        params: { slug: organizationSlug },
                    }
                )
                .then(() => {
                    fetchEditorVersions();
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        }
    };

    const handlePreviewEditorVersion = (versionId) => {
        dispatch(
            setEditorPreviewVersion(
                versionId && !editorPreviewVersion ? versionId : null
            )
        );
    };

    const handleUpdateVersionName = (versionId, versionName) => {
        if (contentOptimizerId && versionId) {
            axiosAPI
                .patch(
                    `${contentOptimizerPathes.getContentOptimizers}/${contentOptimizerId}/versions/${versionId}`,
                    {
                        name: versionName,
                    },
                    {
                        ...getAxiosHeaders(),
                        params: { slug: organizationSlug },
                    }
                )
                .then(() => {
                    dispatch(
                        updateEditorVersionName({
                            id: versionId,
                            name: versionName,
                        })
                    );
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        }
    };

    const handleDeleteEditorVersion = (versionId) => {
        if (contentOptimizerId && versionId) {
            axiosAPI
                .delete(
                    `${contentOptimizerPathes.deleteContentOptimizer}/${contentOptimizerId}/versions/${versionId}`,
                    {
                        ...getAxiosHeaders(),
                        params: { slug: organizationSlug },
                    }
                )
                .then(() => {
                    dispatch(deleteEditorVersion(versionId));
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        }
    };

    return (
        <div className={`${classes.editorVersionHistory}`}>
            <VersionHistory
                versions={mappedEditorVersions}
                previewVersionId={editorPreviewVersion?.id}
                loading={currentEditorLoading}
                newVersionCreating={newEditorVersionCreating}
                handleActivateVersion={handleActivateEditorVersion}
                handlePreviewVersion={handlePreviewEditorVersion}
                createNewVersion={!viewOnlyMode ? createNewEditorVersion : null}
                disableCreateNewVersionBtn={editorPreviewVersion}
                handleUpdateVersionName={
                    !viewOnlyMode ? handleUpdateVersionName : null
                }
                handleDeleteVersion={
                    !viewOnlyMode ? handleDeleteEditorVersion : null
                }
            />
        </div>
    );
};

export default EditorVersionHistory;
