import React, { useEffect, useState } from 'react';

import { Button, Input } from 'antd';

const classes = {
    addLinkWrapper: 'add-link-wrapper d-flex',
    addLinkButton: 'add-link-button',
};

const AddLinkContent = ({ handleCollapse, editor }) => {
    const [linkValue, setLinkValue] = useState(null);

    const handleOnChange = (event) => {
        setLinkValue(event?.target?.value);
    };

    const handleOnClick = () => {
        if (linkValue) {
            handleCollapse();
            setLinkValue('');

            const { view, state } = editor;
            const { from, to } = view.state.selection;
            const text = state.doc.textBetween(from, to, '');

            if (text) {
                editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .setLink({ href: linkValue })
                    .run();
            } else {
                editor.commands.insertContent(
                    `<a href="${linkValue}">${linkValue}</a>`
                );
            }
        }
    };

    useEffect(() => {
        setLinkValue('');

        // eslint-disable-next-lin
    }, []);

    return (
        <div className={`${classes.addLinkWrapper}`}>
            <Input
                placeholder={'Type or paste a URL'}
                onChange={handleOnChange}
                onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                        handleOnClick();
                    }
                }}
                value={linkValue}
            />
            <Button
                type='primary'
                className={classes.addLinkButton}
                onClick={handleOnClick}
            >
                Save
            </Button>
        </div>
    );
};

export default AddLinkContent;
