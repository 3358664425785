const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const getWordBoundaryRegex = (string) => {
    const escapedString = escapeRegExp(string); // Escape special characters to avoid regex errors
    return new RegExp(`\\b${escapedString}\\b`, 'gi');
};

export const roundPercentagesInStringByRegex = (string) => {
    const percentageRegex = /(\d+\.\d+)%/g; // Matches percentages with decimal points (e.g. 12.5%)

    return string.replace(percentageRegex, (match, percentage) => {
        return `${Math.round(Number(percentage))}%`;
    });
};
