import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Layout, Spin, Typography, theme } from 'antd';

import moment from 'moment';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../constants/notificationType';
import { globalAdminPathes } from '../../../../constants/queryPathes';
import { statusCodes } from '../../../../constants/statusCodes';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../utils/helpers/openNotification';

import CustomHeader from '../../../common/header/Header';
import Loader from '../../../common/loader/Loader';
import UsageChartsSection from './UsageChartsSection';
import UsageOrganizationStatsTable from './UsageOrganizationStatsTable';

import './UsagePage.scss';

const { Content } = Layout;
const { Title } = Typography;

const classes = {
    pageLayoutWrapper: 'page-layout-wrapper',
    organizationTitleWrapper: 'page-title-wrapper',
    pageTitle: 'page-title',
    content: 'container usage-page-content',
    contentDark: 'container-dark',
    usageContentWrapper: 'usage-content-wrapper',
};

const mapAdminUsageDataToDisplay = (data, action, period) => {
    const initialValue = Array.from({ length: period }, (_, i) => {
        return {
            name: moment().subtract(i, 'days').format('MMM DD, YYYY'),
            value: 0,
        };
    });

    const descUsageData =
        data?.reduce((accumulator, item) => {
            if (item?.action === action) {
                const date = moment(item.created_at).format('MMM DD, YYYY');
                const index = accumulator?.findIndex((x) => x.name === date);

                if (index !== -1) {
                    accumulator[index].value += 1;
                } else {
                    accumulator.push({ name: date, value: 1 });
                }
            }

            return accumulator;
        }, initialValue) || [];

    return [...descUsageData].reverse();
};

const UsagePage = () => {
    const darkMode = useSelector(selectDarkMode);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const [usageData, setUsageData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        axiosAPI
            .get(`${globalAdminPathes.usage}`, {
                ...getAxiosHeaders(),
            })
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    const stats = result.data?.reduce((accumulator, item) => {
                        const index = accumulator?.findIndex(
                            (x) =>
                                x?.organization === item?.organization?.name &&
                                x.organizationId === item?.organization?.id
                        );

                        if (index !== -1) {
                            if (item?.action === 'create_keyword_research') {
                                accumulator[index].keywordResearches += 1;
                            }
                            if (item?.action === 'create_content_brief') {
                                accumulator[index].contentBriefs += 1;
                            }
                        } else {
                            accumulator.push({
                                organization: item?.organization?.name,
                                organizationId: item?.organization?.id,
                                keywordResearches: 0,
                                contentBriefs: 0,
                            });
                        }

                        return accumulator;
                    }, []);

                    setUsageData({
                        contentBriefCount: mapAdminUsageDataToDisplay(
                            result?.data,
                            'create_content_brief',
                            30
                        ),
                        keywordResearchCount: mapAdminUsageDataToDisplay(
                            result?.data,
                            'create_keyword_research',
                            30
                        ),
                        stats,
                    });
                }
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <>
            <CustomHeader
            // TODO: — Hide all “How To Use” buttons (temporary)
            //showUseSetting
            />

            <div className={classes.pageLayoutWrapper}>
                <div className={classes.organizationTitleWrapper}>
                    <Title level={2} className={classes.pageTitle}>
                        Usage
                    </Title>
                </div>
                <Content
                    style={{
                        background: colorBgContainer,
                    }}
                    className={`${classes.content}
                                ${darkMode ? classes.contentDark : ''}`}
                >
                    <div className={classes.usageContentWrapper}>
                        <Spin spinning={isLoading} indicator={<Loader />}>
                            <UsageChartsSection
                                contentBriefs={usageData?.contentBriefCount}
                                keywordResearches={
                                    usageData?.keywordResearchCount
                                }
                            />
                            <UsageOrganizationStatsTable
                                organizationStats={usageData?.stats}
                            />
                        </Spin>
                    </div>
                </Content>
            </div>
        </>
    );
};

export default UsagePage;
