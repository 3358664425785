import React from 'react';
import { useSelector } from 'react-redux';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectGeneratedAIToken } from '../store/tiptapEditor.selectors';

import AISection from './buttons/AISection';
import GalleryButtonsSection from './buttons/GalleryButtonsSection';
import HeadingSection from './buttons/HeadingSection';
import ListButtonsSection from './buttons/ListButtonsSection';
import MarkButtonsSection from './buttons/MarkButtonsSection';
import UndoRedoButtonsSection from './buttons/UndoRedoButtonsSection';
import ToolbarSettingsSection from './ToolbarSettingsSection';

import './buttons/content/ToolbarContent.scss';
import './buttons/ToolbarButtons.scss';
import './EditorToolbar.scss';

const classes = {
    editorToolbar: 'editor-toolbar justify-content-between',
    buttonWrapper: 'd-flex',
};

const EditorToolbar = () => {
    const darkMode = useSelector(selectDarkMode);
    const generatedAIToken = useSelector(selectGeneratedAIToken);
    const aiAppId = process.env.REACT_APP_TIPTAP_AI_APP_ID;

    return (
        <div
            className={`${classes.editorToolbar} ${
                darkMode ? 'dark-mode' : ''
            }`}
        >
            <div className={`${classes.buttonWrapper}`}>
                <HeadingSection />
                <MarkButtonsSection />
                <ListButtonsSection />
                <GalleryButtonsSection />
                <UndoRedoButtonsSection />
                {generatedAIToken && aiAppId && <AISection />}
            </div>

            <ToolbarSettingsSection />
        </div>
    );
};

export default EditorToolbar;
