import React from 'react';

import { List } from 'antd';

import { useSortedOptimizerKeywords } from '../../../../../../../../utils/hooks/useSortedOptimizerKeywords';
import { useHighlightedKeywords } from '../../../../../../../../utils/hooks/useHighlightedKeywords';

import OptimizerKeywordsListItem from './OptimizerKeywordsListItem';

const classes = {
    optimizerKeywordsListWrapper: 'optimizer-keywords-list-wrapper',
};

const OptimizerKeywordsList = () => {
    const sortedKeywords = useSortedOptimizerKeywords();

    const highlightedKeywords = useHighlightedKeywords();

    const getCurrentKeywordUses = (currentKeyword, keywordList = []) => {
        return keywordList?.find(
            (keyword) => keyword?.keyword.toLocaleLowerCase() === currentKeyword?.toLocaleLowerCase()
        )?.uses;
    };

    return (
        <List
            className={classes.optimizerKeywordsListWrapper}
            dataSource={sortedKeywords}
            renderItem={(item) => (
                <OptimizerKeywordsListItem
                    key={item?.keyword}
                    keywordItem={item}
                    active={
                        getCurrentKeywordUses(item?.keyword, highlightedKeywords) > 0
                    }
                    uses={
                        getCurrentKeywordUses(item?.keyword, highlightedKeywords)
                    }
                />
            )}
        />
    );
};

export default OptimizerKeywordsList;
