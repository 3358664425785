import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Spin, Typography } from 'antd';
import moment from 'moment';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';
import { useOrganizationSlug } from '../../../../../utils/hooks/useOrganizationSlug';
import { usePaymentMethod } from '../../../../../utils/hooks/usePaymentMethod';

import SubscriptionHelper from '../../../auth/helpers/subscription.helper';

import {
    selectCurrentSubscription,
    selectIsCurrentSubscriptionLoading,
    selectOptimizersAdded,
    selectOptimizersAddedSource,
    selectOptimizersLimit,
    selectOptimizersUsed,
} from '../store/billingSettings.selectors';

import CreditDetailsTable from './CreditDetailsTable';
import Loader from '../../../../common/loader/Loader';

const { Title, Link } = Typography;

const classes = {
    reportCreditsWrapper: 'report-credits-wrapper',
    reportCreditsSectiontitle: 'section-title',
    creditDetailsWrapper: 'credit-details-wrapper d-flex align-items-center',
    textAccent: 'text-accent',
    link: 'link-hover-highlight',
};

const ReportCreditsSection = ({ id }) => {
    const navigate = useNavigate();

    const { paymentMethod } = usePaymentMethod();

    const organizationSlug = useOrganizationSlug();

    const darkMode = useSelector(selectDarkMode);
    const optimizersAdded = useSelector(selectOptimizersAdded);
    const optimizersUsed = useSelector(selectOptimizersUsed);
    const optimizersLimit = useSelector(selectOptimizersLimit);
    const currentSubscription = useSelector(selectCurrentSubscription);
    const isSubscriptionLoading = useSelector(
        selectIsCurrentSubscriptionLoading
    );

    const optimizersAddedSource = useSelector(selectOptimizersAddedSource);

    const rolloverTotal = useMemo(
        () =>
            optimizersAddedSource
                ?.filter((x) => x?.reason === 'rollover')
                ?.reduce(
                    (accumulator, currentValue) =>
                        accumulator + currentValue.amount,
                    0
                ),
        [optimizersAddedSource]
    );

    const [showCreditDetails, setShowCreditDetails] = useState(false);

    const toggleCreditDetails = () => {
        setShowCreditDetails(!showCreditDetails);
    };

    const handlePurchaseCredits = () => {
        navigate(`/${organizationSlug}/account/credits`);
    };

    return (
        <section id={id} className={classes.reportCreditsWrapper}>
            <Title level={4} className={classes.reportCreditsSectiontitle}>
                Optimizer Credits
            </Title>
            <Spin spinning={isSubscriptionLoading} indicator={<Loader />}>
                <Typography>
                    <span className={classes.textAccent}>
                        {optimizersLimit + optimizersAdded - optimizersUsed ||
                            0}
                    </span>{' '}
                    of{' '}
                    <span className={classes.textAccent}>
                        {optimizersLimit + optimizersAdded || 0}
                    </span>{' '}
                    Optimizer credits remaining
                    {rolloverTotal > 0 && (
                        <span>
                            {' '}
                            (includes <b>
                                {rolloverTotal} rollover credits
                            </b>{' '}
                            from last month)
                        </span>
                    )}
                    {'. '}
                    Credits will expire/rollover on{' '}
                    <span className={classes.textAccent}>
                        {currentSubscription
                            ? moment(
                                  currentSubscription?.current_period_end
                              ).format('LL')
                            : ''}
                    </span>{' '}
                    at{' '}
                    <span className={classes.textAccent}>
                        {currentSubscription
                            ? moment(
                                  currentSubscription?.current_period_end
                              ).format('LT')
                            : ''}
                        .
                    </span>
                </Typography>
                <Typography className={classes.creditDetailsWrapper}>
                    <span className={classes.textAccent}>Сredit details</span>
                    {!showCreditDetails && paymentMethod && (
                        <Link
                            underline
                            className={`${classes.link}
                        ${darkMode ? 'dark-mode' : ''}`}
                            onClick={toggleCreditDetails}
                        >
                            View
                        </Link>
                    )}
                </Typography>
                {showCreditDetails && (
                    <CreditDetailsTable
                        toggleCreditDetails={toggleCreditDetails}
                    />
                )}
                {!isSubscriptionLoading &&
                    !SubscriptionHelper.isTrial(currentSubscription) && (
                        <Link
                            underline
                            className={`${classes.link}
                    ${darkMode ? 'dark-mode' : ''}`}
                            onClick={handlePurchaseCredits}
                        >
                            Purchase credits
                        </Link>
                    )}
            </Spin>
        </section>
    );
};

export default ReportCreditsSection;
