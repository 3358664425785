import React from 'react';
import { useSelector } from 'react-redux';

import { useCurrentEditor } from '@tiptap/react';
import { Button, Popover, Select, Tooltip } from 'antd';

import SiderChevronDownIcon from '../../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../../resources/icons/SiderChevronUp';
import LinkIcon from '../../../../../resources/icons/editor/LinkIcon';
import OrderedListIcon from '../../../../../resources/icons/editor/OrderedListIcon';
import TextAlignCenterIcon from '../../../../../resources/icons/editor/TextAlignCenterIcon';
import TextAlignJustifyCenterIcon from '../../../../../resources/icons/editor/TextAlignJustifyCenterIcon';
import TextAlignLeftIcon from '../../../../../resources/icons/editor/TextAlignLeftIcon';
import TextAlignRightIcon from '../../../../../resources/icons/editor/TextAlignRightIcon';
import UnorderedListIcon from '../../../../../resources/icons/editor/UnorderedListIcon';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import KeyPressListener from '../../../keyPressListener/KeyPressListener';
import { keyActionTypes } from '../../../keyPressListener/constants';

import { useIconColor } from '../../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../../utils/hooks/useOpen';

import { alignments } from '../../constants/alignments';
import { getIconColorByDarkMode } from '../constants';

import CustomButtonsTooltip from './CustomButtonsTooltip';
import AddLinkContent from './content/AddLinkContent';

const { Option } = Select;

const classes = {
    listButtonsSection: 'list-buttons-section',
    tooltipOverlay: 'toolbar-tooltip-overlay tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',

    linksDropdown: 'links-dropdown',
    linksDropdownOverlay: 'popover-overlay links-popover-overlay',
    textAlignDropdown: 'text-align-dropdown',
    editorTextAlignDropdown: 'editor-text-align-dropdown',
    linksDropdownOverlayDark: 'popover-overlay-dark',
};

const ListButtonsSection = () => {
    const { editor } = useCurrentEditor();

    const iconColor = useIconColor();
    const { isOpen, handleOpen } = useOpen();

    const { isOpen: isAddLinkOpen, handleOpen: handleAddLinkOpen } = useOpen();

    const darkMode = useSelector(selectDarkMode);

    const justifyOptions = [
        {
            value: alignments.left.key,
            optionLabel: (
                <TextAlignLeftIcon color={getIconColorByDarkMode(darkMode)} />
            ),
            label: '',
            tooltip: 'Toggle left align',
        },
        {
            value: alignments.center.key,
            optionLabel: (
                <TextAlignCenterIcon color={getIconColorByDarkMode(darkMode)} />
            ),
            label: '',
            tooltip: 'Toggle center align',
        },
        {
            value: alignments.right.key,
            optionLabel: (
                <TextAlignRightIcon color={getIconColorByDarkMode(darkMode)} />
            ),
            label: '',
            tooltip: 'Toggle right align',
        },
        {
            value: alignments.justify.key,
            optionLabel: (
                <TextAlignJustifyCenterIcon
                    color={getIconColorByDarkMode(darkMode)}
                />
            ),
            label: '',
            tooltip: 'Toggle justify align',
        },
    ];

    justifyOptions.forEach((item) => {
        item.label = (
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                placement='top'
                title={<CustomButtonsTooltip title='Text align' />}
            >
                <div>{item.optionLabel}</div>
            </Tooltip>
        );
    });

    const handleJustifyChange = (value) => {
        editor.chain().focus().setTextAlign(value).run();
        editor.commands.alignImage(value);
    };

    const handleAddLink = () => {
        if (editor.isActive('link')) {
            editor.chain().focus().unsetLink().run();
        } else {
            handleAddLinkOpen();
        }
    };

    const handleInsertLink = () => {
        handleAddLink();
    };

    return (
        <div
            className={`${classes.listButtonsSection} ${
                darkMode ? 'dark-mode' : ''
            }`}
        >
            <div
                className={`${classes.textAlignDropdown} ${
                    darkMode ? 'dark-mode' : ''
                }`}
            >
                <Select
                    onChange={handleJustifyChange}
                    value={
                        justifyOptions?.find((item) =>
                            editor.isActive({ textAlign: item?.value })
                        )?.value || justifyOptions[0]?.value
                    }
                    bordered={false}
                    suffixIcon={
                        isOpen ? (
                            <SiderChevronUpIcon
                                color={colorPalette.colorPrimary}
                            />
                        ) : (
                            <SiderChevronDownIcon color={iconColor} />
                        )
                    }
                    onDropdownVisibleChange={handleOpen}
                    style={{ width: 58 }}
                    popupClassName={classes.editorTextAlignDropdown}
                    optionLabelProp='label'
                >
                    {justifyOptions?.map((item, index) => {
                        return (
                            <Option
                                key={`${index}-${item.value}`}
                                value={item.value}
                                label={item.label}
                                title=''
                            >
                                <Tooltip
                                    onClick={(event) => {
                                        event.preventDefault();
                                    }}
                                    overlayClassName={`${
                                        classes.tooltipOverlay
                                    } ${
                                        darkMode
                                            ? classes.tooltipOverlayDark
                                            : ''
                                    }`}
                                    placement='right'
                                    title={
                                        <CustomButtonsTooltip
                                            title={item.tooltip}
                                        />
                                    }
                                >
                                    <div>{item.optionLabel}</div>
                                </Tooltip>
                            </Option>
                        );
                    })}
                </Select>
            </div>
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={<CustomButtonsTooltip title='Bulleted list' />}
            >
                <>
                    <Button
                        icon={
                            <UnorderedListIcon
                                color={getIconColorByDarkMode(darkMode)}
                            />
                        }
                        onClick={() =>
                            editor.chain().focus().toggleBulletList().run()
                        }
                        className={
                            editor.isActive('bulletList') ? 'active' : ''
                        }
                    />
                </>
            </Tooltip>
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={<CustomButtonsTooltip title='Numbered list' />}
            >
                <>
                    <Button
                        icon={
                            <OrderedListIcon
                                color={getIconColorByDarkMode(darkMode)}
                            />
                        }
                        onClick={() =>
                            editor.chain().focus().toggleOrderedList().run()
                        }
                        className={
                            editor.isActive('orderedList') ? 'active' : ''
                        }
                    />
                </>
            </Tooltip>

            <KeyPressListener
                onKeyPress={handleInsertLink}
                keyAction={keyActionTypes.insertLink}
            />
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={<CustomButtonsTooltip title='Insert link' />}
            >
                <>
                    <Popover
                        open={isAddLinkOpen}
                        content={
                            <AddLinkContent
                                handleCollapse={handleAddLinkOpen}
                                editor={editor}
                            />
                        }
                        trigger={['click']}
                        placement='bottom'
                        className={`${classes.linksDropdown} ${
                            darkMode ? 'dark-mode' : ''
                        }`}
                        overlayClassName={`${classes.linksDropdownOverlay}
                               ${
                                   darkMode
                                       ? classes.linksDropdownOverlayDark
                                       : ''
                               }`}
                        onOpenChange={handleAddLink}
                    >
                        <Button
                            icon={
                                <LinkIcon
                                    color={getIconColorByDarkMode(darkMode)}
                                />
                            }
                            className={editor.isActive('link') ? 'active' : ''}
                        />
                    </Popover>
                </>
            </Tooltip>
        </div>
    );
};

export default ListButtonsSection;
