import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Link from 'antd/es/typography/Link';
import { Button, Form, Input, Typography } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';

import { authPathes } from '../../../../constants/queryPathes';
import { unauthorizedRoutes } from '../../../common/router/Unauthorized/routes';

import { openNotification } from '../../../../utils/helpers/openNotification';
import {
    errorNotificationMessage,
    notificationType,
} from '../../../../constants/notificationType';
import { formEmailRules } from '../../../../constants/formItemRules';
import { useForm } from 'antd/es/form/Form';

import './LoginPageContent.scss';

const classes = {
    root: 'forgot-password-wrapper',
    mainTypography: 'main-typography',
    descTypography: 'description-typography',
    backToSingIn: 'd-flex flex-center',
    darkModeTypography: 'dark-theme-typography dark-mode',
};

const ForgotPassword = () => {
    const darkMode = useSelector(selectDarkMode);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState();
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [form] = useForm();

    const sendForgotPasswordEmail = () => {
        setLoading(true);

        axiosAPI
            .post(
                authPathes.forgotPassword,
                {
                    email: email,
                },
                {
                    ...getAxiosHeaders(),
                }
            )
            .then(() => {
                setIsEmailSent(true);
            })
            .catch((err) => {
                openNotification(
                    notificationType.error,
                    'Error',
                    err?.response?.data || errorNotificationMessage
                );
            })
            .finally(() => setLoading(false));
    };

    const submit = () => {
        form.validateFields()
            .then(() => sendForgotPasswordEmail())
            .catch(() => {});
    };

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSignIn = () => {
        navigate(unauthorizedRoutes.login);
    };

    return (
        <div className={classes.root}>
            {isEmailSent ? (
                <>
                    <Typography
                        className={`${classes.mainTypography} ${
                            darkMode ? classes.darkModeTypography : ''
                        }`}
                    >
                        {email}
                    </Typography>
                    <Typography className={classes.descTypography}>
                        A password reset link will be sent to the email address
                        above within 5 minutes if a valid account is found.
                    </Typography>
                </>
            ) : (
                <>
                    <Typography
                        className={`${classes.mainTypography} ${
                            darkMode ? classes.darkModeTypography : ''
                        }`}
                    >
                        Forgot Your Password?
                    </Typography>
                    <Typography className={classes.descTypography}>
                        Enter your email address below and we'll send you
                        instructions to reset your password.
                    </Typography>
                    <Typography>Email</Typography>
                    <Form form={form}>
                        <Form.Item name='email' rules={formEmailRules}>
                            <Input
                                value={email}
                                onChange={handleChange}
                                placeholder='Enter your email'
                            />
                        </Form.Item>
                    </Form>
                </>
            )}
            {!isEmailSent && (
                <Button loading={loading} type='primary' onClick={submit}>
                    Email me instructions
                </Button>
            )}
            <div className={classes.backToSingIn}>
                <Link onClick={handleSignIn}>Back to sign in</Link>
            </div>
        </div>
    );
};

export default ForgotPassword;
