export const billingSettingsActions = {
    CLEAR_BILLING_SETTINGS: 'BILLING_SETTINGS/CLEAR_BILLING_SETTINGS',

    SET_PAYMENT_METHOD: 'BILLING_SETTINGS/SET_PAYMENT_METHOD',
    SET_CURRENT_SUBSCRIPTION: 'BILLING_SETTINGS/SET_CURRENT_SUBSCRIPTION',
    SET_CURRENT_SUBSCRIPTION_LOADING:
        'BILLING_SETTINGS/SET_CURRENT_SUBSCRIPTION_LOADING',
    SET_PLAN_TO_SWITCH: 'BILLING_SETTINGS/SET_PLAN_TO_SWITCH',
    UPDATE_PLAN_TYPE: 'BILLING_SETTINGS/UPDATE_PLAN_TYPE',
    UPDATE_BILLING_EMAIL: 'BILLING_SETTINGS/UPDATE_BILLING_EMAIL',
    SET_SUBSCRIPTION_LIMITS: 'BILLING_SETTINGS/SET_SUBSCRIPTION_LIMITS',
    INCREASE_SUBSCRIPTION_LIMITS:
        'BILLING_SETTINGS/INCREASE_SUBSCRIPTION_LIMITS',
    DECREASE_SUBSCRIPTION_LIMITS:
        'BILLING_SETTINGS/DECREASE_SUBSCRIPTION_LIMITS',
    UPDATE_OPTIMIZERS_ADDED: 'BILLING_SETTINGS/UPDATE_OPTIMIZERS_ADDED',
    UPDATE_OPTIMIZERS_AND_AI_ADDED_SOURCE:
        'BILLING_SETTINGS/UPDATE_OPTIMIZERS_AND_AI_ADDED_SOURCE',
    UPDATE_CURRENT_SUBSCRIPTION_CANCEL_AT_PERIOD_END:
        'BILLING_SETTINGS/UPDATE_CURRENT_SUBSCRIPTION_CANCEL_AT_PERIOD_END',
    TOGGLE_PAYMENT_METHOD_MODAL: 'BILLING_SETTINGS/TOGGLE_PAYMENT_METHOD_MODAL',
};

export const setPaymentMethod = (payload) => {
    return {
        type: billingSettingsActions.SET_PAYMENT_METHOD,
        payload,
    };
};

export const setCurrentSubscription = (payload) => {
    return {
        type: billingSettingsActions.SET_CURRENT_SUBSCRIPTION,
        payload,
    };
};

export const setCurrentSubscriptionLoading = (payload) => {
    return {
        type: billingSettingsActions.SET_CURRENT_SUBSCRIPTION_LOADING,
        payload,
    };
};

export const setPlanToSwitch = (payload) => {
    return {
        type: billingSettingsActions.SET_PLAN_TO_SWITCH,
        payload,
    };
};

export const updatePlanType = (payload) => {
    return {
        type: billingSettingsActions.UPDATE_PLAN_TYPE,
        payload,
    };
};

export const updateBillingEmail = (payload) => {
    return {
        type: billingSettingsActions.UPDATE_BILLING_EMAIL,
        payload,
    };
};

export const setSubscriptionLimits = (payload) => {
    return {
        type: billingSettingsActions.SET_SUBSCRIPTION_LIMITS,
        payload,
    };
};

export const updateOptimizersAdded = (payload) => {
    return {
        type: billingSettingsActions.UPDATE_OPTIMIZERS_ADDED,
        payload,
    };
};

export const updateOptimizersAndAIAddedSource = (payload) => {
    return {
        type: billingSettingsActions.UPDATE_OPTIMIZERS_AND_AI_ADDED_SOURCE,
        payload,
    };
};

export const increaseSubscriptionLimits = (payload) => {
    return {
        type: billingSettingsActions.INCREASE_SUBSCRIPTION_LIMITS,
        payload,
    };
};

export const decreaseSubscriptionLimits = (payload) => {
    return {
        type: billingSettingsActions.DECREASE_SUBSCRIPTION_LIMITS,
        payload,
    };
};

export const updateCurrentSubscriptionCancelAtPeriodEnd = (payload) => {
    return {
        type: billingSettingsActions.UPDATE_CURRENT_SUBSCRIPTION_CANCEL_AT_PERIOD_END,
        payload,
    };
};

export const togglePaymentMethodModal = () => {
    return {
        type: billingSettingsActions.TOGGLE_PAYMENT_METHOD_MODAL,
    };
};

export const clearBillingSettings = () => {
    return {
        type: billingSettingsActions.CLEAR_BILLING_SETTINGS,
    };
};
