import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { CheckOutlined } from '@ant-design/icons';
import { Typography } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { getPlanFeaturesArray } from '../../../../utils/helpers/getPlanFeaturesArray';
import { planInfoPlaceholder } from '../../../../constants/billingPlanConsts';

const classes = {
    mainTypography: 'main-typography',
    darkThemeTypography: 'dark-theme-typography',
    backToSingUp: 'd-flex flex-center bottom-link',
    billingPlans: 'billing-plans',
    planDescription: 'plan-description',
    darkPlanDescription: 'dark-plan-description',
    checkOutlinedIcon: 'check-outlined-icon',
};

const BillingPlans = ({ subscription, price }) => {
    const darkMode = useSelector(selectDarkMode);
    const [currentInfo, setCurrentInfo] = useState(planInfoPlaceholder);

    useEffect(() => {
        if (subscription && price) {
            setCurrentInfo(
                getPlanFeaturesArray(
                    subscription.metadata,
                    price.reccuring.interval
                )
            );
        } else {
            setCurrentInfo(planInfoPlaceholder);
        }
    }, [subscription, price]);

    return (
        <div className={classes.billingPlans}>
            {currentInfo?.map((feature, index) => (
                <Typography
                    key={index}
                    className={`${classes.planDescription} ${
                        darkMode ? classes.darkPlanDescription : ''
                    }`}
                >
                    <CheckOutlined className={classes.checkOutlinedIcon} />
                    <b>
                        {typeof feature?.value !== 'boolean'
                            ? ` ${feature?.value} `
                            : ' '}
                    </b>
                    {feature?.title}
                </Typography>
            ))}
        </div>
    );
};

export default BillingPlans;
