import React from 'react';
import { useSelector } from 'react-redux';

import { Badge, Card, Typography } from 'antd';

import { getNumberWithCommaSeparator } from '../../../utils/helpers/getNumberWithCommaSeparator';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import CustomDivider from '../Divider';

const classes = {
    baseChartTooltipRoot: 'base-chart-tooltip-root',
    valueLine: 'd-flex justify-content-between',
    titleLabel: 'd-flex flex-center title-label',
    valueLabel: 'value-label',
    valueBudge: 'value-budge',
};

// label and payload props are basic rechart props (You shouldn't provide these)
const BaseChartTooltip = ({ dataKey, valueDescLabel, label, payload }) => {
    const darkTheme = useSelector(selectDarkMode);

    return (
        <Card className={classes.baseChartTooltipRoot}>
            <Typography
                className={`${classes.titleLabel} ${darkTheme && 'dark-mode'}`}
            >
                {label}
            </Typography>

            <CustomDivider />

            <div className={classes.valueLine}>
                <Badge color='#327EEF' className={classes.valueBudge} />
                <Typography>{valueDescLabel || 'Monthly searches'}</Typography>
                <Typography
                    className={`${classes.valueLabel} ${
                        darkTheme && 'dark-mode'
                    }`}
                >
                    {getNumberWithCommaSeparator(payload[0]?.[dataKey])}
                </Typography>
            </div>
        </Card>
    );
};

export default BaseChartTooltip;
