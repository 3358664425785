const mainNavItemsKeysArray = [
    'research',
    'brief',
    'optimizer',
    'monitor',
    'usage',
    'users',
    'organizations',
    'cancellations',
];

export const getDefaultSelectedKeys = (pathname) => {
    const pathIndex = mainNavItemsKeysArray.findIndex((path) =>
        pathname.includes(path)
    );

    return pathIndex !== -1 ? mainNavItemsKeysArray[pathIndex] : '';
};
