import React from 'react';
import { useSelector } from 'react-redux';

import { Table, Typography } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

const classes = {
    mainLabel: 'main-label',
    organizationStatsLabel: 'organization-stats-label',
};

const organizationStatsColumns = [
    {
        title: 'Organization',
        dataIndex: 'organization',
        key: 'organization',
        width: '40%',
    },
    {
        title: '30 day reports',
        dataIndex: 'contentBriefs',
        key: 'contentBriefs',
        width: '30%',
    },
    {
        title: '30 day keyword discoveries',
        dataIndex: 'keywordResearches',
        key: 'keywordResearches',
        width: '30%',
    },
];

const UsageOrganizationStatsTable = ({ organizationStats }) => {
    const darkMode = useSelector(selectDarkMode);

    return (
        <>
            <Typography
                className={`${classes.mainLabel} ${
                    classes.organizationStatsLabel
                } ${darkMode ? 'dark-mode' : ''}`}
            >
                Organization Stats
            </Typography>
            <Table
                dataSource={organizationStats}
                columns={organizationStatsColumns}
                loading={false}
                bordered
                rowKey={'organizationId'}
                showSorterTooltip={false}
                pagination={false}
            />
        </>
    );
};

export default UsageOrganizationStatsTable;
