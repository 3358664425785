import { planFeatures } from '../../components/pages/account/billing/BillingConstants';
import { billingPlanTypes } from '../../constants/billingPlanConsts';

export const getPlanFeaturesArray = (planMetaData, planType) => {
    const planFeaturesArray = [];

    const getMetadataFeatureValue = (feature) => {
        if (typeof planFeatures[feature].value === 'boolean') {
            return planFeatures[feature].value;
        } else {
            if (
                planType === 'year' ||
                planType === billingPlanTypes.yearly.value
            ) {
                return (
                    planMetaData[feature] *
                    planFeatures[feature].yearlyCoefficient
                );
            } else {
                return planMetaData[feature];
            }
        }
    };

    const getPlanFeatureValue = (feature) => {
        if (planType === 'year' || planType === billingPlanTypes.yearly.value) {
            if (typeof planFeatures[feature].value === 'boolean') {
                return planFeatures[feature].value;
            } else {
                return (
                    planFeatures[feature].value *
                    planFeatures[feature].yearlyCoefficient
                );
            }
        } else {
            return planFeatures[feature].value;
        }
    };

    for (const feature in planFeatures) {
        if (planMetaData?.[feature]) {
            planFeaturesArray.push({
                ...planFeatures[feature],
                value: getMetadataFeatureValue(feature),
            });
        } else {
            planFeaturesArray.push({
                ...planFeatures[feature],
                value: getPlanFeatureValue(feature),
            });
        }
    }

    return planFeaturesArray;
};
