import React from 'react';
import { useSelector } from 'react-redux';

import { Modal } from 'antd';

import CloseIcon from '../../../../../../../../../resources/icons/CloseIcon';
import { colorPalette } from '../../../../../../../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import ExamplesModalContent from './ExamplesModalContent';

import './ExamplesModal.scss';

const classes = {
    modalWrapper: 'examples-modal-wrapper',
    modalWrapperDark: 'modal-wrapper-dark dark-input-autofill',
};

const OptimizerKeywordExamplesModal = ({ isOpen, handleOpen, keywordItem }) => {
    const darkMode = useSelector(selectDarkMode);

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    const handleCloseModal = () => {};

    return (
        <Modal
            width={'690px'}
            centered
            forceRender
            title='Keyword usage examples'
            open={isOpen}
            onOk={handleOpen}
            onCancel={handleOpen}
            footer={null}
            closeIcon={<CloseIcon color={closeModalIconColor} />}
            className={`${classes.modalWrapper}
                    ${darkMode ? classes.modalWrapperDark : ''}`}
            afterClose={handleCloseModal}
        >
            <ExamplesModalContent keywordItem={keywordItem} />
        </Modal>
    );
};

export default OptimizerKeywordExamplesModal;
