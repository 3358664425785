import React from 'react';

import VersionDropdown from './content/VersionDropdown';
import VersionStatus from './content/VersionStatus';

import './VersionHistory.scss';

const classes = {
    versionHistory: 'version-history',
};

const VersionHistory = ({
    loading,
    versions,
    previewVersionId,
    handleActivateVersion,
    createNewVersion,
    handlePreviewVersion,
    newVersionCreating,
    disableCreateNewVersionBtn,
    handleUpdateVersionName,
    handleDeleteVersion,
}) => {
    return (
        <div className={`${classes.versionHistory}`}>
            <VersionStatus loading={loading} />
            <VersionDropdown
                loading={loading}
                versions={versions}
                previewVersionId={previewVersionId}
                handleActivateVersion={handleActivateVersion}
                createNewVersion={createNewVersion}
                handlePreviewVersion={handlePreviewVersion}
                newVersionCreating={newVersionCreating}
                disableCreateNewVersionBtn={disableCreateNewVersionBtn}
                handleUpdateVersionName={handleUpdateVersionName}
                handleDeleteVersion={handleDeleteVersion}
            />
        </div>
    );
};

export default VersionHistory;
