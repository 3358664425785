import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Spin, Typography } from 'antd';
import moment from 'moment';

import {
    selectCurrentSubscription,
    selectIsCurrentSubscriptionLoading,
} from '../store/billingSettings.selectors';
import {
    selectDarkMode,
    selectIsSubscriptionExpired,
} from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { getPlanFeaturesArray } from '../../../../../utils/helpers/getPlanFeaturesArray';
import { useOrganizationSlug } from '../../../../../utils/hooks/useOrganizationSlug';
import { usePaymentMethod } from '../../../../../utils/hooks/usePaymentMethod';

import StatusList from '../../../../common/lists/StatusList';
import Loader from '../../../../common/loader/Loader';

const { Title, Link } = Typography;

const classes = {
    subscriptionWrapper: 'subscription-wrapper',
    subscriptionSectiontitle: 'section-title',
    textAccent: 'text-accent',
    link: 'link-hover-highlight',
};

const SubscriptionSection = () => {
    const navigate = useNavigate();

    const isSubscriptionExpired = useSelector(selectIsSubscriptionExpired);
    const darkMode = useSelector(selectDarkMode);

    const organizationSlug = useOrganizationSlug();
    const { paymentMethod } = usePaymentMethod();

    const currentSubscription = useSelector(selectCurrentSubscription);
    const isSubscriptionLoading = useSelector(
        selectIsCurrentSubscriptionLoading
    );

    const subscriptionFeaturesListItems = getPlanFeaturesArray(
        currentSubscription?.plan?.metadata,
        currentSubscription?.plan?.interval
    ).map((feature) => ({
        success: true,
        text: `${typeof feature?.value !== 'boolean' ? feature?.value : ''} ${
            feature?.title
        }`,
    }));

    const handleViewPlanOptions = () => {
        navigate(`/${organizationSlug}/account/plans`);
    };

    return (
        <section className={classes.subscriptionWrapper}>
            <Title level={4} className={classes.subscriptionSectiontitle}>
                Subscription Info
            </Title>
            <Spin spinning={isSubscriptionLoading} indicator={<Loader />}>
                {!isSubscriptionExpired ? (
                    <>
                        <Typography>
                            You're subscribed to the{' '}
                            <span className={classes.textAccent}>
                                {currentSubscription?.plan?.name || 'Starter'}
                            </span>{' '}
                            for{' '}
                            <span className={classes.textAccent}>
                                ${currentSubscription?.plan?.amount / 100 || 0}/
                                {currentSubscription?.plan?.interval}
                            </span>
                            . The next billing period starts on{' '}
                            <span className={classes.textAccent}>
                                {currentSubscription
                                    ? moment(
                                          currentSubscription?.current_period_end
                                      ).format('LL')
                                    : ''}
                            </span>{' '}
                            at{' '}
                            <span className={classes.textAccent}>
                                {currentSubscription
                                    ? moment(
                                          currentSubscription?.current_period_end
                                      ).format('LT')
                                    : ''}
                                .
                            </span>
                        </Typography>
                        <StatusList listItems={subscriptionFeaturesListItems} />
                    </>
                ) : (
                    <Typography className='mb-1'>
                        You're not subscribed to any plan.
                    </Typography>
                )}
                {!isSubscriptionLoading &&
                    (paymentMethod ? (
                        <Link
                            underline
                            className={`${classes.link}
                    ${darkMode ? 'dark-mode' : ''}`}
                            onClick={handleViewPlanOptions}
                        >
                            View plan options
                        </Link>
                    ) : (
                        <Typography>
                            Add payment method to be able to view other plan
                            options
                        </Typography>
                    ))}
            </Spin>
        </section>
    );
};

export default SubscriptionSection;
