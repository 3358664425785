import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Typography } from 'antd';
import moment from 'moment';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import { selectDarkMode } from '../../../../../../userBrowserSettings/store/browserSettings.selectors';
import {
    selectContentMonitorChartData,
    selectContentMonitorQueryParams,
} from '../../store/contentMonitorMain.selectors';

import ContentMonitorChartTooltip from './ContentMonitorChartTooltip';

import { monitorDateSortKeys } from '../header/constants';
import { getMondaysLastDays, getMondaysOfMonth } from './constants';

import './ContentMonitorChart.scss';

const classes = {
    contentMonitorChart: 'content-monitor-chart',
    mainLabel: 'main-label',
};

const getScoreKey = (rankability_score) => {
    if (rankability_score <= 100 && rankability_score > 95) {
        return 'ultraRelevant';
    } else if (rankability_score <= 95 && rankability_score > 79) {
        return 'extremelyRelevant';
    } else if (rankability_score <= 79 && rankability_score > 63) {
        return 'veryRelevant';
    } else if (rankability_score <= 63 && rankability_score > 47) {
        return 'strongRelevance';
    } else if (rankability_score <= 47 && rankability_score > 31) {
        return 'moderateRelevance';
    } else if (rankability_score <= 31 && rankability_score > 15) {
        return 'lowRelevance';
    } else if (rankability_score <= 15 && rankability_score > 0) {
        return 'irrelevant';
    } else {
        return 'irrelevant';
    }
};

const ContentMonitorChartSection = () => {
    const darkMode = useSelector(selectDarkMode);

    const contentMonitorChartData = useSelector(selectContentMonitorChartData);
    const monitorQueryParams = useSelector(selectContentMonitorQueryParams);

    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (contentMonitorChartData?.length > 0) {
            let uniqueWeekRanges = [];

            if (
                monitorQueryParams?.dateRange === monitorDateSortKeys.thisMonth
            ) {
                uniqueWeekRanges = getMondaysOfMonth();
            } else if (
                monitorQueryParams?.dateRange === monitorDateSortKeys.lastMonth
            ) {
                uniqueWeekRanges = getMondaysOfMonth(true);
            } else if (
                monitorQueryParams?.dateRange === monitorDateSortKeys.last30Days
            ) {
                uniqueWeekRanges = getMondaysLastDays(30);
            } else if (
                monitorQueryParams?.dateRange === monitorDateSortKeys.last90Days
            ) {
                uniqueWeekRanges = getMondaysLastDays(90);
            } else if (
                monitorQueryParams?.dateRange ===
                monitorDateSortKeys.last12Months
            ) {
                uniqueWeekRanges = getMondaysLastDays(365);
            }

            let mappedChartData = [];

            for (const week of uniqueWeekRanges) {
                mappedChartData?.push({
                    week,
                    name: `${moment(week, 'DD.MM.YYYY').format(
                        'MMM DD/YY'
                    )} - ${moment(week, 'DD.MM.YYYY')
                        .add(7, 'days')
                        .format('MMM DD/YY')}`,
                    ultraRelevant: 0,
                    extremelyRelevant: 0,
                    veryRelevant: 0,
                    strongRelevance: 0,
                    moderateRelevance: 0,
                    lowRelevance: 0,
                    irrelevant: 0,
                });
            }

            contentMonitorChartData?.forEach((monitor) => {
                const scores = JSON.parse(monitor?.rankability_scores);

                scores?.forEach((score) => {
                    mappedChartData?.forEach((chartItem) => {
                        if (chartItem?.week === score?.week) {
                            const rScoreKey = getScoreKey(
                                score?.rankability_score
                            );

                            chartItem[rScoreKey] += 1;
                        }
                    });
                });
            });

            mappedChartData.sort(
                (a, b) =>
                    new Date(a.week.split('.').reverse().join('-')) -
                    new Date(b.week.split('.').reverse().join('-'))
            );
            setChartData(mappedChartData);
        } else {
            setChartData([]);
        }
    }, [contentMonitorChartData, monitorQueryParams?.dateRange]);

    return (
        <div
            className={`${classes.contentMonitorChart} ${
                darkMode ? 'dark-mode' : ''
            }`}
        >
            <Typography
                className={`${classes.mainLabel} ${
                    darkMode ? 'dark-mode' : ''
                }`}
            >
                Monitored Page RS
            </Typography>
            <ResponsiveContainer width='100%' height={200}>
                <BarChart
                    width={500}
                    height={150}
                    data={chartData}
                    margin={{ left: -16, right: 16, top: 0, bottom: 0 }}
                >
                    <CartesianGrid strokeDasharray='8 8' />
                    <XAxis dataKey='name' tick={chartData?.length > 0} />
                    <YAxis tickCount={4} allowDecimals={false} />
                    <ContentMonitorChartTooltip />
                    <Tooltip content={<ContentMonitorChartTooltip />} />
                    <Legend
                        width={'100%'}
                        wrapperStyle={{ left: '5px', bottom: '-5px' }}
                    />
                    <Bar
                        dataKey='ultraRelevant'
                        fill='#1EA11D'
                        name='Ultra Relevant'
                    />
                    <Bar
                        dataKey='extremelyRelevant'
                        fill='#4DA91D'
                        name='Extremely Relevant'
                    />
                    <Bar
                        dataKey='veryRelevant'
                        fill='#81B21F'
                        name='Very Relevant'
                    />
                    <Bar
                        dataKey='strongRelevance'
                        fill='#BBBB21'
                        name='Strong Relevance'
                    />
                    <Bar
                        dataKey='moderateRelevance'
                        fill='#C38E21'
                        name='Moderate Relevance'
                    />
                    <Bar
                        dataKey='lowRelevance'
                        fill='#CC5C24'
                        name='Low Relevance'
                    />
                    <Bar
                        dataKey='irrelevant'
                        fill='#D52624'
                        name='Irrelevant'
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default ContentMonitorChartSection;
