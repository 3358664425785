import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Typography, Spin } from 'antd';

import {
    selectIsGracePeriod,
    selectIsSubscriptionExpired,
} from '../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { statusCodes } from '../../../../../../constants/statusCodes';
import { paymentsPathes } from '../../../../../../constants/queryPathes';
import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../constants/notificationType';
import { billingPlanIntervals } from '../../BillingConstants';

import { useOrganizationSlug } from '../../../../../../utils/hooks/useOrganizationSlug';
import { openNotification } from '../../../../../../utils/helpers/openNotification';
import { getAxiosHeaders } from '../../../../../../utils/helpers/getAxiosHeaders';
import { axiosAPI } from '../../../../../../utils/axiosAPI';

import Loader from '../../../../../common/loader/Loader';

import PlanTypesSwitcher from './PlanTypesSwitcher';
import BillingPlanCard from './BillingPlanCard';

const { Title, Link } = Typography;

const classes = {
    billingPlansContentWrapper:
        'billing-plans-content-wrapper d-flex flex-column align-items-center',
    billingPlansTitleWrapper:
        'billing-plans-title-wrapper d-flex flex-column align-items-center',
    billingSwitchWrapper: 'billing-switch-wrapper',
    billingPlansCardsWrapper: 'billing-plans-cards-wrapper',
    billingFooterText: 'billing-footer-text',
};

const BillingPlansContent = () => {
    const navigate = useNavigate();

    const isSubscriptionExpired = useSelector(selectIsSubscriptionExpired);
    const isGracePeriod = useSelector(selectIsGracePeriod);

    const organizationSlug = useOrganizationSlug();

    const [plansData, setPlansData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);

        axiosAPI
            .get(paymentsPathes.getProducts, {
                params: {
                    active: true,
                },
                ...getAxiosHeaders(),
            })
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    setPlansData(
                        result?.data
                            ?.sort(
                                (a, b) =>
                                    a?.prices?.find(
                                        (x) =>
                                            x.reccuring?.interval ===
                                            billingPlanIntervals.monthly
                                    )?.unit_amount -
                                    b?.prices?.find(
                                        (x) =>
                                            x.reccuring?.interval ===
                                            billingPlanIntervals.monthly
                                    )?.unit_amount
                            )
                            ?.filter(
                                (plan) =>
                                    !plan?.metadata?.custom_solution &&
                                    plan?.metadata?.credit !== 1
                            )
                    );
                }
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const handlePurchaseCredits = () => {
        navigate(`/${organizationSlug}/account/credits`);
    };

    return (
        <div className={classes.billingPlansContentWrapper}>
            <div className={classes.billingPlansTitleWrapper}>
                <Title level={4}>Plans & Pricing</Title>
                <PlanTypesSwitcher isLoading={isLoading} />
            </div>
            <Spin spinning={isLoading} indicator={<Loader />}>
                <div className={classes.billingPlansCardsWrapper}>
                    {plansData.map((plan) => (
                        <BillingPlanCard key={plan.name} planData={plan} />
                    ))}
                </div>
                <div className={classes.billingFooterText}>
                    {!isSubscriptionExpired && !isGracePeriod && (
                        <Title level={5}>
                            Need more credits?{' '}
                            <Link onClick={handlePurchaseCredits}>
                                Purchase additional credits
                            </Link>
                        </Title>
                    )}
                    <Title level={5}>
                        Need a larger plan?{' '}
                        <Link>Ask us about our SEO Enterprise solution</Link>
                    </Title>
                </div>
            </Spin>
        </div>
    );
};

export default BillingPlansContent;
