import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from 'antd';

import { selectDarkMode } from '../../../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { optimizerKeywordsSortItems } from '../../../../../../../../constants/optimizerKeywordsSortItems';

import { useIconColor } from '../../../../../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../../../../../utils/hooks/useOpen';

import SiderChevronDownIcon from '../../../../../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../../../../../resources/icons/SiderChevronUp';
import { colorPalette } from '../../../../../../../../resources/styles/colorPalette';

import { selectKeywordsSortKey } from '../../../../store/contentOptimizerContent.selectors';
import { updateKeywordsSortKey } from '../../../../store/contentOptimizerContent.actions';
import CustomDropdown from '../../../../../../../common/dropdown/CustomDropdown';

const classes = {
    sortWrapper: 'sort-wrapper optimizer-keywords-sort-wrapper d-flex',
    dropdownOverlay: 'dropdown-with-arrow-overlay dropdown-menu',
    sortText: 'sort-text d-flex align-items-center justify-content-between',
};

const getSelectedItemLabel = (sortKey) => {
    return optimizerKeywordsSortItems?.find((item) => item?.key === sortKey)
        ?.label;
};

const OptimizerKeywordsSortDropdown = () => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const sortKey = useSelector(selectKeywordsSortKey);

    const { isOpen, handleOpen } = useOpen();
    const iconColor = useIconColor();

    const onSelect = (item) => {
        dispatch(updateKeywordsSortKey(item?.key));
        handleOpen();
    };

    return (
        <CustomDropdown
            dropdownOpenElement={
                <div
                    className={`${classes.sortWrapper} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                >
                    <Typography className={classes.sortText}>
                        Sort by:
                        <span className='fw-500'>
                            {getSelectedItemLabel(sortKey)}
                        </span>
                    </Typography>
                    <Typography className={classes.sortText}>
                        {isOpen ? (
                            <SiderChevronUpIcon
                                color={colorPalette.colorPrimary}
                            />
                        ) : (
                            <SiderChevronDownIcon color={iconColor} />
                        )}
                    </Typography>
                </div>
            }
            dropdownMenu={{
                items: optimizerKeywordsSortItems,
                selectable: true,
                defaultSelectedKeys: [sortKey],
                selectedKeys: [sortKey],
                onSelect,
            }}
            dropdownTriggersArray={['click']}
            dropdownPlacement={'bottom'}
            dropdownOverlayClassName={classes.dropdownOverlay}
            handleOpenChange={handleOpen}
            isDropdownOpen={isOpen}
        />
    );
};

export default OptimizerKeywordsSortDropdown;
