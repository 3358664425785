// Main editor content
export const selectCurrentEditorContent = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.currentEditorContent;
export const selectAdditionalEditorContent = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.additionalEditorContent;
export const selectCurrentEditorId = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.currentEditorId;
export const selectCurrentEditorText = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.currentEditorText;

// Loadings
export const selectCurrentEditorLoading = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.currentEditorLoading;
export const selectSaveEditorLoading = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.saveEditorLoading;

// Versions
export const selectEditorVersions = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.editorVersions;
export const selectCurrentEditorVersion = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.currentEditorVersion;
export const selectCurrentVersionImportedUrl = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.currentVersionImportedUrl;
export const selectEditorPreviewVersion = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.previewVersion;

/**
 * @param {*} state
 * @returns {object} basicData object or undefined
 */
export const selectEditorBasicData = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.basicData;

// Settings
export const selectEditorHtmlTags = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.htmlTags;
export const selectEditorFullWidth = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.fullWidth;
export const selectEditorHighlightKeywords = (state) =>
    state?.contentOptimizer?.contentOptimizerEditor?.highlightKeywords;

// AI
export const selectGeneratedAIToken = (state) =>
    state.contentOptimizer?.contentOptimizerEditor?.generatedAIToken;
export const selectAIState = (state) =>
    state.contentOptimizer?.contentOptimizerEditor?.aiState;
export const selectAIStateLoading = (state) =>
    state.contentOptimizer?.contentOptimizerEditor?.aiState.isLoading;
