import React from 'react';
import { useSelector } from 'react-redux';

import { Button, Dropdown, Typography } from 'antd';

import { selectDarkMode } from '../../../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import ExportIcon from '../../../../../../../../../../resources/icons/ExportIcon';
import SiderChevronDownIcon from '../../../../../../../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../../../../../../../resources/icons/SiderChevronUp';
import { colorPalette } from '../../../../../../../../../../resources/styles/colorPalette';

import { useIconColor } from '../../../../../../../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../../../../../../../utils/hooks/useOpen';

import EditorMoreSettings from './EditorMoreSettings';
import ImportFromUrlModal from './ImportFromUrlModal';

import { selectContentOptimizerViewOnlyMode } from '../../../../../../../viewOnly/store/viewOnlySettings.selectors';

import './EditorMoreDropdown.scss';

const classes = {
    listMoreButton:
        'list-more-button d-flex flex-center align-items-center btn-hover-light-primary',
    moreDropdownOverlay:
        'dropdown-with-arrow-overlay more-dropdown-overlay dropdown-menu dropdown-overlay-with-extra-content',
    moreDropdownOverlayDark:
        'dropdown-with-arrow-overlay-dark more-dropdown-overlay-dark dropdown-overlay-with-extra-content-dark',
    moreDropdownContentWrapper: 'dropdown-with-extra-content-wrapper',
    editorMoreBtn: 'editor-more-btn',

    importRowWrapper: 'd-flex align-items-center',
    importRow: 'import-row d-flex',
};

const EditorMoreDropdown = () => {
    const darkMode = useSelector(selectDarkMode);
    const viewOnlyMode = useSelector(selectContentOptimizerViewOnlyMode);

    const { isOpen: isDropdownOpen, handleOpen: handleDropdownOpen } =
        useOpen();
    const { isOpen: isModalOpen, handleOpen: handleModalOpen } = useOpen();
    const iconColor = useIconColor();

    const handleImportFromUrl = () => {
        handleModalOpen();
        handleDropdownOpen();
    };

    const moreItems = [
        {
            key: 'copy-url',
            label: (
                <div className={classes.importRowWrapper}>
                    <div className={classes.importRow}>
                        <ExportIcon color={colorPalette.colorPrimary} />
                    </div>{' '}
                    <span>URL Content Extraction</span>
                </div>
            ),
            onClick: handleImportFromUrl,
        },
    ];

    return (
        <>
            <Dropdown
                menu={{ items: moreItems }}
                arrow
                placement='bottomRight'
                trigger={['click']}
                overlayClassName={`${classes.moreDropdownOverlay}
                                   ${
                                       darkMode &&
                                       classes.moreDropdownOverlayDark
                                   }`}
                onOpenChange={handleDropdownOpen}
                dropdownRender={(menu) => (
                    <div className={classes.moreDropdownContentWrapper}>
                        {!viewOnlyMode && menu}
                        <EditorMoreSettings />
                    </div>
                )}
            >
                <Button
                    className={`${classes.editorMoreBtn}
                            ${darkMode ? 'dark-mode' : ''}`}
                    icon={
                        isDropdownOpen ? (
                            <SiderChevronUpIcon
                                color={colorPalette.colorPrimary}
                            />
                        ) : (
                            <SiderChevronDownIcon color={iconColor} />
                        )
                    }
                    onClick={handleDropdownOpen}
                >
                    <Typography>More</Typography>
                </Button>
            </Dropdown>
            <ImportFromUrlModal
                isOpen={isModalOpen}
                handleOpen={handleModalOpen}
            />
        </>
    );
};

export default EditorMoreDropdown;
