import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Popconfirm, Typography } from 'antd';
import moment from 'moment';

import {
    selectCurrentSubscription,
    selectCurrentSubscriptionCancelAtPeriodEnd,
} from '../../store/billingSettings.selectors';
import { selectDarkMode } from '../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { updateCurrentSubscriptionCancelAtPeriodEnd } from '../../store/billingSettings.actions';

import { axiosAPI } from '../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../../utils/helpers/openNotification';
import SubscriptionHelper from '../../../../auth/helpers/subscription.helper';

import { paymentsPathes } from '../../../../../../constants/queryPathes';
import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../constants/notificationType';
import { statusCodes } from '../../../../../../constants/statusCodes';

const { Title } = Typography;

const classes = {
    subscriptionInfoWrapper: 'subscription-info-wrapper d-flex flex-center',
    textAccent: 'fw-600',
    popconfirm: 'delete-popconfirm',
    popconfirmDark: 'delete-popconfirm-dark',
};

const SubscriptionInfo = () => {
    const currentSubscription = useSelector(selectCurrentSubscription);
    const darkMode = useSelector(selectDarkMode);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const currentSubscriptionCanceled = useSelector(
        selectCurrentSubscriptionCancelAtPeriodEnd
    );

    if (
        currentSubscriptionCanceled ||
        !currentSubscription ||
        !currentSubscription?.paid
    ) {
        return null;
    }

    const handleCancelSubscription = () => {
        setIsLoading(true);

        axiosAPI
            .post(
                `${paymentsPathes.cancelSubscription}`,
                {},
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    dispatch(
                        updateCurrentSubscriptionCancelAtPeriodEnd(
                            result?.data?.cancel_at_period_end
                        )
                    );
                    openNotification(
                        notificationType.success,
                        'Success',
                        'Subscription successfully canceled'
                    );
                }
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className={classes.subscriptionInfoWrapper}>
            <Title level={5}>
                You're subscribed to the{' '}
                <span className={classes.textAccent}>
                    {currentSubscription?.plan?.name || 'Starter'}
                </span>{' '}
                for{' '}
                <span className={classes.textAccent}>
                    ${currentSubscription?.plan?.amount / 100 || 0}/
                    {currentSubscription?.plan?.interval}
                </span>
                . The next billing period starts on{' '}
                <span className={classes.textAccent}>
                    {currentSubscription
                        ? moment(
                              currentSubscription?.current_period_end
                          ).format('LL')
                        : ''}
                    .
                </span>
            </Title>
            {SubscriptionHelper.isTrial(currentSubscription) && (
                <Popconfirm
                    placement='bottomRight'
                    title='Are you sure you want to cancel your subscription?'
                    onConfirm={handleCancelSubscription}
                    okText='Yes'
                    okButtonProps={{ loading: isLoading }}
                    cancelText='Cancel'
                    overlayClassName={`${classes.popconfirm}
                                       ${
                                           darkMode
                                               ? classes.popconfirmDark
                                               : ''
                                       }`}
                    autoAdjustOverflow
                >
                    {' '}
                    <Button type='primary' loading={isLoading}>
                        Cancel
                    </Button>
                </Popconfirm>
            )}
        </div>
    );
};

export default SubscriptionInfo;
