import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { renderPaginationArrows } from '../../../../utils/helpers/renderPaginationArrows';
import { useKeywordsListOfResultsColumns } from '../../../../utils/hooks/useKeywordsListOfResultsColumns';
import { useKeywordUrlsColumns } from '../../../../utils/hooks/useKeywordUrlsColumns';

import { keywordTaskStatus } from '../../../../constants/keywordTaskStatus';
import { keywordTypes } from './constants';

import Loader from '../../../common/loader/Loader';

import { setKeywordListPagination } from '../store/keywordResearchSettings.actions';
import {
    selectCurrentKeyword,
    selectKeywordListPagination,
} from '../store/keywordResearchSettings.selectors';

import './KeywordListOfResult.scss';

const classes = {
    keywordTable: 'keyword-table',
    tableRow: 'table-row',
    columnContentColor: 'column-content-color',
    columnContentColorDark: 'column-content-color-dark',
    hideXScroll: 'hide-x-scroll',
    urlTab: 'url-tab',
};

const KeywordListTable = ({ isListLoading }) => {
    const dispatch = useDispatch();

    const currentKeyword = useSelector(selectCurrentKeyword);
    const darkMode = useSelector(selectDarkMode);

    const keywordListPagination = useSelector(selectKeywordListPagination);

    const keywordColumns = useKeywordsListOfResultsColumns();
    const urlColumns = useKeywordUrlsColumns();

    const [mappedKeywordListData, setMappedKeywordListData] = useState([]);

    useEffect(() => {
        if (currentKeyword) {
            setMappedKeywordListData(
                currentKeyword.task_result?.map((item) => {
                    return {
                        relatedSearches: item?.keyword,
                        volume: item?.search_volume,
                        trend: item?.monthly_searches,
                        cpc: {
                            high: item?.cpc_to,
                            low: item?.cpc_from,
                        },
                        position: item?.position,
                        difficulty: item?.difficulty,
                        highlight: item?.highlight,
                        searchIntent: item?.search_intent,
                        updated: item?.last_updated_time,
                    };
                })
            );
        }
    }, [currentKeyword, currentKeyword?.task_result]);

    const handleChange = (pagination) => {
        window?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        dispatch(
            setKeywordListPagination(
                pagination?.current
                    ? {
                          page:
                              keywordListPagination.perPage !==
                              pagination.pageSize
                                  ? 1
                                  : pagination.current,
                          perPage: pagination.pageSize,
                      }
                    : null
            )
        );
    };

    const scrollOption =
        isListLoading || !currentKeyword?.id ? undefined : { x: true };

    return (
        <Table
            rowKey={(record) => record.relatedSearches}
            dataSource={mappedKeywordListData}
            columns={
                currentKeyword?.type === keywordTypes.keyword
                    ? keywordColumns
                    : urlColumns
            }
            loading={{
                spinning:
                    isListLoading ||
                    !currentKeyword ||
                    (!currentKeyword?.task_result &&
                        !(
                            // same as in KeywordResultsContentLayout.jsx
                            // if we have not found error or DFSEO error we should not display loader
                            (
                                currentKeyword?.task_status ===
                                    keywordTaskStatus.notFound ||
                                keywordTaskStatus.error.test(
                                    currentKeyword?.task_status
                                )
                            )
                        )),
                indicator: <Loader />,
            }}
            bordered
            showSorterTooltip={false}
            rowClassName={`${classes.tableRow} ${classes.columnContentColor} ${
                darkMode ? classes.columnContentColorDark : ''
            }`}
            className={`${classes.keywordTable} ${
                darkMode ? 'dark-mode' : ''
            } ${mappedKeywordListData?.length > 0 ? '' : classes.hideXScroll} ${
                currentKeyword?.type === keywordTypes.url ? classes.urlTab : ''
            }`}
            onChange={handleChange}
            pagination={{
                pageSize: keywordListPagination?.perPage || 50,
                defaultCurrent: keywordListPagination?.page || 1,
                current: keywordListPagination?.page || 1,
                itemRender: renderPaginationArrows,
                total:
                    currentKeyword?.filtered_result_count ||
                    currentKeyword?.result_count,
            }}
            scroll={scrollOption}
            sticky={{
                offsetHeader: -4,
            }}
        />
    );
};

export default KeywordListTable;
