import React from 'react';
import { useSelector } from 'react-redux';

import { useCurrentEditor } from '@tiptap/react';
import { Select } from 'antd';

import SiderChevronDownIcon from '../../../../../resources/icons/SiderChevronDown';
import SiderChevronUpIcon from '../../../../../resources/icons/SiderChevronUp';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { useIconColor } from '../../../../../utils/hooks/useIconColor';
import { useOpen } from '../../../../../utils/hooks/useOpen';

import { headingOptions, headingOptionsValues } from '../constants';

const { Option } = Select;

const classes = {
    headingSection: 'heading-section',
};

const HeadingSection = () => {
    const { editor } = useCurrentEditor();

    const darkMode = useSelector(selectDarkMode);

    const iconColor = useIconColor();
    const { isOpen, handleOpen } = useOpen();

    const getActiveHeading = () => {
        if (editor.isActive(headingOptionsValues.blockquote)) {
            return headingOptionsValues.blockquote;
        }

        const headingOptionsValue = headingOptions?.find((item) => {
            if (item.level) {
                return editor.isActive(item.levelName, {
                    level: item.level,
                });
            }

            return editor.isActive(item.value);
        })?.value;

        return (
            headingOptionsValue ||
            (headingOptions?.length && headingOptions[0]?.value)
        );
    };

    return (
        <div
            className={`${classes.headingSection} ${
                darkMode ? 'dark-mode' : ''
            }`}
        >
            <Select
                value={getActiveHeading()}
                style={{ width: 120 }}
                bordered={false}
                suffixIcon={
                    isOpen ? (
                        <SiderChevronUpIcon color={colorPalette.colorPrimary} />
                    ) : (
                        <SiderChevronDownIcon color={iconColor} />
                    )
                }
                onDropdownVisibleChange={handleOpen}
            >
                {headingOptions?.map((item, index) => {
                    return (
                        <Option
                            key={`${index}-${item.value}`}
                            value={item.value}
                            label={item.label}
                            title=''
                            onMouseDown={() => {
                                item.handleSelect(editor);
                            }}
                        >
                            {item.label}
                        </Option>
                    );
                })}
            </Select>
        </div>
    );
};

export default HeadingSection;
