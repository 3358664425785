import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectContentOptimizerKeywords } from '../../components/pages/contentOptimizer/contentPage/store/contentOptimizerContent.selectors';

import {
    selectCurrentEditorText,
    selectEditorPreviewVersion,
} from '../../components/common/tiptapEditor/store/tiptapEditor.selectors';

import { getWordBoundaryRegex } from '../helpers/regexHelpers';

export const useHighlightedKeywords = () => {
    const keywords = useSelector(selectContentOptimizerKeywords);

    const currentEditorText = useSelector(selectCurrentEditorText);
    const previewEditorVersion = useSelector(selectEditorPreviewVersion);

    const [mappedKeywords, setMappedKeywords] = useState([]);
    const [mappedEditorContent, setMappedEditorContent] = useState('');

    useEffect(() => {
        if (keywords && keywords?.length > 0) {
            const mappedKeywords = keywords.map((item) => {
                return {
                    ...item,
                    keyword: item?.keyword,
                };
            });

            setMappedKeywords(mappedKeywords);
        }
    }, [keywords]);

    useEffect(() => {
        if (previewEditorVersion?.content) {
            setMappedEditorContent(
                previewEditorVersion?.content_text ||
                    previewEditorVersion?.content
            );
        } else {
            setMappedEditorContent(currentEditorText || '');
        }
    }, [previewEditorVersion, currentEditorText]);

    const highlightedKeywords = useMemo(() => {
        if (mappedEditorContent && mappedKeywords) {
            const highlightedKeywordsSet = new Set();
            const lowerCasedEditorText = mappedEditorContent.toLocaleLowerCase();

            mappedKeywords?.forEach((keywordItem) => {
                const keywordPattern = getWordBoundaryRegex(
                    keywordItem.keyword.toLocaleLowerCase()
                );
                const isFoundInText = keywordPattern.test(lowerCasedEditorText);
                // There is try-catch block to fix broken regex expression
                try {
                    if (
                        (keywordItem.regex &&
                            lowerCasedEditorText.match(keywordItem.regex)) ||
                        isFoundInText
                    ) {
                        highlightedKeywordsSet.add(keywordItem.keyword);
                    }
                } catch (_err) {
                    if (isFoundInText) {
                        highlightedKeywordsSet.add(keywordItem.keyword);
                    }
                }
            });

            const highlightedUsedKeywords = Array.from(
                highlightedKeywordsSet
            ).map((item) => ({
                keyword: item,
                uses: 0,
                regex:
                    mappedKeywords.find(
                        (keywordItem) => keywordItem?.keyword === item
                    )?.regex || null,
            }));

            const getHighlightedUsedKeywords = (item) => {
                let startIndex = 0;

                while (startIndex !== -1) {
                    if (startIndex === lowerCasedEditorText.length - 1) {
                        startIndex = -1;
                        break;
                    }

                    const slicedEditor = lowerCasedEditorText.slice(startIndex);

                    const matchedKeyword = slicedEditor?.includes(
                        item.keyword.toLocaleLowerCase()
                    )
                        ? item.keyword.toLocaleLowerCase()
                        : null;

                    if (matchedKeyword) {
                        const firstIndex = slicedEditor.indexOf(matchedKeyword);

                        if (firstIndex === -1) {
                            startIndex = -1;
                            break;
                        } else {
                            item.uses += 1;
                            startIndex =
                                firstIndex + matchedKeyword.length + startIndex;
                        }
                    } else {
                        startIndex = -1;
                    }
                }

                return item;
            };

            highlightedUsedKeywords.forEach((item) => {
                if (item?.regex) {
                    // There is try-catch block to fix broken regex expression
                    try {
                        const pattern = item?.regex;
                        const matches = Array.from(
                            lowerCasedEditorText.matchAll(pattern)
                        );

                        if (matches) {
                            item.uses = matches?.length;
                        } else {
                            item = getHighlightedUsedKeywords(item);
                        }
                    } catch (_err) {
                        item = getHighlightedUsedKeywords(item);
                    }
                } else {
                    item = getHighlightedUsedKeywords(item);
                }
            });

            return highlightedUsedKeywords;
        }
    }, [mappedEditorContent, mappedKeywords]);

    return highlightedKeywords || [];
};
