import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { openNotification } from '../../../utils/helpers/openNotification';
import { getAxiosHeaders } from '../../../utils/helpers/getAxiosHeaders';
import { axiosAPI } from '../../../utils/axiosAPI';
import SubscriptionHelper from '../../pages/auth/helpers/subscription.helper';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../constants/notificationType';
import { paymentsPathes } from '../../../constants/queryPathes';
import { statusCodes } from '../../../constants/statusCodes';

import {
    selectCurrentSubscription,
    selectCurrentSubscriptionCancelAtPeriodEnd,
} from '../../pages/account/billing/store/billingSettings.selectors';
import { selectIsSubscriptionExpired } from '../../../userBrowserSettings/store/browserSettings.selectors';
import { updateCurrentSubscriptionCancelAtPeriodEnd } from '../../pages/account/billing/store/billingSettings.actions';

import TopPageWarningNotification from './TopPageWarningNotification';

import './Notifications.scss';

const CancelledSubscriptionNotification = () => {
    const dispatch = useDispatch();

    const currentSubscription = useSelector(selectCurrentSubscription);
    const canceledAtPeriodAnd = useSelector(
        selectCurrentSubscriptionCancelAtPeriodEnd
    );
    const isSubscriptionExpired = useSelector(selectIsSubscriptionExpired);
    const isCanceledTrial =
        SubscriptionHelper.isCanceled(currentSubscription) &&
        SubscriptionHelper.isTrial(currentSubscription);

    const [isLoading, setIsLoading] = useState(false);

    const handleResumeSubscription = () => {
        setIsLoading(true);

        axiosAPI
            .post(
                paymentsPathes.resumeSubscription,
                {},
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    dispatch(
                        updateCurrentSubscriptionCancelAtPeriodEnd(
                            result?.data?.cancel_at_period_end
                        )
                    );
                    openNotification(
                        notificationType.success,
                        'Success',
                        `Subscription successfully resumed`
                    );
                }
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    if (
        !currentSubscription ||
        !canceledAtPeriodAnd ||
        isSubscriptionExpired ||
        isCanceledTrial
    )
        return null;

    return (
        <TopPageWarningNotification
            notificationTitle={
                <>
                    Your Rankability account and credits will expire on{' '}
                    <span className='fw-600'>
                        {currentSubscription
                            ? moment(
                                  currentSubscription?.current_period_end
                              ).format('LL')
                            : ''}{' '}
                    </span>
                    because your subscription was canceled.
                </>
            }
            btnText='Resume subscription'
            isLoading={isLoading}
            handleClickButton={handleResumeSubscription}
        />
    );
};

export default CancelledSubscriptionNotification;
