import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useOrganizationSlug } from '../../../utils/hooks/useOrganizationSlug';

import TopPageWarningNotification from './TopPageWarningNotification';
import Timer from '../timer/Timer';

import { selectCurrentSubscription } from '../../pages/account/billing/store/billingSettings.selectors';
import { selectIsSubscriptionExpired } from '../../../userBrowserSettings/store/browserSettings.selectors';
import {
    setPlanToSwitch,
    setSubscriptionLimits,
    updatePlanType,
} from '../../pages/account/billing/store/billingSettings.actions';

import { paymentsPathes } from '../../../constants/queryPathes';

import { axiosAPI } from '../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../utils/helpers/openNotification';
import SubscriptionHelper from '../../pages/auth/helpers/subscription.helper';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../constants/notificationType';
import { billingPlanIntervals } from '../../pages/account/billing/BillingConstants';
import { billingPlanTypes } from '../../../constants/billingPlanConsts';

const classes = {
    timer: 'fw-600 trial-notification-timer',
    btn: 'trial-notification-button',
};

const TrialNotification = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const organizationSlug = useOrganizationSlug();
    const subscription = useSelector(selectCurrentSubscription);

    const canceledSubscription = SubscriptionHelper.isCanceled(subscription);
    const expired = useSelector(selectIsSubscriptionExpired);

    const [showTimer, setShowTimer] = useState(false);

    useEffect(() => {
        if (SubscriptionHelper.isTrial(subscription)) {
            setShowTimer(true);
        }
        if (
            (!canceledSubscription &&
                window.location.href.includes('/account/plans')) ||
            expired
        ) {
            setShowTimer(false);
        }

        // canceledSubscription, expired depend on subscription, that's why there is no need for additional dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscription]);

    const transformPlanDataToPrice = (plan) => {
        return {
            id: plan.product,
            metadata: plan.metadata,
            name: plan.name,
            prices: [
                {
                    id: plan.id,
                    reccuring: {
                        interval: plan.interval,
                        interval_count: plan.interval_count,
                    },
                    type: 'recurring',
                    unit_amount: plan.amount,
                    currency: plan.currency,
                },
            ],
        };
    };

    const handleClick = () => {
        if (
            SubscriptionHelper.isTrial(subscription) &&
            SubscriptionHelper.isCanceled(subscription)
        ) {
            navigate(`/${organizationSlug}/account/plans`);
        } else {
            if (subscription?.plan) {
                dispatch(
                    updatePlanType(
                        subscription.plan.interval ===
                            billingPlanIntervals.monthly
                            ? billingPlanTypes.monthly.value
                            : billingPlanTypes.yearly.value
                    )
                );
                dispatch(
                    setPlanToSwitch(transformPlanDataToPrice(subscription.plan))
                );
                navigate(`/${organizationSlug}/account/plans/checkout`);
            }
        }
    };

    const updateQuotes = () => {
        axiosAPI
            .get(paymentsPathes.getQuotes, { ...getAxiosHeaders() })
            .then((result) => {
                setShowTimer(false);
                dispatch(setSubscriptionLimits(result?.data));
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            });
    };

    return (
        <>
            {showTimer && (
                <TopPageWarningNotification
                    notificationTitle={
                        <>
                            Your Rankability trial ends in{' '}
                            <span className={classes.timer}>
                                <Timer
                                    startDateTime={subscription?.trial_end}
                                    timerEndsCallback={updateQuotes}
                                />
                            </span>
                        </>
                    }
                    btnClass={classes.btn}
                    btnText='Subscribe now'
                    handleClickButton={handleClick}
                />
            )}
        </>
    );
};

export default TrialNotification;
