import { useDispatch, useSelector } from 'react-redux';

import { GoogleOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';

import KeywordChart from '../../components/common/charts/KeywordChart';
import TableInfoModal from '../../components/common/tableInfo/TableInfoModal';
import DifficultyBadge from '../../components/pages/keywordResearch/common/DifficultyBadge';
import IntentBadge from '../../components/pages/keywordResearch/common/IntentBadge';
import { selectKeywordListSort } from '../../components/pages/keywordResearch/store/keywordResearchSettings.selectors';

import { keywordResearchResultsSortFields } from '../../constants/keywordResearchResultsSortFields';
import { difficultyCategories } from '../../constants/difficultyCategories';
import { difficultyBadgeSize } from '../../constants/difficultyBadgeSize';
import { intentCategories } from '../../constants/intentCategories';
import { sortOrders } from '../../constants/sortOrders';
import { monthsList } from '../../constants/months';

import ContentOptimizerIcon from '../../resources/icons/ContentOptimizer';

import { setKeywordListSort } from '../../components/pages/keywordResearch/store/keywordResearchSettings.actions';
import TableHeaderSortIcons from '../../components/common/tables/TableHeaderSortIcons';

import { selectDarkMode } from '../../userBrowserSettings/store/browserSettings.selectors';
import { getNumberWithCommaSeparator } from '../helpers/getNumberWithCommaSeparator';
import { getNumWithZeroAfterDecimal } from '../helpers/getNumWithZeroAfterDecimal';
import { useOrganizationSlug } from './useOrganizationSlug';

const { Link } = Typography;

const classes = {
    reletedSearches: 'd-flex',
    actionsButtons: 'actions-buttons',
    tableHeaderTitle:
        'd-flex align-items-center keywords-result-table-header-title cursor-pointer',
    columnsWidth: 'space-no-wrap text-decoration-dotted',
    textDotted: 'text-decoration-dotted',
    columnContentColor: 'column-content-color',
    magicPen: 'magic-pen-icon',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    highlightKeyword: 'highlight-keyword',
    active: 'active',
};

export const useKeywordsListOfResultsColumns = () => {
    const dispatch = useDispatch();

    const keywordListSort = useSelector(selectKeywordListSort);
    const darkMode = useSelector(selectDarkMode);

    const organizationSlug = useOrganizationSlug();

    const handleSortClick = (field) => () => {
        dispatch(
            setKeywordListSort({
                field: field,
                order:
                    keywordListSort?.field === field &&
                    keywordListSort?.order === sortOrders.desc
                        ? sortOrders.asc
                        : sortOrders.desc,
            })
        );
    };

    const getColumnTitle = ({
        columnName,
        columnTitle = '',
        sortByColumn = false,
        tooltipTitle = '',
        infoComponent = null,
        isSortable = true,
    }) => (
        <div
            className={`${classes.tableHeaderTitle} ${
                darkMode ? 'dark-mode' : ''
            }`}
            onClick={
                isSortable && sortByColumn
                    ? handleSortClick(columnName)
                    : () => null
            }
        >
            <Tooltip
                title={tooltipTitle}
                className={`${
                    keywordListSort?.field === columnName ? classes.active : ''
                }`}
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
            >
                <div className={`${classes.textDotted} space-no-wrap`}>
                    {columnTitle}
                </div>
            </Tooltip>
            {infoComponent}
            {isSortable && (
                <TableHeaderSortIcons
                    active={keywordListSort?.field === columnName}
                    order={keywordListSort?.order}
                />
            )}
        </div>
    );

    const columns = [
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                    onClick={handleSortClick('keyword')}
                >
                    <Tooltip
                        title={
                            'Search queries from Google that are related to your target keyword.'
                        }
                        className={`${
                            keywordListSort?.field ===
                                keywordResearchResultsSortFields.keyword &&
                            classes.active
                        }`}
                        overlayClassName={`${classes.tooltipOverlay}
                                           ${
                                               darkMode
                                                   ? classes.tooltipOverlayDark
                                                   : ''
                                           }`}
                    >
                        <div className={classes.columnsWidth}>
                            RELATED SEARCHES
                        </div>
                    </Tooltip>
                    <TableHeaderSortIcons
                        active={
                            keywordListSort?.field ===
                            keywordResearchResultsSortFields.keyword
                        }
                        order={keywordListSort?.order}
                    />
                </div>
            ),
            dataIndex: 'keyword',
            width: '30%',
            fixed: 'left',
            render: (_, record) => {
                return (
                    <div
                        className={`${classes.reletedSearches} ${
                            record.highlight ? classes.highlightKeyword : ''
                        }`}
                    >
                        {record?.relatedSearches}
                        <Tooltip
                            placement='top'
                            title={
                                'Open Google search results for this keyword in a new tab.'
                            }
                            trigger={['hover']}
                            overlayClassName={`${classes.tooltipOverlay}
                                               ${
                                                   darkMode
                                                       ? classes.tooltipOverlayDark
                                                       : ''
                                               }`}
                        >
                            <Link
                                href={`https://www.google.com/search?q=${record?.relatedSearches?.replace(
                                    ' ',
                                    '+'
                                )}`}
                                target='_blank'
                                rel='noreferrer'
                                className={classes.actionsButtons}
                            >
                                <GoogleOutlined />
                            </Link>
                        </Tooltip>
                        <Tooltip
                            placement='top'
                            title={
                                'Open a new Optimizer for this keyword in a new tab.'
                            }
                            trigger={['hover']}
                            overlayClassName={`${classes.tooltipOverlay}
                                               ${
                                                   darkMode
                                                       ? classes.tooltipOverlayDark
                                                       : ''
                                               }`}
                        >
                            <Link
                                href={`/${organizationSlug}/optimizer?keyword=${record?.relatedSearches?.replace(
                                    ' ',
                                    '+'
                                )}`}
                                target='_blank'
                                rel='noreferrer'
                                className={`${classes.actionsButtons} ${classes.magicPen}`}
                            >
                                <ContentOptimizerIcon size={14.5} />
                            </Link>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            title: getColumnTitle({
                columnName: keywordResearchResultsSortFields.searchIntent,
                columnTitle: 'SEARCH INTENT',
                sortByColumn: true,
                tooltipTitle:
                    'The purpose or goal behind a user’s search query, guiding the type of content that should be provided to fulfill their needs effectively.',
                infoComponent: (
                    <TableInfoModal
                        title={'Search Intent'}
                        infoCategories={intentCategories}
                    />
                ),
                isSortable: false,
            }),
            dataIndex: 'search_intent',
            width: '15%',
            render: (_, record) => {
                if (record.searchIntent) {
                    const showElementCount = 2;
                    return record.searchIntent
                        .split(',')
                        .slice(0, showElementCount)
                        .map((value, index) => (
                            <IntentBadge
                                key={`IntentBadge_${value}_${index}`}
                                value={value}
                            />
                        ));
                }

                return <IntentBadge value={null} />;
            },
        },
        {
            title: getColumnTitle({
                columnName: keywordResearchResultsSortFields.searchVolume,
                columnTitle: 'VOLUME',
                sortByColumn: true,
                tooltipTitle:
                    'Search Volume displays the number of monthly searches in Google over the past 12 months.',
            }),
            dataIndex: 'search_volume',
            width: '10%',
            render: (_, record) => {
                return (
                    <Typography level={5} className='space-no-wrap'>
                        {record?.volume &&
                            getNumberWithCommaSeparator(record?.volume)}
                    </Typography>
                );
            },
        },
        {
            title: getColumnTitle({
                columnName: keywordResearchResultsSortFields.monthlySearches,
                columnTitle: 'TREND',
                sortByColumn: true,
                tooltipTitle:
                    'A visual display of the Search Volume Trend in Google over the past 12 months.',
            }),
            dataIndex: 'monthly_searches',
            width: '18%',
            render: (_, record) => {
                const trendData = record?.trend
                    ?.map((item) => {
                        return {
                            value: item?.search_volume,
                            name: `${monthsList[item?.month - 1]} ${
                                item?.year
                            }`,
                        };
                    })
                    ?.reverse();
                const index = record.relatedSearches.replaceAll(' ', '_');

                return (
                    trendData && (
                        <KeywordChart
                            key={`Trend_${index}`}
                            data={trendData}
                            hoverTitle={`Monthly searches between ${
                                trendData[0]?.name
                            } and ${trendData[trendData?.length - 1]?.name}`}
                        />
                    )
                );
            },
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                    onClick={handleSortClick('cpc_from')}
                >
                    <Tooltip
                        title={
                            'The average Cost Per Click (CPC) range for Google Ads. A higher CPC often suggests that a keyword has higher conversion rates and commercial intent.'
                        }
                        className={`${
                            keywordListSort?.field ===
                                keywordResearchResultsSortFields.cpc &&
                            classes.active
                        }`}
                        overlayClassName={`${classes.tooltipOverlay}
                                           ${
                                               darkMode
                                                   ? classes.tooltipOverlayDark
                                                   : ''
                                           }`}
                    >
                        <div className={classes.textDotted}>CPC</div>
                    </Tooltip>
                    <TableHeaderSortIcons
                        active={
                            keywordListSort?.field ===
                            keywordResearchResultsSortFields.cpc
                        }
                        order={keywordListSort?.order}
                    />
                </div>
            ),
            dataIndex: 'cpc_from',
            width: '12%',
            render: (_, record) => {
                return (
                    <Typography level={5} className='space-no-wrap'>
                        {record?.cpc?.low && record?.cpc?.high
                            ? `$${getNumWithZeroAfterDecimal(
                                  record?.cpc?.low
                              )} - $${getNumWithZeroAfterDecimal(
                                  record?.cpc?.high
                              )}`
                            : 'N/A'}
                    </Typography>
                );
            },
        },
        {
            title: (
                <div
                    className={`${classes.tableHeaderTitle} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                    onClick={handleSortClick('difficulty')}
                >
                    <Tooltip
                        title={
                            'Keyword Difficulty estimates how difficult it is to rank in the top 10 organic search results on a 100-point scale, where 1 is easy and 100 is extremely hard.'
                        }
                        className={`${
                            keywordListSort?.field === 'difficulty' &&
                            classes.active
                        }`}
                        overlayClassName={`${classes.tooltipOverlay}
                                           ${
                                               darkMode
                                                   ? classes.tooltipOverlayDark
                                                   : ''
                                           }`}
                    >
                        <div className={classes.textDotted}>DIFFICULTY</div>
                    </Tooltip>
                    <TableInfoModal
                        title={'Keyword SEO Difficulty'}
                        infoCategories={difficultyCategories}
                    />
                    <TableHeaderSortIcons
                        active={keywordListSort?.field === 'difficulty'}
                        order={keywordListSort?.order}
                    />
                </div>
            ),
            dataIndex: 'difficulty',
            width: '10%',
            fixed: 'right',
            render: (_, record) => {
                return (
                    <DifficultyBadge
                        marginRight='32px'
                        size={difficultyBadgeSize.small}
                        difficultyScore={record?.difficulty}
                    />
                );
            },
        },
    ];

    return columns;
};
