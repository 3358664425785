import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Form } from 'antd';

import { axiosAPI } from '../../../utils/axiosAPI';
import { getArrayOptionsFromObject } from '../../../utils/helpers/getArrayOptionsFromObject';
import { getAxiosHeaders } from '../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../utils/helpers/openNotification';
import { isUrlValid } from '../../../utils/helpers/urlHelpers';
import { useOpen } from '../../../utils/hooks/useOpen';

import {
    countries,
    defaultCountry,
    featuredCountries,
} from '../../../constants/countries';
import {
    defaultLanguage,
    featuredLanguages,
    languages,
} from '../../../constants/languages';
import { linkSearchParams } from '../../../constants/linkSearchParams';
import {
    errorNotificationMessage,
    notificationType,
} from '../../../constants/notificationType';
import { contentBriefPathes } from '../../../constants/queryPathes';
import { statusCodes } from '../../../constants/statusCodes';
import { subscriptionLimits } from '../../../constants/subscriptionLimits';

import { KEY_CODES } from '../../../constants/constants';
import { increaseSubscriptionLimits } from '../../pages/account/billing/store/billingSettings.actions';
import {
    selectOptimizersAdded,
    selectOptimizersLimit,
    selectOptimizersUsed,
} from '../../pages/account/billing/store/billingSettings.selectors';
import { setContentOptimizerForUpdate } from '../../pages/contentOptimizer/mainPage/store/contentOptimizerMain.actions';
import {
    selectIsImportURLActive,
    selectOptimizerForUpdate,
} from '../../pages/contentOptimizer/mainPage/store/contentOptimizerMain.selectors';

import KeywordSearchInput from '../inputs/KeywordSearchInput';
import KeywordSelect from '../selects/KeywordSelect';
import NoCreditsModal from './NoCreditsModal';
import RunOptimizerButton from './RunOptimizerButton';

import {
    selectUserGoogleSearchEnginePreference,
    selectUserLanguagePreference,
} from '../../../userBrowserSettings/store/browserSettings.selectors';

import './CreateNew.scss';

const classes = {
    keywordHeaderWrapper: 'keyword-header-wrapper brief-header d-flex w-100',
    keywordLocationSelect: 'keyword-location-select',
    keywordLanguageSelect: 'keyword-language-select',
    runBriefBtn: 'run-brief-btn',
    changeURLFormItemWrapper: 'change-url-form-item-wrapper',
    breakRow: 'flex-break-row',
};

const CreateSingleSection = ({
    getOptimizers,
    runBtnText,
    handleCreateSingle,
}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();

    const optimizersLimit = useSelector(selectOptimizersLimit);
    const optimizersUsed = useSelector(selectOptimizersUsed);
    const optimizersAdded = useSelector(selectOptimizersAdded);
    const isImportURLActive = useSelector(selectIsImportURLActive);
    const optimizerForUpdate = useSelector(selectOptimizerForUpdate);
    const userLanguagePreference =
        useSelector(selectUserLanguagePreference) || defaultLanguage;
    const userSearchEnginePreference =
        useSelector(selectUserGoogleSearchEnginePreference) || defaultCountry;

    const [isKeywordInputError, setIsKeywordInputError] = useState(false);
    const [isUrlInputError, setIsUrlInputError] = useState(false);

    const [keywordSearchData, setKeywordSearchData] = useState({
        keywords: '',
        country_code: userSearchEnginePreference,
        language_code: userLanguagePreference,
        url: '',
    });

    const [isLoading, setIsLoading] = useState(false);
    const { isOpen, handleOpen } = useOpen();

    useEffect(() => {
        if (keywordSearchData.keywords?.includes(',')) {
            form.setFields([
                {
                    name: 'keywords',
                    errors: [
                        'You can use only one keyword for this type of brief',
                    ],
                },
            ]);
        } else {
            form.setFields([
                {
                    name: 'keywords',
                    errors: [],
                },
            ]);
        }

        // eslint-disable-next-line
    }, [keywordSearchData.keywords]);

    useEffect(() => {
        if (searchParams.get(linkSearchParams.keyword.param)) {
            setKeywordSearchData((prev) => ({
                ...prev,
                keywords: searchParams
                    .get(linkSearchParams.keyword.param)
                    ?.replace('+', ' '),
            }));

            form.setFieldsValue({
                keywords: searchParams
                    .get(linkSearchParams.keyword.param)
                    ?.replace('+', ' '),
            });
        }

        // eslint-disable-next-line
    }, [searchParams]);

    useEffect(() => {
        if (optimizerForUpdate) {
            setKeywordSearchData((prev) => ({
                ...prev,
                keywords: optimizerForUpdate,
            }));

            form.setFieldsValue({
                keywords: optimizerForUpdate,
            });

            dispatch(setContentOptimizerForUpdate(null));
        }

        // eslint-disable-next-line
    }, []);

    const handleChangeKeyword = (event) => {
        const eventValue = event?.target?.value;

        setKeywordSearchData((prev) => ({
            ...prev,
            keywords: eventValue,
        }));
        setIsKeywordInputError(false);
    };

    const handleChangeURL = (event) => {
        const eventValue = event?.target?.value;

        setKeywordSearchData((prev) => ({
            ...prev,
            url: eventValue,
        }));

        form.setFields([
            {
                name: 'url',
                errors: [],
            },
        ]);
    };

    const handleChangeLocation = (value) => {
        setKeywordSearchData((prev) => ({
            ...prev,
            country_code: value,
        }));
    };

    const handleChangeLanguage = (value) => {
        setKeywordSearchData((prev) => ({
            ...prev,
            language_code: value,
        }));
    };

    const handleRunBrief = () => {
        const isURLValid = isUrlValid(keywordSearchData.url);

        if (
            optimizersUsed >= optimizersLimit + optimizersAdded ||
            !optimizersLimit
        ) {
            handleOpen();
        } else if (
            keywordSearchData.keywords &&
            (!isImportURLActive ? true : isURLValid)
        ) {
            setIsLoading(true);

            axiosAPI
                .post(
                    contentBriefPathes.createContentBrief,
                    {
                        ...keywordSearchData,
                        user_created_at_time: new Date().toISOString(),
                    },
                    {
                        ...getAxiosHeaders(),
                        params: {
                            get_optimizers: getOptimizers,
                        },
                    }
                )
                .then((result) => {
                    if (result?.status === statusCodes.create) {
                        handleCreateSingle(result?.data);
                        dispatch(
                            increaseSubscriptionLimits({
                                limit: subscriptionLimits.optimizersUsed,
                                value: 1,
                            })
                        );
                        setKeywordSearchData((prev) => ({
                            ...prev,
                            keywords: '',
                        }));
                        setKeywordSearchData((prev) => ({
                            ...prev,
                            url: '',
                        }));

                        form.setFieldValue('keywords', '');
                        form.setFieldValue('url', '');
                    }
                })
                .catch(() => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            if (!keywordSearchData.keywords) {
                setIsKeywordInputError(true);
            }

            if (!keywordSearchData.url) {
                setIsUrlInputError(true);
            } else if (!isURLValid) {
                form.setFields([
                    {
                        name: 'url',
                        errors: [
                            'Please enter a valid URL (e.g. https://www.google.com)',
                        ],
                    },
                ]);
            }
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === KEY_CODES.ENTER) {
            handleRunBrief();
        }
    };

    return (
        <>
            <Form form={form} onKeyDown={handleKeyDown}>
                <div className={classes.keywordHeaderWrapper}>
                    <Form.Item name='keywords'>
                        <KeywordSearchInput
                            placeholder='Enter keyword or phrase you want to rank for (e.g. best running shoes)...'
                            value={keywordSearchData.keywords}
                            onChange={handleChangeKeyword}
                            isInputError={isKeywordInputError}
                        />
                    </Form.Item>
                    <div className={classes.keywordLocationSelect}>
                        <KeywordSelect
                            placeholder='Select Location'
                            options={getArrayOptionsFromObject({
                                values: countries,
                                featured: featuredCountries,
                            })}
                            defaultValue={keywordSearchData.country_code}
                            onChange={handleChangeLocation}
                        />
                    </div>
                    <div className={classes.keywordLanguageSelect}>
                        <KeywordSelect
                            placeholder='Select Language'
                            options={getArrayOptionsFromObject({
                                values: languages,
                                featured: featuredLanguages,
                            })}
                            defaultValue={keywordSearchData.language_code}
                            onChange={handleChangeLanguage}
                        />
                    </div>
                    <Form.Item shouldUpdate>
                        {() => (
                            <RunOptimizerButton
                                isLoading={isLoading}
                                runBtnText={runBtnText}
                                handleRunBrief={handleRunBrief}
                            />
                        )}
                    </Form.Item>

                    {isImportURLActive && (
                        <>
                            <div className={classes.breakRow} />
                            <Form.Item
                                name='url'
                                className={classes.changeURLFormItemWrapper}
                            >
                                <KeywordSearchInput
                                    isInputError={isUrlInputError}
                                    placeholder='Enter URL to import existing content (e.g. https://www.domain.com/best-running-shoes)...'
                                    value={keywordSearchData.url}
                                    onChange={handleChangeURL}
                                />
                            </Form.Item>
                        </>
                    )}
                </div>
            </Form>
            <NoCreditsModal isOpen={isOpen} handleOpen={handleOpen} />
        </>
    );
};

export default CreateSingleSection;
