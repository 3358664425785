import { Extension, getAttributes } from '@tiptap/core';
import Document from '@tiptap/extension-document';
import Link from '@tiptap/extension-link';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import ImageResize from 'tiptap-extension-resize-image';

import { getWordBoundaryRegex } from '../../../../utils/helpers/regexHelpers';

import { alignments } from '../constants/alignments';

export const highlighterPluginKey = new PluginKey('highlighterPlugin');

export const CustomLinkExtension = Link.extend({
    addOptions() {
        return {
            ...this.parent?.(),
            openOnClick: false,
        };
    },
    addProseMirrorPlugins() {
        const plugins = this.parent?.() || [];

        const ctrlClickHandler = new Plugin({
            key: new PluginKey('handleControlClick'),
            props: {
                handleClick(view, pos, event) {
                    const attrs = getAttributes(view.state, 'link');
                    const link = event.target?.closest('a');

                    const keyPressed = event.ctrlKey || event.metaKey;

                    if (keyPressed && link && attrs.href) {
                        window.open(attrs.href, attrs.target);

                        return true;
                    }

                    return false;
                },
            },
        });

        plugins.push(ctrlClickHandler);

        return plugins;
    },
});

const getHighlightedKeywords = (
    doc,
    editor,
    className = 'highlight-keyword'
) => {
    const { mappedKeywords, activeKeyword, isTurnedOn } =
        editor.storage.colorHighlighter;

    const decorations = [];

    if (!isTurnedOn || !mappedKeywords?.length) {
        return DecorationSet.empty;
    }

    const mergedTextNodes = [];
    let textNodesIndex = 0;

    doc.descendants((node, position) => {
        if (node.isText) {
            if (mergedTextNodes[textNodesIndex]) {
                mergedTextNodes[textNodesIndex] = {
                    text: mergedTextNodes[textNodesIndex].text + node.text,
                    position: mergedTextNodes[textNodesIndex].position,
                };
            } else {
                mergedTextNodes[textNodesIndex] = {
                    text: node.text,
                    position,
                };
            }
        } else {
            textNodesIndex += 1;
        }
    });

    mergedTextNodes.forEach(({ text, position }) => {
        mappedKeywords.forEach((highlightKeyword) => {
            const escapedKeywordText =
                highlightKeyword.keyword.toLocaleLowerCase();
            // The keyword is selected by user in an Optimizer Keywords list with Details window opened
            const isActive =
                activeKeyword &&
                (escapedKeywordText ===
                    activeKeyword.keyword.toLocaleLowerCase() ||
                    highlightKeyword.regex === activeKeyword.regex);
            const matchedKeywordKey =
                highlightKeyword.regex ?? escapedKeywordText;

            let matchedKeywordsArray = Array.from(
                text.toLocaleLowerCase().matchAll(matchedKeywordKey)
            );

            // This section for handling unexpected cases
            if (!matchedKeywordsArray?.length > 0) {
                const modifiedRegex = getWordBoundaryRegex(escapedKeywordText);

                matchedKeywordsArray = Array.from(
                    text.toLocaleLowerCase().matchAll(modifiedRegex)
                );
            }

            matchedKeywordsArray.forEach((match) => {
                const word = match[0];
                const index = match.index || 0;
                const from = position + index;
                const to = from + word.length;
                const decoration = Decoration.inline(from, to, {
                    class: isActive
                        ? `${className} highlight-selected-keyword`
                        : className,
                });

                decorations.push(decoration);
            });
        });
    });

    return DecorationSet.create(doc, decorations);
};

export const HighlightKeywordsExtension = Extension.create({
    name: 'colorHighlighter',

    addOptions() {
        return {
            className: 'highlight-keyword',
        };
    },

    addStorage() {
        return {
            mappedKeywords: [],
            activeKeyword: null,
            isTurnedOn: true,
        };
    },

    addCommands() {
        const { className } = this.options;

        return {
            setKeywordList:
                (keywords = []) =>
                ({ editor }) => {
                    editor.storage.colorHighlighter.mappedKeywords = keywords;

                    return false;
                },
            setActiveKeyword:
                (keyword = null) =>
                ({ editor }) => {
                    if (
                        editor.storage.colorHighlighter.activeKeyword !==
                        keyword
                    ) {
                        editor.storage.colorHighlighter.activeKeyword = keyword;
                    }

                    return false;
                },
            applyHighlighting:
                (doc) =>
                ({ editor }) => {
                    getHighlightedKeywords(doc, editor, className);

                    return false;
                },
            setHighlightingOn:
                (isOn = true) =>
                ({ editor }) => {
                    if (
                        editor.storage.colorHighlighter.isTurnedOn !==
                        Boolean(isOn)
                    ) {
                        editor.storage.colorHighlighter.isTurnedOn =
                            Boolean(isOn);
                    }

                    return false;
                },
        };
    },

    addProseMirrorPlugins() {
        const editor = this.editor;
        const { className } = this.options;

        return [
            new Plugin({
                key: highlighterPluginKey,
                state: {
                    init() {
                        return;
                    },
                    apply({ doc, docChanged, updated }, oldState) {
                        return docChanged || updated === 0
                            ? getHighlightedKeywords(doc, editor, className)
                            : oldState;
                    },
                },
                props: {
                    decorations(state) {
                        return this.getState(state);
                    },
                },
            }),
        ];
    },
});

export const CustomImageResize = ImageResize.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            style: {
                default:
                    'width: 100%; height: auto; cursor: pointer; max-width: 100%;',
                parseHTML: (element) => {
                    return element.style.cssText;
                },
            },
            class: {
                default: 'custom-image',
                parseHTML: () => 'custom-image',
                renderHTML: () => {
                    return {
                        class: 'custom-image',
                    };
                },
            },
        };
    },
    addCommands() {
        return {
            alignImage:
                (alignment) =>
                ({ commands, state }) => {
                    const { selection } = state;
                    const { $from, $to } = selection;

                    state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
                        if (node.type.name === 'image') {
                            let updatedStyle = node.attrs.style || '';
                            // Remove previous margin if exists
                            updatedStyle = updatedStyle.replace(
                                /margin:\s*[^;]+;?/g,
                                ''
                            );
                            // Add new margin according to the alignment
                            updatedStyle += ` margin: ${
                                alignments?.[alignment]?.margin || '0'
                            };`;
                            commands.updateAttributes(node.type.name, {
                                ...node.attrs,
                                style: updatedStyle,
                            });
                        }
                    });
                },
        };
    },
    addProseMirrorPlugins() {
        const plugins = this.parent?.() || [];

        const pastedImagesDefaultStyleHandler = new Plugin({
            props: {
                transformPastedHTML(html) {
                    const doc = new DOMParser().parseFromString(
                        html,
                        'text/html'
                    );
                    const images = doc.querySelectorAll('img');

                    images.forEach((image) => {
                        const originalWidth = image.style?.width || image.width;
                        let width = '100%';

                        if (originalWidth) {
                            const originalWidthStr = originalWidth.toString();

                            if (originalWidthStr.includes('%')) {
                                width = originalWidth;
                            } else {
                                const parsedWidth = parseInt(
                                    originalWidthStr.replace('px', ''),
                                    10
                                );
                                if (!isNaN(parsedWidth) && parsedWidth > 0) {
                                    width = `${parsedWidth}px`;
                                }
                            }
                        }

                        const margin =
                            Object.values(alignments).find(
                                (alignment) =>
                                    alignment.margin === image.style?.margin
                            )?.margin || '0';

                        image.setAttribute(
                            'style',
                            `width: ${width}; margin: ${margin}; height: auto; cursor: pointer; max-width: 100%;`
                        );
                    });

                    return doc.body.innerHTML;
                },
            },
        });

        plugins.push(pastedImagesDefaultStyleHandler);

        return plugins;
    },
});

/**
 * Custom document extension for the editor.
 *
 * This extends the base Document schema with custom content.
 * The 'block+' value indicates that the document can contain one or more block-level elements.
 * Needed for the editor drag handle to work correctly.
 * @see https://tiptap.dev/api/nodes/document
 */
export const CustomDocument = Document.extend({
    content: 'block+',
});
