import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { EventType } from '@upollo/web';
import { Button, Checkbox, Col, Form, Input, Row, Typography } from 'antd';
import Link from 'antd/es/typography/Link';

import {
    formEmailRules,
    formPasswordRules,
} from '../../../../../constants/formItemRules';
import { linkSearchParams } from '../../../../../constants/linkSearchParams';
import { notificationType } from '../../../../../constants/notificationType';
import { authPathes } from '../../../../../constants/queryPathes';
import { companyNamePattern } from '../../../../../constants/regularExpression';
import { unauthorizedRoutes } from '../../../../common/router/Unauthorized/routes';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../utils/helpers/openNotification';
import { setUserRegistrationEmail } from '../../store/authSettings.actions';

import { UppoloContext } from '../../../../hocs/UppoloProvider';

import '../../Auth.scss';

const classes = {
    root: 'signup-wrapper',
    mainTypography: 'main-typography',
    backToSingIn: 'd-flex flex-center back-to-signin',
    darkThemeTypography: 'dark-theme-typography',
    checkboxItem: 'checkbox-item',
};

const SignUp = () => {
    const upollo = useContext(UppoloContext);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [form] = Form.useForm();
    const darkMode = useSelector(selectDarkMode);
    const [isRegisterLoading, setIsRegisterLoading] = useState(false);
    const [hideCompanyName] = useState(
        searchParams?.get(linkSearchParams.source.param) ===
            linkSearchParams.source.values.invite
    );

    const email = searchParams?.get(linkSearchParams.email.param);

    const sendUppoloTrackEvent = (userEmail) => {
        upollo.track({ userEmail }, EventType.EVENT_TYPE_REGISTER);
    };

    const handleSignIn = () => {
        navigate(unauthorizedRoutes.login);
    };

    useEffect(() => {
        if (email) {
            form.setFieldValue('email', email);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);

    const handleSubmitUserData = (values) => {
        const userData = form.getFieldsValue();

        if (values?.privacyPolicy) {
            setIsRegisterLoading(true);

            if (upollo) {
                sendUppoloTrackEvent(userData.email);
            }

            axiosAPI
                .post(
                    authPathes.users,
                    {
                        email: userData.email,
                        password: userData.password,
                        first_name: userData.firstName,
                        last_name: userData.lastName,
                        company_name: userData.organization,
                    },
                    {
                        ...getAxiosHeaders(),
                    }
                )
                .then(() => {
                    dispatch(setUserRegistrationEmail(userData.email));
                    navigate(unauthorizedRoutes.confirmInstructions);
                })
                .catch((err) => {
                    // The next part of the code takes the error message from the server validation response if it is an existing
                    const errorsObj = err.response?.data?.errors;

                    if (errorsObj) {
                        const errorKeys = Object.keys(errorsObj);

                        if (errorKeys.length) {
                            const errorTextArray = errorsObj[errorKeys[0]];

                            if (errorTextArray && errorTextArray.length) {
                                openNotification(
                                    notificationType.error,
                                    'Error',
                                    errorTextArray[0]
                                );
                            }
                        }
                    } else {
                        openNotification(
                            notificationType.error,
                            'Please try again later'
                        );
                    }
                })
                .finally(() => {
                    setIsRegisterLoading(false);
                });
        } else {
            form.setFields([
                {
                    name: 'privacyPolicy',
                    errors: [
                        'Please accept Terms of Service and Privacy Policy',
                    ],
                },
            ]);
        }
    };

    const commonFieldErrorText = 'This field is required';

    return (
        <Form
            name='singUpForm'
            form={form}
            autoComplete='off'
            onFinish={handleSubmitUserData}
            className={classes.root}
        >
            <Typography
                className={`${classes.mainTypography} ${
                    darkMode ? classes.darkThemeTypography : ''
                }`}
            >
                Create Your Account
            </Typography>

            <Row>
                <Col span={11}>
                    <Typography>First Name</Typography>
                    <Form.Item
                        name='firstName'
                        rules={[
                            {
                                required: true,
                                message: commonFieldErrorText,
                            },
                        ]}
                    >
                        <Input placeholder='Enter first name' />
                    </Form.Item>
                </Col>
                <Col span={11} offset={2}>
                    <Typography>Last Name</Typography>
                    <Form.Item
                        name='lastName'
                        rules={[
                            {
                                required: true,
                                message: commonFieldErrorText,
                            },
                        ]}
                    >
                        <Input placeholder='Enter last name' />
                    </Form.Item>
                </Col>
            </Row>

            {!hideCompanyName && (
                <>
                    <Typography>Company Name</Typography>
                    <Form.Item
                        name='organization'
                        rules={[
                            {
                                required: true,
                                message: commonFieldErrorText,
                            },
                            {
                                min: 2,
                                message:
                                    'Company name must be between 2 and 64 characters long',
                            },
                            {
                                max: 64,
                                message:
                                    'Company name must be between 2 and 64 characters long',
                            },
                            {
                                pattern: companyNamePattern,
                                message:
                                    'Please use only letters, digits, spaces, and dashes, and ensure it starts and ends with a letter or digit',
                            },
                        ]}
                    >
                        <Input placeholder='Enter company name' />
                    </Form.Item>
                </>
            )}

            <Typography>Email</Typography>
            <Form.Item name='email' rules={formEmailRules}>
                <Input placeholder='Enter your email' disabled={email} />
            </Form.Item>

            <Typography>Password</Typography>
            <Form.Item name='password' rules={formPasswordRules}>
                <Input.Password placeholder='Enter your password' />
            </Form.Item>

            <Form.Item
                name='privacyPolicy'
                valuePropName='checked'
                className={classes.checkboxItem}
                validateTrigger='onFinish'
                rules={[
                    {
                        validator: (_, value) =>
                            value
                                ? Promise.resolve()
                                : Promise.reject(
                                      new Error(commonFieldErrorText)
                                  ),
                    },
                ]}
            >
                <Checkbox defaultChecked={false}>
                    I agree to the{' '}
                    <a
                        href={'https://www.rankability.com/terms/'}
                        target='_blank'
                        rel='noreferrer'
                    >
                        Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                        href={'https://www.rankability.com/privacy/'}
                        target='_blank'
                        rel='noreferrer'
                    >
                        Privacy Policy
                    </a>
                </Checkbox>
            </Form.Item>

            <Form.Item>
                <Button
                    type='primary'
                    htmlType='submit'
                    loading={isRegisterLoading}
                >
                    Register
                </Button>
            </Form.Item>

            <div className={classes.backToSingIn}>
                <Typography>Already have an account?&nbsp;</Typography>
                <Link onClick={handleSignIn}>Sign in</Link>
            </div>
        </Form>
    );
};

export default SignUp;
