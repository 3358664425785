import React from 'react';
import { useSelector } from 'react-redux';

import { Layout, theme } from 'antd';

import { selectDarkMode } from '../../../userBrowserSettings/store/browserSettings.selectors';

import CustomBreadcrumb from './content/Breadcrumb';
import DateRangeFilterDropdown from './content/DateRangeFilterDropdown';
import HeaderSort from './content/HeaderSort';
import InfoSetting from './content/InfoSetting';
import Projectsetting from './content/ProjectSetting';
import QuickNavigationItem from './content/QuickNavigationItem';
import SearchInput from '../inputs/SearchInput';
import ShareSetting from './content/ShareSetting';
import UseSetting from './content/UseSetting';
import ViewModeSwitch from './content/ViewModeSwitch';
import './Header.scss';

const { Header } = Layout;

const classes = {
  headerWrapper: 'd-flex align-items-center justify-content-between header',
  rightHeaderBlock: 'd-flex align-items-center',
};

const CustomHeader = ({
  showSearch,
  handleSearch,
  defaultSearchValue,
  searchValue,
  showSort,
  sortItems,
  sortDropdownPrefix,
  selectedSort,
  handleSort,
  quickNavTitle,
  quickNavIcon,
  quickNavLink,
  showViewMode,
  showInfoSetting,
  showQuickNavigation,
  showUseSetting,
  showShareSetting,
  showProjectSetting,
  extraBreadcrumbs = [],
  additionalBreadcrumbFunction,

  showDateRangeFilterDropdown,
  dateRangePickerArray,
  handleApplyDateRangeValue,
}) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const darkMode = useSelector(selectDarkMode);

  return (
    <Header
      style={{
        background: colorBgContainer,
        minWidth: '768px',
        left: 0,
      }}
      className={`${classes.headerWrapper} ${darkMode ? 'header-dark' : ''}`}
    >
      <CustomBreadcrumb
        extraBreadcrumbs={extraBreadcrumbs}
        additionalBreadcrumbFunction={additionalBreadcrumbFunction}
      />
      <div className={classes.rightHeaderBlock}>
        {showSearch && (
          <SearchInput
            handleSearch={handleSearch}
            defaultSearchValue={defaultSearchValue}
            searchValue={searchValue}
          />
        )}
        {showSort && (
          <HeaderSort
            sortItems={sortItems}
            selectedSort={selectedSort}
            handleSort={handleSort}
            sortDropdownPrefix={sortDropdownPrefix}
          />
        )}
        {showDateRangeFilterDropdown && (
          <DateRangeFilterDropdown
            dateRangePickerArray={dateRangePickerArray}
            handleApplyDateRangeValue={handleApplyDateRangeValue}
          />
        )}
        {showViewMode && <ViewModeSwitch />}
        {showInfoSetting && <InfoSetting />}
        {showQuickNavigation && (
          <QuickNavigationItem
            title={quickNavTitle}
            icon={quickNavIcon}
            link={quickNavLink}
          />
        )}
        {showUseSetting && <UseSetting />}
        {showShareSetting && <ShareSetting />}
        {showProjectSetting && <Projectsetting />}
      </div>
    </Header>
  );
};

export default CustomHeader;
