import React, { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button, Switch, Typography } from 'antd';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../../constants/notificationType';
import { contentOptimizerPathes } from '../../../../../../../constants/queryPathes';

import MoreHorizontalIcon from '../../../../../../../resources/icons/MoreHorizontalIcon';
import ResetToDefaultIcon from '../../../../../../../resources/icons/ResetToDefaultIcon';
import { colorPalette } from '../../../../../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { updateCompetitor } from '../../../store/contentOptimizerContent.actions';

import { axiosAPI } from '../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../../../utils/helpers/openNotification';
import useGetListOfContentBriefs from '../../../../../../../utils/hooks/useGetListOfContentBriefs';
import { useOpen } from '../../../../../../../utils/hooks/useOpen';

import { updateEditorOptimalsBasicData } from '../../../../../../common/tiptapEditor/store/tiptapEditor.actions';

import CustomDropdown from '../../../../../../common/dropdown/CustomDropdown';

const { Title } = Typography;

const classes = {
    listMoreButton:
        'list-more-button d-flex flex-center align-items-center btn-hover-light-primary',
    toggleContainer: 'd-flex align-items-center',
    competitors3Dots: 'competitors-3-dots',
    dropdownOverlay: 'three-dots-dropdown-overlay',
    resetToDefault: 'reset-to-default',
};

const ThreeDotsCompetitorActionsDropdown = ({
    isSwitchersVisible,
    handleSwitchersVisibility,
}) => {
    const dispatch = useDispatch();

    const { contentOptimizerId } = useParams();
    const { isOpen, handleOpen } = useOpen();

    const getListOfContentBriefs = useGetListOfContentBriefs();
    const darkMode = useSelector(selectDarkMode);

    const [isLoading, setIsLoading] = useState(false);

    const handleResetToDefault = () => {
        setIsLoading(true);

        axiosAPI
            .patch(
                `${contentOptimizerPathes.resetCompetitorsToDefault}/${contentOptimizerId}`,
                {},
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                batch(() => {
                    dispatch(
                        updateCompetitor(
                            result?.data?.task_result?.map((x) => ({
                                onpageTaskId: x?.onpage_task_id,
                                useInOptimalRange: x?.include_in_optimal_range,
                            }))
                        )
                    );
                    dispatch(
                        updateEditorOptimalsBasicData(result?.data?.optimals)
                    );
                });

                getListOfContentBriefs(contentOptimizerId);

                openNotification(
                    notificationType.success,
                    'Success',
                    'Competitors list was reset to default state'
                );
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <CustomDropdown
            withExtraContent
            withArrow
            dropdownOpenElement={
                <Button
                    className={`${classes.listMoreButton} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                >
                    <MoreHorizontalIcon color={colorPalette.basicGray} />
                </Button>
            }
            dropdownContent={
                <div className={classes.competitors3Dots}>
                    <div
                        className={classes.toggleContainer}
                        onClick={() => handleSwitchersVisibility()}
                    >
                        <Switch checked={isSwitchersVisible} size='small' />
                        <Title level={5}>Customize URLs</Title>
                    </div>
                    <div
                        className={classes.toggleContainer}
                        onClick={() =>
                            !isLoading ? handleResetToDefault() : null
                        }
                    >
                        <ResetToDefaultIcon color={colorPalette.colorPrimary} />
                        <Title level={5} className={classes.resetToDefault}>
                            Reset To Default
                        </Title>
                    </div>
                </div>
            }
            dropdownOverlayClassName={classes.dropdownOverlay}
            dropdownTriggersArray={['click']}
            isDropdownOpen={isOpen}
            handleOpenChange={handleOpen}
        />
    );
};

export default ThreeDotsCompetitorActionsDropdown;
