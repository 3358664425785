import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button, Dropdown, Popconfirm, Switch, Tooltip } from 'antd';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../../../../constants/notificationType';
import { contentOptimizerPathes } from '../../../../../../../constants/queryPathes';

import CloseCircleIcon from '../../../../../../../resources/icons/CloseCircleIcon';
import MoreHorizontalIcon from '../../../../../../../resources/icons/MoreHorizontalIcon';
import RefreshIcon from '../../../../../../../resources/icons/RefreshIcon';
import { colorPalette } from '../../../../../../../resources/styles/colorPalette';

import {
    selectDarkMode,
    selectUserOrganizationSettings,
} from '../../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { selectContentOptimizerViewOnlyMode } from '../../../../viewOnly/store/viewOnlySettings.selectors';
import {
    removeCompetitor,
    updateCompetitor,
} from '../../../store/contentOptimizerContent.actions';

import { axiosAPI } from '../../../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../../../utils/helpers/getAxiosHeaders';
import { getRandomId } from '../../../../../../../utils/helpers/idGenerator';
import { openNotification } from '../../../../../../../utils/helpers/openNotification';
import { useOpen } from '../../../../../../../utils/hooks/useOpen';

import RankabilityLoader from '../../../../../../common/rankabilityLoader/RankabilityLoader';

import { selectCurrentCompetitorsList } from '../../../store/contentOptimizerContent.selectors';

const classes = {
    moreDropdownOverlay:
        'dropdown-with-arrow-overlay basic-more-dropdown-overlay dropdown-menu dropdown-overlay-with-extra-content content-actions-dropdown',
    moreDropdownOverlayDark:
        'dropdown-with-arrow-overlay-dark basic-more-dropdown-overlay-dark dropdown-overlay-with-extra-content-dark',
    listMoreButton:
        'list-more-button d-flex flex-center align-items-center btn-hover-light-primary',

    deletePopconfirm: 'delete-popconfirm',
    deletePopconfirmDark: 'delete-popconfirm-dark dark-mode',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    toggleContainer: 'toggle-container',
};

const MIN_NUMBER_OF_ON_COMPETITORS = 3;
const MIN_COMPETITOR_WORD_COUNT = 0;

const CompetitorActionsDropdown = ({
    record,
    isSwitchersVisible,
    competitorsToUpdate,
    setCompetitorsToUpdate,
}) => {
    const dispatch = useDispatch();

    const { contentOptimizerId } = useParams();

    const competitorsList = useSelector(selectCurrentCompetitorsList);
    const darkMode = useSelector(selectDarkMode);
    const viewOnlyMode = useSelector(selectContentOptimizerViewOnlyMode);
    const organizationSettings = useSelector(selectUserOrganizationSettings);

    const [isLoading, setIsLoading] = useState(false);

    if (
        isLoading &&
        !competitorsToUpdate
            ?.map((x) => x.onpageTaskId)
            ?.includes(record?.onpageTaskId)
    ) {
        setIsLoading(false);
    }

    const {
        isOpen: isHighlightDropdownOpen,
        handleOpen: handleHighlightDropdownOpen,
    } = useOpen();

    const iconColor = darkMode
        ? colorPalette.textAccentColor.dark
        : colorPalette.textAccentColor.light;

    const handleRefreshCompetitor = () => {
        setIsLoading(true);

        axiosAPI
            .patch(
                `${contentOptimizerPathes.refreshUrl}?url=${record?.url}&onpage_task_id=${record?.onpageTaskId}&optimizer_id=${contentOptimizerId}`,
                {},
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                dispatch(
                    updateCompetitor({
                        onpageTaskId: result?.data?.onpage_task_id,
                        favicon: result?.data?.favicon,
                        wordCount: result?.data?.word_count || null,
                        rankabilityScore:
                            Math.ceil(result?.data?.score) || null,
                        domainRank:
                            Math.ceil(result?.data?.domain_rank) || null,
                    })
                );
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleDeleteCompetitor = () => {
        axiosAPI
            .delete(
                `${contentOptimizerPathes.deleteUrl}?onpage_task_id=${record?.onpageTaskId}&optimizer_id=${contentOptimizerId}`,
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                dispatch(
                    removeCompetitor({
                        id: result?.data?.onpage_task_id,
                        competitor_positions_analyzed:
                            organizationSettings?.competitor_positions_analyzed,
                    })
                );
            })
            .catch(() => {
                openNotification(
                    notificationType.error,
                    'Error',
                    errorNotificationMessage
                );
            });
    };

    const numberOfIncludedInCalcCompetitors = competitorsList?.filter(
        (x) => x?.useInOptimalRange
    )?.length;

    const useInOptimalRange = record?.useInOptimalRange;
    const competitorsThatAreGoingToBeOff = competitorsToUpdate.map(
        (x) => !x.useInOptimalRange
    ).length;

    const isMinNumberOfCompetitorsReached =
        numberOfIncludedInCalcCompetitors - competitorsThatAreGoingToBeOff <=
        MIN_NUMBER_OF_ON_COMPETITORS;

    const handleUseInOptimalRangeChange = () => {
        setIsLoading(true);
        const newValue = !useInOptimalRange;

        if (
            !competitorsToUpdate.some(
                (x) => x.onpageTaskId == record?.onpageTaskId
            )
        ) {
            setCompetitorsToUpdate([
                ...competitorsToUpdate,
                {
                    onpageTaskId: record?.onpageTaskId,
                    useInOptimalRange: newValue,
                },
            ]);
        }
    };

    const competitorActionsItems = [
        {
            key: 'refresh-competitor',
            label: (
                <Popconfirm
                    placement='topRight'
                    okText='Yes'
                    cancelText='Cancel'
                    title='Refresh this URL with updated information?'
                    overlayClassName={`${classes.deletePopconfirm}
                                   ${
                                       darkMode
                                           ? classes.deletePopconfirmDark
                                           : ''
                                   }`}
                    onConfirm={() => {
                        handleRefreshCompetitor();
                        handleHighlightDropdownOpen();
                    }}
                    onCancel={() => {
                        handleHighlightDropdownOpen();
                    }}
                >
                    <div className='d-flex align-items-center'>
                        <RefreshIcon color={iconColor} />
                        <span>Refresh</span>
                    </div>
                </Popconfirm>
            ),
            onClick: (_event) => {},
        },
        {
            key: 'delete-competitor',
            label: (
                <Popconfirm
                    placement='topRight'
                    okText='Yes'
                    cancelText='Cancel'
                    title='Remove this URL from being featured at the top?'
                    overlayClassName={`${classes.deletePopconfirm}
                                   ${
                                       darkMode
                                           ? classes.deletePopconfirmDark
                                           : ''
                                   }`}
                    onConfirm={() => {
                        handleDeleteCompetitor();
                        handleHighlightDropdownOpen();
                    }}
                    onCancel={() => {
                        handleHighlightDropdownOpen();
                    }}
                >
                    <div className='d-flex align-items-center competitor-actions-dropdown-close'>
                        <CloseCircleIcon color={iconColor} />
                        <span>Remove</span>
                    </div>
                </Popconfirm>
            ),
            onClick: (_event) => {},
        },
    ];

    if (record?.highlighted) {
        return !isLoading ? (
            <Dropdown
                id={getRandomId()}
                menu={{
                    items: !viewOnlyMode
                        ? competitorActionsItems
                        : competitorActionsItems?.slice(0, 1),
                }}
                arrow
                placement='bottomRight'
                trigger={['click']}
                overlayClassName={`${classes.moreDropdownOverlay}
                                           ${
                                               darkMode &&
                                               classes.moreDropdownOverlayDark
                                           }`}
                open={isHighlightDropdownOpen}
                onOpenChange={handleHighlightDropdownOpen}
            >
                <Button
                    className={`${classes.listMoreButton} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                >
                    <MoreHorizontalIcon color={colorPalette.basicGray} />
                </Button>
            </Dropdown>
        ) : (
            <RankabilityLoader />
        );
    }

    if (!isSwitchersVisible) {
        return;
    }

    const isDisabled =
        record?.desktop == null ||
        !record?.wordCount ||
        (useInOptimalRange && isMinNumberOfCompetitorsReached) ||
        !Number.isInteger(record?.rankabilityScore) ||
        (Number.isInteger(record?.wordCount) &&
            record?.wordCount <= MIN_COMPETITOR_WORD_COUNT);

    const tooltipTitle =
        isMinNumberOfCompetitorsReached && isDisabled
            ? `At least ${MIN_NUMBER_OF_ON_COMPETITORS} URLs must be selected`
            : `${
                  !useInOptimalRange ? 'Include' : 'Exclude'
              } this URL in Optimal ranges`;

    return (
        <Tooltip
            title={tooltipTitle}
            overlayClassName={`${classes.tooltipOverlay}
                                   ${
                                       darkMode
                                           ? classes.tooltipOverlayDark
                                           : ''
                                   }`}
            placement='top'
        >
            <div className={classes.toggleContainer}>
                <Switch
                    checked={useInOptimalRange}
                    onChange={handleUseInOptimalRangeChange}
                    disabled={isDisabled}
                    size='small'
                    loading={isLoading}
                />
            </div>
        </Tooltip>
    );
};

export default CompetitorActionsDropdown;
