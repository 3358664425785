import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Col, Row, Typography } from 'antd';

import { selectDarkMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';

import UsageChartLabel from './UsageChartLabel';
import UsageChart from './UsageChart';

const classes = {
    chartWrapper: 'chart-wrapper',
    xAxisLabel: 'x-axis-label',
};

const UsageChartsSection = ({ contentBriefs = [], keywordResearches = [] }) => {
    const chartWrapper = useRef(null);

    const darkMode = useSelector(selectDarkMode);

    const [chartWidth, setChartWidth] = useState(0);

    const changeChartWidth = () => {
        if (chartWrapper?.current) {
            setChartWidth(chartWrapper?.current?.offsetWidth - 32);
        }
    };

    useEffect(() => {
        changeChartWidth();

        window.addEventListener('resize', changeChartWidth);

        return () => {
            window.removeEventListener('resize', changeChartWidth);
        };

        // eslint-disable-next-line
    }, [chartWrapper]);

    return (
        <Row justify='space-between' gutter={24}>
            <Col span={12} ref={chartWrapper}>
                <div
                    className={`${classes.chartWrapper} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                >
                    <UsageChartLabel mainLabel={'Reports Created'} />
                    <UsageChart
                        data={contentBriefs}
                        valueDescLabel={'Reports created'}
                        strokeColor={'#21CC90'}
                        width={chartWidth}
                    />
                    <div className={classes.xAxisLabel}>
                        <Typography>{contentBriefs[0]?.name}</Typography>
                        <Typography>
                            {contentBriefs[contentBriefs?.length - 1]?.name}
                        </Typography>
                    </div>
                </div>
            </Col>
            <Col span={12}>
                <div
                    className={`${classes.chartWrapper} ${
                        darkMode ? 'dark-mode' : ''
                    }`}
                >
                    <UsageChartLabel
                        mainLabel={'Keyword Discoveries Created'}
                    />

                    <UsageChart
                        data={keywordResearches}
                        valueDescLabel={'Keyword Discoveries created'}
                        strokeColor={'#DEB87E'}
                        width={chartWidth}
                    />
                    <div className={classes.xAxisLabel}>
                        <Typography>{keywordResearches[0]?.name}</Typography>
                        <Typography>
                            {
                                keywordResearches[keywordResearches?.length - 1]
                                    ?.name
                            }
                        </Typography>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default UsageChartsSection;
