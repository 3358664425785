import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Form, Input, Tooltip } from 'antd';

import CloseIcon from '../../../../../../../resources/icons/CloseIcon';
import SearchIcon from '../../../../../../../resources/icons/SearchIcon';

import { selectDarkMode } from '../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { urlPattern } from '../../../../../../../constants/regularExpression';
import { useIconColor } from '../../../../../../../utils/hooks/useIconColor';

import { updateCompetitorsSearch } from '../../../store/contentOptimizerContent.actions';
import {
    selectCurrentCompetitorsList,
    selectIsCompetitorScanUrlLoading,
} from '../../../store/contentOptimizerContent.selectors';

const classes = {
    competitorsSearchInput: 'competitors-search-input',
    searchInput: 'search-input',
    scanButton: 'scan-button',
    inputError: 'input-error',

    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
};

const CompetitorsSearchInput = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const iconColor = useIconColor();

    const darkMode = useSelector(selectDarkMode);
    const isCompetitorScanUrlLoading = useSelector(
        selectIsCompetitorScanUrlLoading
    );
    const competitorsList = useSelector(selectCurrentCompetitorsList);

    const [currentValue, setCurrentValue] = useState(null);
    const [isInputError, setIsInputError] = useState(false);
    const [isDisabledScan, setIsDisabledScan] = useState(false);

    useEffect(() => {
        if (!currentValue) {
            dispatch(updateCompetitorsSearch(''));
        }

        // eslint-disable-next-line
    }, [currentValue]);

    useEffect(() => {
        setIsDisabledScan(
            competitorsList?.filter((competitor) => competitor?.highlighted)
                ?.length >= 5
        );
    }, [competitorsList]);

    const resetErrors = () => {
        form.setFields([
            {
                name: 'search',
                errors: [],
            },
        ]);
    };

    const handleSearchInputValue = (event) => {
        setCurrentValue(event?.target?.value);
        setIsInputError(false);
    };

    const handleScan = () => {
        if (currentValue) {
            if (urlPattern.test(currentValue)) {
                dispatch(updateCompetitorsSearch(currentValue));
                setCurrentValue('');
                form.setFieldValue('search', '');
            } else {
                form.setFields([
                    {
                        name: 'search',
                        errors: [
                            'Please enter a valid URL (e.g. https://www.google.com)',
                        ],
                    },
                ]);
            }
        } else {
            setIsInputError(true);
        }
    };

    return (
        <Form
            form={form}
            className={`${classes.competitorsSearchInput}`}
            onFinish={handleScan}
            onValuesChange={resetErrors}
        >
            <Form.Item name='search'>
                <Input
                    placeholder={
                        'Enter URL to compare with competitors below (e.g. https://www.domain.com/page)…'
                    }
                    prefix={<SearchIcon color={iconColor} />}
                    disabled={isCompetitorScanUrlLoading}
                    className={`${classes.searchInput} ${
                        darkMode ? 'dark-mode dark-input-autofill' : ''
                    } ${isInputError ? classes.inputError : ''}`}
                    value={currentValue}
                    onChange={handleSearchInputValue}
                    allowClear={{ clearIcon: <CloseIcon color={iconColor} /> }}
                />
            </Form.Item>
            <Tooltip
                title={
                    isDisabledScan
                        ? "Sorry you can not scan more than 5 URL's. Please remove highlighted competitor and try again."
                        : ''
                }
                overlayClassName={`${classes.tooltipOverlay}
                                 ${darkMode ? classes.tooltipOverlayDark : ''}`}
            >
                <Button
                    type='primary'
                    htmlType='submit'
                    className={`${classes.scanButton}`}
                    disabled={isDisabledScan || isCompetitorScanUrlLoading}
                >
                    Compare
                </Button>
            </Tooltip>
        </Form>
    );
};

export default CompetitorsSearchInput;
