import React from 'react';
import { useSelector } from 'react-redux';

import { useCurrentEditor } from '@tiptap/react';
import { Button, Tooltip } from 'antd';

import RedoIcon from '../../../../../resources/icons/editor/RedoIcon';
import UndoIcon from '../../../../../resources/icons/editor/UndoIcon';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import { getIconColorByDarkMode } from '../constants';

import CustomButtonsTooltip from './CustomButtonsTooltip';

const classes = {
    undoRedoButtonsSection: 'undo-redo-buttons-section',
    tooltipOverlay: 'tooltip-overlay toolbar-tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
};

const UndoRedoButtonsSection = () => {
    const { editor } = useCurrentEditor();

    const darkMode = useSelector(selectDarkMode);

    return (
        <div
            className={`${classes.undoRedoButtonsSection} ${
                darkMode ? 'dark-mode' : ''
            }`}
        >
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={<CustomButtonsTooltip title='Undo' />}
            >
                <>
                    <Button
                        icon={
                            <UndoIcon
                                color={getIconColorByDarkMode(darkMode)}
                            />
                        }
                        onClick={() => editor.chain().focus().undo().run()}
                    />
                </>
            </Tooltip>
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay} ${
                    darkMode ? classes.tooltipOverlayDark : ''
                }`}
                title={<CustomButtonsTooltip title='Redo' />}
            >
                <>
                    <Button
                        icon={
                            <RedoIcon
                                color={getIconColorByDarkMode(darkMode)}
                            />
                        }
                        onClick={() => editor.chain().focus().redo().run()}
                    />
                </>
            </Tooltip>
        </div>
    );
};

export default UndoRedoButtonsSection;
