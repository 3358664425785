import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Form, Input, Modal, Typography } from 'antd';

import { selectDarkMode } from '../../../../../userBrowserSettings/store/browserSettings.selectors';

import CloseIcon from '../../../../../resources/icons/CloseIcon';
import { colorPalette } from '../../../../../resources/styles/colorPalette';

import { axiosAPI } from '../../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../../utils/helpers/openNotification';
import { useOpen } from '../../../../../utils/hooks/useOpen';

import { notificationType } from '../../../../../constants/notificationType';
import { userDataPathes } from '../../../../../constants/queryPathes';
import { passwordPattern } from '../../../../../constants/regularExpression';
import { errorMessages } from '../../../../../constants/errorMessages';
import { statusCodes } from '../../../../../constants/statusCodes';

import ErrorMessage from '../../../../common/errorMessage/ErrorMessage';

const { Link } = Typography;

const classes = {
    modalWrapper: 'change-password-modal',
    modalWrapperDark: 'change-password-modal-dark',
    modalFormFieldsWrapper: 'form-fields-wrapper',
    modalFormButtonsWrapper: 'form-buttons-wrapper d-flex justify-content-end',
    link: 'link-hover-highlight',
};

const ChangePasswordModal = () => {
    const [form] = Form.useForm();

    const darkMode = useSelector(selectDarkMode);

    const { isOpen, handleOpen } = useOpen();

    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [error, setError] = useState(null);

    const closeModalIconColor = darkMode
        ? colorPalette.textColor.dark
        : colorPalette.textColor.light;

    const handleFormChange = () => {
        if (
            !form.isFieldsTouched(true) ||
            form.getFieldsError().filter(({ errors }) => errors.length).length >
                0
        ) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    };

    const handleCloseModal = () => {
        form.resetFields();
        setError(null);
    };

    const handleChangePassword = (values) => {
        setIsLoading(true);

        axiosAPI
            .post(
                userDataPathes.changePassword,
                { ...values },
                { ...getAxiosHeaders() }
            )
            .then((result) => {
                if (result?.status === statusCodes.success) {
                    openNotification(
                        notificationType.success,
                        'Success',
                        'Password has been updated successfully'
                    );
                    handleOpen();
                }
            })
            .catch((err) => {
                setError(
                    err?.response?.data?.errors?.password?.[0] ||
                        'Please try again'
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <Link
                underline
                onClick={handleOpen}
                className={`${classes.link}
                ${darkMode ? 'dark-mode' : ''}`}
            >
                Change password
            </Link>
            <Modal
                centered
                title='Change Password'
                open={isOpen}
                onCancel={handleOpen}
                footer={null}
                closeIcon={<CloseIcon color={closeModalIconColor} />}
                wrapClassName={`${classes.modalWrapper}
                                ${darkMode ? classes.modalWrapperDark : ''}`}
                afterClose={handleCloseModal}
            >
                <Form
                    form={form}
                    layout='vertical'
                    className={classes.profileForm}
                    onFinish={handleChangePassword}
                    onFieldsChange={handleFormChange}
                >
                    <div className={classes.modalFormFieldsWrapper}>
                        {error && (
                            <ErrorMessage
                                errorMessage={
                                    error === errorMessages.invalidPassword
                                        ? 'Current password does not match'
                                        : 'Please try again'
                                }
                                errorTitle={
                                    error === errorMessages.invalidPassword &&
                                    'Please check your entry and try again'
                                }
                            />
                        )}
                        <Form.Item
                            label='Current password'
                            name='password'
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please enter your current password!',
                                },
                            ]}
                        >
                            <Input.Password placeholder='Enter your current password' />
                        </Form.Item>
                        <Form.Item
                            label='New password'
                            name='newPassword'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your new password!',
                                },
                                {
                                    pattern: passwordPattern,
                                    message:
                                        'Password must contain at least 8 characters, including upper/lowercase letters, numbers & symbols',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            !value ||
                                            getFieldValue('password') !== value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                'The two passwords that you entered are the same!'
                                            )
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input.Password placeholder='Enter your new password' />
                        </Form.Item>
                    </div>
                    <div className={classes.modalFormButtonsWrapper}>
                        <Button
                            type='primary'
                            size='large'
                            ghost
                            onClick={handleOpen}
                            className={`${darkMode ? 'dark-mode' : ''}`}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='primary'
                            size='large'
                            htmlType='submit'
                            disabled={isDisabled}
                            loading={isLoading}
                        >
                            Save
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default ChangePasswordModal;
