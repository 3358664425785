import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Ai from '@tiptap-pro/extension-ai';
import { EditorProvider } from '@tiptap/react';
import { debounce } from 'lodash';

import useUpdateEditorContent from './hooks/useUpdateEditorContent';
import { configureCustomKeyboardShortcuts } from './utils/customKeyboardShortcuts';

import { editorAutoSaveDelay } from '../../../constants/contentOptimizer';

import { HighlightedKeywordContext } from '../../pages/contentOptimizer/contentPage/content/optimizerTab/tiptapEditor/TipTapEditorWrapper';

import TipTapEditorSettings from './TipTapEditorSettings';
import EditorToolbar from './toolbar/EditorToolbar';
import EditLinkContent from './toolbar/buttons/content/EditLinkContent';
// import DraggableComponent from './components/DraggableComponent';

import { HighlightKeywordsExtension } from './extensions/customExtensions';
import { tiptapEditorExtensions } from './extensions/tiptapEditorExtensions';

import {
    setAIState,
    updateCurrentEditorText,
} from './store/tiptapEditor.actions';
import {
    selectCurrentEditorContent,
    selectEditorPreviewVersion,
    selectGeneratedAIToken,
} from './store/tiptapEditor.selectors';

import './TipTapEditor.scss';

const TipTapEditor = () => {
    const dispatch = useDispatch();

    const mappedKeywords = React.useContext(HighlightedKeywordContext);

    const currentEditorContent = useSelector(selectCurrentEditorContent);
    const editorPreviewVersion = useSelector(selectEditorPreviewVersion);

    const generatedAIToken = useSelector(selectGeneratedAIToken);

    const [handleUpdateEditor] = useUpdateEditorContent();

    const handleEditorReady = (editorData) => {
        const { editor } = editorData;

        if (editor) {
            editor.options.editorProps.handleKeyDown = (_, event) => {
                configureCustomKeyboardShortcuts(editor, event);
            };
            editor.commands.setKeywordList(mappedKeywords || []);

            const newEditorContent =
                editorPreviewVersion && editorPreviewVersion.content?.length > 0
                    ? editorPreviewVersion.content.slice(1, -1)
                    : currentEditorContent;

            if (editor.getHTML() !== newEditorContent) {
                editor.commands.setContent(newEditorContent);

                const editorText = editor.getText();
                dispatch(updateCurrentEditorText(editorText));
            }
        }
    };

    const handleEditorChange = debounce((editorOnChangeData) => {
        const { editor } = editorOnChangeData;
        const updatedEditorData = editor?.getHTML();
        const updatedEditorText = editor?.getText() || '';

        dispatch(updateCurrentEditorText(updatedEditorText));
        handleUpdateEditor(updatedEditorData, updatedEditorText);
    }, editorAutoSaveDelay);

    return (
        <EditorProvider
            slotBefore={<EditorToolbar />}
            extensions={[
                ...tiptapEditorExtensions,
                HighlightKeywordsExtension.configure({
                    className: 'highlight-keyword',
                }),
                Ai.configure({
                    appId: process.env.REACT_APP_TIPTAP_AI_APP_ID || '',
                    token: generatedAIToken || '',
                    autocompletion: true,
                    onLoading: () => {
                        dispatch(
                            setAIState({
                                isLoading: true,
                                errorMessage: null,
                                response: '',
                            })
                        );
                    },
                    onChunk: ({ response }) => {
                        dispatch(
                            setAIState({
                                isLoading: true,
                                errorMessage: null,
                                response,
                            })
                        );
                    },
                    onSuccess: ({ response }) => {
                        dispatch(
                            setAIState({
                                isLoading: false,
                                errorMessage: null,
                                response,
                            })
                        );
                    },
                    onError: (error) => {
                        dispatch(
                            setAIState({
                                isLoading: false,
                                errorMessage: error.message,
                                response: null,
                            })
                        );
                    },
                }),
            ]}
            onCreate={handleEditorReady}
            onUpdate={handleEditorChange}
        >
            <TipTapEditorSettings />
            <EditLinkContent />
            {/* <DraggableComponent /> */}
        </EditorProvider>
    );
};

export default TipTapEditor;
