import React from 'react';

import EditorMoreDropdown from '../../../pages/contentOptimizer/contentPage/content/optimizerTab/editor/content/toolbar/moreDropdown/EditorMoreDropdown';
import EditorVersionHistory from '../../../pages/contentOptimizer/contentPage/content/optimizerTab/editor/content/toolbar/versionHistory/EditorVersionHistory';

import useEditorVersions from '../hooks/useEditorVersions';

const classes = {
    toolbarSettingsSection: 'toolbar-settings-section d-flex',
};

const ToolbarSettingsSection = () => {
    const [fetchEditorVersions, createNewEditorVersion, isVersionCreating] =
        useEditorVersions();

    return (
        <div className={classes.toolbarSettingsSection}>
            <EditorVersionHistory
                fetchEditorVersions={fetchEditorVersions}
                createNewEditorVersion={createNewEditorVersion}
                newEditorVersionCreating={isVersionCreating}
            />
            <EditorMoreDropdown />
        </div>
    );
};

export default ToolbarSettingsSection;
