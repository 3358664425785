import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectDarkMode } from '../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { generateTipTapAIJwtToken } from '../../../../../../../utils/helpers/editor/generateJWT';

import SkeletonLoading from '../../../../../../common/SkeletonLoading';
import RankabilityLoader from '../../../../../../common/rankabilityLoader/RankabilityLoader';
import TipTapEditor from '../../../../../../common/tiptapEditor/TipTapEditor';
import useEditorVersions from '../../../../../../common/tiptapEditor/hooks/useEditorVersions';

import {
    selectAIStateLoading,
    selectCurrentEditorContent,
    selectCurrentEditorLoading,
    selectEditorFullWidth,
    selectEditorHighlightKeywords,
    selectEditorHtmlTags,
    selectGeneratedAIToken,
} from '../../../../../../common/tiptapEditor/store/tiptapEditor.selectors';
import { selectContentOptimizerKeywords } from '../../../store/contentOptimizerContent.selectors';

import { setGeneratedAIToken } from '../../../../../../common/tiptapEditor/store/tiptapEditor.actions';

import './TipTapEditorWrapper.scss';

const classes = {
    tiptapEditorWrapper: 'tiptap-editor-wrapper',
    tiptapEditorLoader: 'tiptap-editor-loader',
};

export const HighlightedKeywordContext = React.createContext([]);

const TipTapEditorWrapper = () => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);

    const currentEditorLoading = useSelector(selectCurrentEditorLoading);
    const currentEditorContent = useSelector(selectCurrentEditorContent);

    const keywords = useSelector(selectContentOptimizerKeywords);

    const editorHighlightKeywords = useSelector(selectEditorHighlightKeywords);
    const editorFullWidth = useSelector(selectEditorFullWidth);
    const editorHtmlTags = useSelector(selectEditorHtmlTags);

    const generatedToken = useSelector(selectGeneratedAIToken);
    const aiStateLoading = useSelector(selectAIStateLoading);

    const [fetchEditorVersions] = useEditorVersions();

    const { contentOptimizerId } = useParams();

    const [mappedKeywords, setMappedKeywords] = useState([]);

    useEffect(() => {
        if (contentOptimizerId) {
            fetchEditorVersions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentOptimizerId]);

    useEffect(() => {
        if (keywords) {
            setMappedKeywords(
                keywords?.map((item) => {
                    return {
                        regex: item?.regex,
                        keyword: item?.keyword?.toLowerCase(),
                    };
                })
            );
        }
    }, [keywords]);

    useEffect(() => {
        const getToken = async () => {
            if (!generatedToken) {
                let token = await generateTipTapAIJwtToken();
                dispatch(setGeneratedAIToken(token));
            }
        };

        getToken();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generatedToken]);

    return (
        <HighlightedKeywordContext.Provider value={mappedKeywords}>
            {!currentEditorLoading && currentEditorContent && mappedKeywords ? (
                <div
                    className={`${classes.tiptapEditorWrapper} ${
                        darkMode ? 'dark-mode' : ''
                    } ${
                        editorHighlightKeywords
                            ? ''
                            : 'turn-off-highlight-keywords'
                    } ${editorFullWidth ? 'turn-on-editor-full-width' : ''} ${
                        editorHtmlTags ? 'turn-on-editor-html-tags' : ''
                    }`}
                >
                    {aiStateLoading && (
                        <div className={classes.rankabilityLoaderWrapper}>
                            <RankabilityLoader
                                absolute
                                className={classes.tiptapEditorLoader}
                            />
                        </div>
                    )}
                    <TipTapEditor currentEditorContent={currentEditorContent} />
                </div>
            ) : (
                <SkeletonLoading width='98%' />
            )}
        </HighlightedKeywordContext.Provider>
    );
};

export default TipTapEditorWrapper;
