import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Card, List } from 'antd';
import moment from 'moment';

import { selectViewMode } from '../../../../userBrowserSettings/store/browserSettings.selectors';
import { decreaseSubscriptionLimits } from '../../account/billing/store/billingSettings.actions';
import { removeContentOptimizerData } from '../mainPage/store/contentOptimizerMain.actions';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../utils/helpers/openNotification';
import { useOrganizationSlug } from '../../../../utils/hooks/useOrganizationSlug';

import {
    errorNotificationMessage,
    notificationType,
} from '../../../../constants/notificationType';
import { contentBriefPathes } from '../../../../constants/queryPathes';
import { subscriptionLimits } from '../../../../constants/subscriptionLimits';
import { viewModeType } from '../../../../constants/viewModeType';

import OptimizerKanbanView from './OptimizerKanbanView';
import OptimizerListView from './OptimizerListView';

import './ContentOptimizerList.scss';

const classes = {
    listCard: 'list-card w-100',
    disabledItem: 'disabled-content-optimizer-item',
};

const ContentOptimizerListItem = ({ optimizerItem, users, showEmptySpace }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const organizationSlug = useOrganizationSlug();

    const viewMode = useSelector(selectViewMode);

    const isOptimizerItemCompleted =
        optimizerItem?.queue_status === 'completed' ||
        optimizerItem?.queue_status === 'failed' ||
        !optimizerItem?.queue_status;

    const handleContentOptimizerNavigate = () => {
        if (isOptimizerItemCompleted) {
            navigate(`/${organizationSlug}/optimizer/${optimizerItem.id}`);
        }
    };

    const [startedStatus] = useState(optimizerItem?.queue_progress);

    const [renderCancelButton, setRenderCancelButton] = useState(false);

    useEffect(() => {
        let timer = null;

        const canceledSeconds = moment
            .utc()
            .local()
            .diff(
                moment
                    .utc(
                        optimizerItem?.content_brief?.user_created_at_time ||
                            optimizerItem?.created_at
                    )
                    .local(),
                'seconds'
            );

        if (canceledSeconds <= 30) {
            setRenderCancelButton(true);

            timer = setTimeout(() => {
                setRenderCancelButton(false);
            }, (30 - canceledSeconds) * 1000);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [optimizerItem]);

    const cancelItem = () => {
        if (optimizerItem?.id) {
            axiosAPI
                .delete(contentBriefPathes.deleteContentBrief, {
                    ...getAxiosHeaders(),
                    data: {
                        ids: [optimizerItem?.id],
                    },
                })
                .then((result) => {
                    dispatch(removeContentOptimizerData(result?.data?.deleted));
                    dispatch(
                        decreaseSubscriptionLimits({
                            limit: subscriptionLimits.optimizersUsed,
                            value: 1,
                        })
                    );
                    openNotification(
                        notificationType.success,
                        'Success',
                        'Canceled successfully'
                    );
                })
                .catch((_err) => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        }
    };

    return (
        <List.Item>
            <Card
                className={`${classes.listCard} ${
                    optimizerItem.queue_status === 'failed'
                        ? classes.disabledItem
                        : ''
                }`}
            >
                {viewMode === viewModeType.list && (
                    <OptimizerListView
                        optimizerItem={optimizerItem}
                        handleContentOptimizerNavigate={
                            optimizerItem.queue_status !== 'failed'
                                ? handleContentOptimizerNavigate
                                : null
                        }
                        isOptimizerItemCompleted={isOptimizerItemCompleted}
                        users={users}
                        disabled={optimizerItem.queue_status === 'failed'}
                        showEmptySpace={showEmptySpace}
                        startedStatus={startedStatus}
                        renderCancelButton={renderCancelButton}
                        cancelItem={cancelItem}
                    />
                )}

                {viewMode === viewModeType.kanban && (
                    <OptimizerKanbanView
                        optimizerItem={optimizerItem}
                        handleContentOptimizerNavigate={
                            optimizerItem.queue_status !== 'failed'
                                ? handleContentOptimizerNavigate
                                : null
                        }
                        isOptimizerItemCompleted={isOptimizerItemCompleted}
                        users={users}
                        disabled={optimizerItem.queue_status === 'failed'}
                        showEmptySpace={showEmptySpace}
                        startedStatus={startedStatus}
                        renderCancelButton={renderCancelButton}
                        cancelItem={cancelItem}
                    />
                )}
            </Card>
        </List.Item>
    );
};

export default ContentOptimizerListItem;
