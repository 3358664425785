import React from 'react';

const ExportIcon = ({ color = '#1F1F1F' }) => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M17.9252 12.5918V12.7001C17.9252 16.4251 17.9252 17.9168 17.9252 17.9168H2.06689C2.06689 17.9168 2.06689 16.4251 2.06689 12.7001V12.5918'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M10 1.66602V12.3993'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='square'
                strokeLinejoin='round'
            />
            <path
                d='M12.7918 10.541L10.0002 13.3327L7.2085 10.541'
                stroke={color}
                strokeWidth='1.5'
                strokeLinecap='square'
            />
        </svg>
    );
};

export default ExportIcon;
