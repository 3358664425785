import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { Menu } from 'antd';

import {
    selectDarkMode,
    selectUserData,
} from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { colorPalette } from '../../../../resources/styles/colorPalette';
import { useDefaultSiderMenuItems } from '../../../../utils/hooks/useDefaultSiderMenuItems';
import { useOrganizationSlug } from '../../../../utils/hooks/useOrganizationSlug';

import { getDefaultSelectedKeys } from '../../../../utils/helpers/getDefaultSelectedKey';
import { checkIsGlobalAdmin } from '../../../../utils/helpers/checkIsGlobalAdmin';

import { globalAdminMenuItems } from '../../../../constants/globalAdminMenuItems';

const NavMenu = ({ collapsed }) => {
    const location = useLocation();

    const darkMode = useSelector(selectDarkMode);
    const userData = useSelector(selectUserData);

    const organizationSlug = useOrganizationSlug();
    const defaultNavItems = useDefaultSiderMenuItems();

    const [selectedItem, setSelectedItem] = useState(
        getDefaultSelectedKeys(location.pathname)
    );

    // To update selected item when user navigates to another page
    useEffect(() => {
        setSelectedItem(getDefaultSelectedKeys(location.pathname));
    }, [location.pathname]);

    const getItemColor = (key) => {
        if (key === selectedItem) {
            return colorPalette.colorPrimary;
        } else if (!darkMode) {
            return colorPalette.iconColor.light;
        } else {
            return colorPalette.iconColor.dark;
        }
    };

    const generateNavMenuItems = (menuItems) => {
        return menuItems.map((item) => {
            return {
                label: (
                    <Link
                        className={`${darkMode ? 'dark-mode' : ''} ${
                            item.className || ''
                        }`}
                        to={`${organizationSlug}/${item.key}`}
                        onClick={item.handleClick}
                    >
                        <div className='menu-icon'>
                            <item.icon color={getItemColor(item.key)} />
                        </div>
                        {!collapsed && item.label}
                    </Link>
                ),
                key: item.key,
                title: '',
                disabled: item.isDisabled,
            };
        });
    };

    const handleSelect = ({ key }) => {
        setSelectedItem(key);
    };

    return (
        <Menu
            className={darkMode ? 'dark-mode' : ''}
            defaultSelectedKeys={[getDefaultSelectedKeys(location.pathname)]}
            selectedKeys={[selectedItem]}
            mode='inline'
            items={generateNavMenuItems(
                checkIsGlobalAdmin(userData?.roles)
                    ? globalAdminMenuItems
                    : defaultNavItems
            )}
            onSelect={handleSelect}
        />
    );
};

export default NavMenu;
