import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Button, Tooltip } from 'antd';

import { selectDarkMode } from '../../../../../../../../userBrowserSettings/store/browserSettings.selectors';
import { useSortedOptimizerKeywords } from '../../../../../../../../utils/hooks/useSortedOptimizerKeywords';

import { colorPalette } from '../../../../../../../../resources/styles/colorPalette';
import CopySectionIcon from '../../../../../../../../resources/icons/CopySectionIcon';

const classes = {
    exportKeywordsBtn: 'optimizer-keywords-icon-btn',
    tooltipOverlay: 'tooltip-overlay optimizer-keywords-tooltip-overlay',
    tooltipOverlayDark:
        'tooltip-overlay-dark optimizer-keywords-tooltip-overlay-dark',
};

const OptimizerKeywordsCopyButton = () => {
    const darkMode = useSelector(selectDarkMode);

    const sortedKeywords = useSortedOptimizerKeywords();

    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        const textToCopy = sortedKeywords
            ?.map((item) => {
                return item?.keyword;
            })
            ?.join('\n');

        navigator.clipboard.writeText(textToCopy);
        setIsCopied(true);
    };

    const handleTooltipOpenChange = () => {
        setIsCopied(false);
    };

    return (
        <Tooltip
            title={isCopied ? 'Copied!' : 'Copy to clipboard'}
            overlayClassName={`${classes.tooltipOverlay}
                               ${darkMode ? classes.tooltipOverlayDark : ''}`}
            placement='top'
            onOpenChange={handleTooltipOpenChange}
        >
            <Button
                className={`${classes.exportKeywordsBtn}
                ${darkMode ? 'dark-mode' : ''}`}
                type='primary'
                size='large'
                ghost
                onClick={handleCopy}
            >
                <CopySectionIcon color={colorPalette.colorPrimary} size={18} />
            </Button>
        </Tooltip>
    );
};

export default OptimizerKeywordsCopyButton;
