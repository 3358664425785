import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    selectIsGracePeriod,
    selectUserData,
} from '../../userBrowserSettings/store/browserSettings.selectors';

import { checkIsGlobalAdmin } from '../../utils/helpers/checkIsGlobalAdmin';
import { openNotification } from '../../utils/helpers/openNotification';
import { getAxiosHeaders } from '../../utils/helpers/getAxiosHeaders';
import { usePrevious } from '../../utils/hooks/usePrevious';
import { axiosAPI } from '../../utils/axiosAPI';
import SubscriptionHelper from '../pages/auth/helpers/subscription.helper';

import {
    errorNotificationMessage,
    notificationType,
} from '../../constants/notificationType';
import { paymentsPathes } from '../../constants/queryPathes';
import { statusCodes } from '../../constants/statusCodes';

import {
    selectCurrentSubscription,
    selectSubscriptionLimits,
} from '../pages/account/billing/store/billingSettings.selectors';
import { setSubscriptionLimits } from '../pages/account/billing/store/billingSettings.actions';
import {
    setIsGracePeriod,
    setIsSubscriptionExpired,
} from '../../userBrowserSettings/store/browserSettings.actions';

const withSubscriptionLimitsProvider = (ComposedComponent) => {
    return memo((props) => {
        const dispatch = useDispatch();

        const subscriptionLimits = useSelector(selectSubscriptionLimits);
        const currentSubscription = useSelector(selectCurrentSubscription);
        const isGracePeriod = useSelector(selectIsGracePeriod);
        const userData = useSelector(selectUserData);

        const prevSubscriptionPlanId = usePrevious(
            currentSubscription?.plan?.id
        );
        const prevIsGracePeriod = usePrevious(isGracePeriod);

        useEffect(() => {
            if (
                currentSubscription &&
                !currentSubscription?.canceled_at &&
                !currentSubscription?.cancel_at_period_end
            ) {
                dispatch(setIsSubscriptionExpired(false));

                if (!currentSubscription?.paid) {
                    dispatch(setIsGracePeriod(true));
                }
            }

            if (currentSubscription?.paid) {
                dispatch(setIsGracePeriod(false));
            }

            // eslint-disable-next-line
        }, [currentSubscription]);

        useEffect(() => {
            if (userData && !checkIsGlobalAdmin(userData?.roles)) {
                if (
                    (userData && !subscriptionLimits) ||
                    (userData &&
                        prevSubscriptionPlanId &&
                        currentSubscription?.plan?.id &&
                        prevSubscriptionPlanId !==
                            currentSubscription?.plan?.id) ||
                    (userData && !isGracePeriod && prevIsGracePeriod)
                ) {
                    axiosAPI
                        .get(paymentsPathes.getQuotes, { ...getAxiosHeaders() })
                        .then((result) => {
                            if (result?.status === statusCodes.success) {
                                dispatch(setSubscriptionLimits(result?.data));
                            }
                        })
                        .catch(() => {
                            openNotification(
                                notificationType.error,
                                'Error',
                                errorNotificationMessage
                            );
                        });
                }
            }

            // eslint-disable-next-line
        }, [
            subscriptionLimits,
            userData,
            currentSubscription?.plan?.id,
            currentSubscription?.subscription_id,
            isGracePeriod,
        ]);

        return <ComposedComponent {...props} />;
    });
};

export default withSubscriptionLimitsProvider;
