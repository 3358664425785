export const contentOptimizerContentActions = {
    CLEAR_CONTENT_OPTIMIZER_CONTENT:
        'CONTENT_OPTIMIZER_CONTENT/CLEAR_CONTENT_OPTIMIZER_CONTENT',

    UPDATE_CURRENT_CONTENT_OPTIMIZER:
        'CONTENT_OPTIMIZER_CONTENT/UPDATE_CURRENT_CONTENT_OPTIMIZER',
    UPDATE_CONTENT_BRIEF_COMPETITORS:
        'CONTENT_OPTIMIZER_CONTENT/UPDATE_CONTENT_BRIEF_COMPETITORS',
    ADD_ITEM_TO_CONTENT_BRIEF_COMPETITORS:
        'CONTENT_OPTIMIZER_CONTENT/ADD_ITEM_TO_CONTENT_BRIEF_COMPETITORS',
    SET_COMPETITOR_SCAN_URL_LOADING:
        'CONTENT_OPTIMIZER_CONTENT/SET_COMPETITOR_SCAN_URL_LOADING',

    UPDATE_COMPETITORS_SEARCH:
        'CONTENT_OPTIMIZER_CONTENT/UPDATE_COMPETITORS_SEARCH',
    UPDATE_COMPETITORS_SORT_KEY:
        'CONTENT_OPTIMIZER_CONTENT/UPDATE_COMPETITORS_SORT_KEY',

    SET_OPTIMIZER_LOADING: 'CONTENT_OPTIMIZER_CONTENT/SET_OPTIMIZER_LOADING',
    SET_KEYWORDS: 'CONTENT_OPTIMIZER_CONTENT/SET_KEYWORDS',
    SET_SUGGESTIONS: 'CONTENT_OPTIMIZER_CONTENT/SET_SUGGESTIONS',
    SET_SHARED: 'CONTENT_OPTIMIZER_CONTENT/SET_SHARED',
    UPDATE_KEYWORDS_SORT_KEY:
        'CONTENT_OPTIMIZER_CONTENT/UPDATE_KEYWORDS_SORT_KEY',
    UPDATE_KEYWORDS_SEARCH_KEY:
    'CONTENT_OPTIMIZER_CONTENT/UPDATE_KEYWORDS_SEARCH_KEY',
    UPDATE_SELECTED_KEYWORD:
        'CONTENT_OPTIMIZER_CONTENT/UPDATE_SELECTED_KEYWORD',

    UPDATE_COMPETITOR: 'CONTENT_OPTIMIZER_CONTENT/UPDATE_COMPETITOR',
    REMOVE_COMPETITOR: 'CONTENT_OPTIMIZER_CONTENT/REMOVE_COMPETITOR',
};

export const updateCompetitor = (payload) => {
    return {
        type: contentOptimizerContentActions.UPDATE_COMPETITOR,
        payload,
    };
};

export const removeCompetitor = (payload) => {
    return {
        type: contentOptimizerContentActions.REMOVE_COMPETITOR,
        payload,
    };
};

export const updateSelectedKeyword = (payload) => {
    return {
        type: contentOptimizerContentActions.UPDATE_SELECTED_KEYWORD,
        payload,
    };
};

export const updateCompetitorsSearch = (payload) => {
    return {
        type: contentOptimizerContentActions.UPDATE_COMPETITORS_SEARCH,
        payload,
    };
};

export const updateCompetitorsSortKey = (payload) => {
    return {
        type: contentOptimizerContentActions.UPDATE_COMPETITORS_SORT_KEY,
        payload,
    };
};

export const updateContentBriefCompetitors = (payload) => {
    return {
        type: contentOptimizerContentActions.UPDATE_CONTENT_BRIEF_COMPETITORS,
        payload,
    };
};

export const addItemToContentBriefCompetitors = (payload) => {
    return {
        type: contentOptimizerContentActions.ADD_ITEM_TO_CONTENT_BRIEF_COMPETITORS,
        payload,
    };
};

export const setCompetitorScanUrlLoading = (payload) => {
    return {
        type: contentOptimizerContentActions.SET_COMPETITOR_SCAN_URL_LOADING,
        payload,
    };
};

export const setShared = (payload) => {
    return {
        type: contentOptimizerContentActions.SET_SHARED,
        payload,
    };
};

export const updateCurrentContentOptimizer = (payload) => {
    return {
        type: contentOptimizerContentActions.UPDATE_CURRENT_CONTENT_OPTIMIZER,
        payload,
    };
};

export const setOptimizerLoading = (payload) => {
    return {
        type: contentOptimizerContentActions.SET_OPTIMIZER_LOADING,
        payload,
    };
};

export const setContentOptimizerKeywords = (payload) => {
    return {
        type: contentOptimizerContentActions.SET_KEYWORDS,
        payload,
    };
};

export const setContentOptimizerSuggestions = (payload) => {
    return {
        type: contentOptimizerContentActions.SET_SUGGESTIONS,
        payload,
    };
};

export const updateKeywordsSortKey = (payload) => {
    return {
        type: contentOptimizerContentActions.UPDATE_KEYWORDS_SORT_KEY,
        payload,
    };
};

export const updateKeywordsSearchKey = (payload) => {
    return {
        type: contentOptimizerContentActions.UPDATE_KEYWORDS_SEARCH_KEY,
        payload,
    };
};

export const clearContentOptimizerContent = () => {
    return {
        type: contentOptimizerContentActions.CLEAR_CONTENT_OPTIMIZER_CONTENT,
    };
};
