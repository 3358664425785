import React, { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Checkbox, List } from 'antd';

import { contentOptimizerMainTabs } from '../../../../constants/contentOptimizerMainTabs';
import {
    errorNotificationMessage,
    notificationType,
} from '../../../../constants/notificationType';
import { contentBriefPathes } from '../../../../constants/queryPathes';
import { subscriptionLimits } from '../../../../constants/subscriptionLimits';
import { viewModeType } from '../../../../constants/viewModeType';

import {
    selectDarkMode,
    selectViewMode,
} from '../../../../userBrowserSettings/store/browserSettings.selectors';

import { axiosAPI } from '../../../../utils/axiosAPI';
import { checkAreBriefsAssignedToProjectNearCurrent } from '../../../../utils/helpers/checkAreBriefsAssignedToProjectNearCurrent';
import { getAxiosHeaders } from '../../../../utils/helpers/getAxiosHeaders';
import { openNotification } from '../../../../utils/helpers/openNotification';
import { renderPaginationArrows } from '../../../../utils/helpers/renderPaginationArrows';
import { useUsers } from '../../../../utils/hooks/useUsers';

import CheckedItemsActionsPanel from '../../../common/checkedItemsActionsPanel/CheckedItemsActionsPanel';
import Loader from '../../../common/loader/Loader';

import { getUnfinishedBriefIds } from '../contentPage/content/briefTab/contentBriefsConstants';

import { decreaseSubscriptionLimits } from '../../account/billing/store/billingSettings.actions';
import {
    decreaseOptimizersCount,
    increaseOptimizersCount,
    multipleUpdateContentOptimizerData,
    removeContentOptimizerData,
    setContentOptimizersChecked,
    updateContentOptimizerData,
} from '../mainPage/store/contentOptimizerMain.actions';
import {
    selectContentOptimizerData,
    selectContentOptimizerMainTab,
    selectContentOptimizersChecked,
} from '../mainPage/store/contentOptimizerMain.selectors';

import ContentOptimizerListItem from './ContentOptimizerListItem';

const classes = {
    listHeaderWrapper:
        'list-header-wrapper d-flex justify-content-between align-items-center',
    listHeaderTitle: 'list-header-title-wrapper d-flex',
    listWrapper: 'list-wrapper',
    kanbanListWrapper: 'kanban-list-wrapper',
    loaderWrapper: 'content-briefs-loader-wrapper d-flex flex-center',
};

const ContentOptimizersList = ({
    isOptimizerLoading,
    listTitle,
    listHeaderButton,
    page,
    pageSize,
    total,
    handleChangePage,
    showOnlyDeleteAction,
}) => {
    const dispatch = useDispatch();

    const darkMode = useSelector(selectDarkMode);
    const listViewMode = useSelector(selectViewMode);
    const checkedOptimizers = useSelector(selectContentOptimizersChecked);
    const selectedTab = useSelector(selectContentOptimizerMainTab);
    const contentOptimizerData = useSelector(selectContentOptimizerData);

    const { users } = useUsers();

    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [selectedProjects, setSelectedProjects] = useState([]);

    useEffect(() => {
        let updateTimer = null;

        const updateOptimizers = (ids) => {
            axiosAPI
                .get(contentBriefPathes.getContentBriefStatus, {
                    ...getAxiosHeaders(),
                    params: { ids: ids?.join() },
                })
                .then((result) => {
                    dispatch(multipleUpdateContentOptimizerData(result?.data));

                    const optimizersToUpdate = getUnfinishedBriefIds(
                        result.data
                    );

                    const failedBriefs = result?.data?.filter(
                        (item) => item?.queue_status === 'failed'
                    );

                    if (failedBriefs?.length) {
                        dispatch(
                            decreaseSubscriptionLimits({
                                limit: subscriptionLimits.optimizersUsed,
                                value: failedBriefs.length,
                            })
                        );
                    }

                    if (optimizersToUpdate.length) {
                        updateTimer = setTimeout(
                            () => updateOptimizers(optimizersToUpdate),
                            15 * 1000
                        );
                    }
                })
                .catch((_err) => {
                    openNotification(
                        notificationType.error,
                        'Error',
                        errorNotificationMessage
                    );
                });
        };

        const briefIdsToUpdate = getUnfinishedBriefIds(
            contentOptimizerData || []
        );

        if (briefIdsToUpdate?.length) {
            updateOptimizers(briefIdsToUpdate);
        }

        return () => {
            if (updateTimer) {
                clearTimeout(updateTimer);
            }
        };
        // eslint-disable-next-line
    }, [contentOptimizerData?.length]);

    useEffect(() => {
        setSelectedProjects(
            contentOptimizerData
                ?.filter((item) => checkedOptimizers.includes(item?.id))
                ?.map((item) => item?.content_brief?.projects)
        );

        // eslint-disable-next-line
    }, [checkedOptimizers?.length, contentOptimizerData?.length]);

    useEffect(() => {
        setIndeterminate(
            checkedOptimizers.length &&
                checkedOptimizers.length !== contentOptimizerData?.length
        );
        setCheckAll(
            checkedOptimizers?.length &&
                checkedOptimizers?.length === contentOptimizerData?.length
        );
    }, [checkedOptimizers, contentOptimizerData?.length]);

    const onCheckAllChange = (e) => {
        dispatch(
            setContentOptimizersChecked(
                e.target.checked
                    ? contentOptimizerData?.map((item) => item.id)
                    : []
            )
        );
        setCheckAll(e.target.checked);
    };

    const handleArchiveAction = () => {
        dispatch(setContentOptimizersChecked([]));
        dispatch(removeContentOptimizerData(checkedOptimizers));
        if (!(selectedTab === contentOptimizerMainTabs.archived.key)) {
            batch(() => {
                dispatch(
                    increaseOptimizersCount([
                        {
                            key: 'archived',
                            value: checkedOptimizers.length,
                        },
                    ])
                );
                dispatch(
                    decreaseOptimizersCount([
                        {
                            key: 'active',
                            value: checkedOptimizers.length,
                        },
                    ])
                );
            });
        } else {
            batch(() => {
                dispatch(
                    increaseOptimizersCount([
                        {
                            key: 'active',
                            value: checkedOptimizers.length,
                        },
                    ])
                );
                dispatch(
                    decreaseOptimizersCount([
                        {
                            key: 'archived',
                            value: checkedOptimizers.length,
                        },
                    ])
                );
            });
        }
    };

    const handleDeleteAction = useCallback(() => {
        batch(() => {
            dispatch(setContentOptimizersChecked([]));
            dispatch(removeContentOptimizerData(checkedOptimizers));
            dispatch(
                decreaseOptimizersCount([
                    {
                        key: selectedTab,
                        value: checkedOptimizers.length,
                    },
                ])
            );
        });
    }, [checkedOptimizers, dispatch, selectedTab]);

    const handleUpdateProjects = useCallback(
        (updatedOptimizers, optimizersAssignedToProjectsDiff) => {
            batch(() => {
                dispatch(updateContentOptimizerData(updatedOptimizers));
                dispatch(setContentOptimizersChecked([]));
            });

            if (optimizersAssignedToProjectsDiff > 0) {
                dispatch(
                    increaseOptimizersCount([
                        {
                            key: 'projects',
                            value: optimizersAssignedToProjectsDiff,
                        },
                    ])
                );
            } else if (optimizersAssignedToProjectsDiff < 0) {
                dispatch(
                    decreaseOptimizersCount([
                        {
                            key: 'projects',
                            value: Math.abs(optimizersAssignedToProjectsDiff),
                        },
                    ])
                );
            }
        },
        [dispatch]
    );

    return (
        <>
            <div className={classes.listHeaderWrapper}>
                <div className={classes.listHeaderTitle}>
                    {contentOptimizerData?.length > 0 && (
                        <div className='d-flex align-items-center'>
                            <Checkbox
                                indeterminate={indeterminate}
                                onChange={onCheckAllChange}
                                checked={checkAll}
                                disabled={isOptimizerLoading}
                            />
                            {listTitle}
                        </div>
                    )}
                    {checkedOptimizers.length > 0 && (
                        <CheckedItemsActionsPanel
                            getOptimizers
                            selectedTab={selectedTab}
                            checkedItems={contentOptimizerData
                                ?.filter((item) =>
                                    checkedOptimizers.includes(item.id)
                                )
                                .map((item) => item.content_brief_id)}
                            showOnlyDeleteAction={showOnlyDeleteAction}
                            selectedProjects={selectedProjects}
                            handleArchiveAction={handleArchiveAction}
                            handleDeleteAction={handleDeleteAction}
                            handleUpdateProjects={handleUpdateProjects}
                            showOnlyManageProjectAction
                        />
                    )}
                </div>
                {listHeaderButton}
            </div>
            <Checkbox.Group
                value={checkedOptimizers}
                onChange={(checkedValues) => {
                    dispatch(setContentOptimizersChecked(checkedValues));
                }}
            >
                <List
                    loading={{
                        spinning: isOptimizerLoading && !contentOptimizerData,
                        indicator: <Loader />,
                    }}
                    grid={
                        listViewMode === viewModeType.kanban && {
                            gutter: 20,
                            column: 4,
                        }
                    }
                    className={`${classes.listWrapper} ${
                        listViewMode === viewModeType.kanban
                            ? classes.kanbanListWrapper
                            : ''
                    } ${darkMode ? 'dark-mode' : ''}`}
                    dataSource={contentOptimizerData || []}
                    renderItem={(item) => (
                        <ContentOptimizerListItem
                            key={item.id}
                            optimizerItem={item}
                            users={users}
                            showEmptySpace={checkAreBriefsAssignedToProjectNearCurrent(
                                item.id,
                                contentOptimizerData || []
                            )}
                        />
                    )}
                    pagination={{
                        pageSize: pageSize,
                        defaultCurrent: page,
                        current: page,
                        total: total,
                        onChange: handleChangePage,
                        itemRender: renderPaginationArrows,
                        showSizeChanger: false,
                    }}
                />
            </Checkbox.Group>
        </>
    );
};

export default ContentOptimizersList;
