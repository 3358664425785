import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button, Form, Input, InputNumber, Tooltip, Typography } from 'antd';

import {
    urlPattern,
    urlSpecificPattern,
} from '../../../../../../../../constants/regularExpression';

import EditIcon from '../../../../../../../../resources/icons/EditIcon';
import TickIcon from '../../../../../../../../resources/icons/versionHistory/TickIcon';
import { colorPalette } from '../../../../../../../../resources/styles/colorPalette';

import { selectDarkMode } from '../../../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { checkBriefOptimal } from '../../../../../../../../utils/helpers/checkBriefOptimal';
import { getNumberRoundedToHundreds } from '../../../../../../../../utils/helpers/getNumberRoundedToHundreds';
import { getNumberWithCommaAsThousandSeparator } from '../../../../../../../../utils/helpers/getNumberWithCommaAsThousandSeparator';

import CopyItem from '../../../../../../../common/copyItem/CopyItem';

import { selectEditorBasicData } from '../../../../../../../common/tiptapEditor/store/tiptapEditor.selectors';
import { selectContentOptimizerViewOnlyMode } from '../../../../../viewOnly/store/viewOnlySettings.selectors';

const LINK_EXAMPLES_FIELD = 'example_articles';

const classes = {
    copyIcon: 'copy-icon',
    tooltipOverlay: 'tooltip-overlay',
    tooltipOverlayDark: 'tooltip-overlay-dark',
    briefTitle: 'brief-title text-decoration-dotted',
    briefText: 'brief-text d-flex align-items-center',
    briefLabel: 'brief-label',
    checkMark: 'check-mark',
    briefInputNumber: 'w-100',
    linkArea: 'link-area',
};

const BriefFormItem = ({
    primaryKeyword,
    value,
    savedValue,
    isContentInBrief,
    reduceInfo,
    briefFormItem,
    handleEditBrief,
    setError,
    deletePageUrlIfExists,
}) => {
    const { contentOptimizerId } = useParams();

    const darkMode = useSelector(selectDarkMode);
    const viewOnlyMode = useSelector(selectContentOptimizerViewOnlyMode);
    const basicData = useSelector(selectEditorBasicData);

    const [editable, setEditable] = useState(false);
    const [allowGreenMark, setAllowGreenMark] = useState(true);

    const iconColor = darkMode
        ? colorPalette.textAccentColor.dark
        : colorPalette.textAccentColor.light;

    const handleSetEditable = () => {
        setEditable(true);
    };

    const isExampleArticlesField = (fieldName = '') => {
        return fieldName === LINK_EXAMPLES_FIELD;
    };

    const reguirements =
        briefFormItem?.name === 'word_count'
            ? {
                  characters: {
                      min: getNumberRoundedToHundreds(
                          basicData?.optimal_word_count.min
                      ),
                      max: getNumberRoundedToHundreds(
                          basicData?.optimal_word_count.max
                      ),
                  },
              }
            : briefFormItem?.requirements;

    const checkOptimal = checkBriefOptimal(value, reguirements, primaryKeyword);

    const requireAll = checkOptimal?.all;
    const requireCharacters = checkOptimal?.characters;
    const requirePrimaryKeyword = checkOptimal?.primaryKeyword;

    const getLinkListFromString = (dataValue, fromStart = false) => {
        const pattern = fromStart ? urlPattern : urlSpecificPattern;

        return dataValue?.match(pattern) || [];
    };

    const isLinkListValid = (currentLinksValue = []) => {
        if (currentLinksValue.length) {
            return currentLinksValue.every(
                (link) => link.match(urlPattern) || link === ''
            );
        }

        if (!currentLinksValue?.length && value?.length) {
            return false;
        }

        return true;
    };

    const getCheckMark = () => {
        if (briefFormItem?.name === 'word_count') {
            return (
                <>
                    {requireAll && (
                        <span className={classes.checkMark}>
                            <TickIcon />
                        </span>
                    )}
                </>
            );
        } else if (briefFormItem?.name === 'page_url') {
            return (
                <>
                    {requirePrimaryKeyword && allowGreenMark && (
                        <span className={classes.checkMark}>
                            <TickIcon />
                        </span>
                    )}
                </>
            );
        } else {
            return (
                <>
                    {requireAll && (
                        <span className={classes.checkMark}>
                            <TickIcon />
                        </span>
                    )}
                </>
            );
        }
    };

    const getLabelTitle = () => {
        return (
            <Tooltip
                overlayClassName={`${classes.tooltipOverlay}
                                       ${
                                           darkMode &&
                                           classes.tooltipOverlayDark
                                       }`}
                title={briefFormItem?.label}
                placement='top'
            >
                <span className={classes.briefTitle}>
                    {briefFormItem?.label}
                </span>
            </Tooltip>
        );
    };

    const getLabelRequirements = () => {
        if (isExampleArticlesField(briefFormItem?.name)) {
            return (
                <div className='d-flex'>
                    <Typography>{briefFormItem?.requirementsLabel}</Typography>
                    {!reduceInfo &&
                        getLinkListFromString(value) &&
                        getCheckMark()}
                </div>
            );
        }

        if (briefFormItem?.name === 'word_count') {
            return (
                <div className='d-flex'>
                    <Typography>
                        ({!reduceInfo && 'Optimal: '}
                        <span
                            className={
                                requireCharacters
                                    ? 'success'
                                    : value
                                    ? 'empty'
                                    : ''
                            }
                        >
                            {getNumberWithCommaAsThousandSeparator(value)}
                        </span>{' '}
                        /{' '}
                        {getNumberWithCommaAsThousandSeparator(
                            getNumberRoundedToHundreds(
                                basicData?.optimal_word_count.min
                            )
                        )}
                        {' - '}
                        {getNumberWithCommaAsThousandSeparator(
                            getNumberRoundedToHundreds(
                                basicData?.optimal_word_count.max
                            )
                        )}
                        {' words'})
                    </Typography>
                    {!reduceInfo && getCheckMark()}
                </div>
            );
        }

        return (
            <div className='d-flex'>
                {briefFormItem?.requirements?.label && (
                    <Typography>
                        ({!reduceInfo && 'Optimal: '}
                        {(briefFormItem?.requirements?.characters?.min ||
                            briefFormItem?.requirements?.characters?.max) && (
                            <>
                                <span
                                    className={
                                        requireCharacters
                                            ? 'success'
                                            : value
                                            ? 'empty'
                                            : ''
                                    }
                                >
                                    {value?.trim()?.length}
                                </span>{' '}
                                / {briefFormItem?.requirements?.characters?.min}{' '}
                                - {briefFormItem?.requirements?.characters?.max}{' '}
                                characters{' '}
                            </>
                        )}
                        {(briefFormItem?.requirements?.characters?.min ||
                            briefFormItem?.requirements?.characters?.max) &&
                            briefFormItem?.requirements?.primaryKeyword &&
                            '& '}
                        {briefFormItem?.requirements?.primaryKeyword && (
                            <>
                                {!reduceInfo && ' Includes '}
                                <span
                                    className={
                                        requirePrimaryKeyword
                                            ? 'success'
                                            : value
                                            ? 'empty'
                                            : ''
                                    }
                                >
                                    {!reduceInfo
                                        ? 'primary keyword'
                                        : 'primary kw'}
                                </span>
                            </>
                        )}
                        )
                    </Typography>
                )}
                {!reduceInfo && getCheckMark()}
            </div>
        );
    };

    const getPlaceholder = () => {
        if (viewOnlyMode) {
            return <i>No added {briefFormItem?.placeholder?.slice(13)}</i>;
        }

        if (isContentInBrief) {
            return `Click ${briefFormItem?.placeholder?.slice(6)}`;
        }

        return contentOptimizerId
            ? briefFormItem?.placeholder?.replaceAll('\n', ' ')
            : briefFormItem?.placeholder;
    };

    const getPlaceholderElement = () => {
        if (viewOnlyMode) {
            return <i>No added {briefFormItem?.placeholder?.slice(13)}</i>;
        } else if (isContentInBrief) {
            return (
                <span className='empty'>{`Click ${briefFormItem?.placeholder?.slice(
                    6
                )}`}</span>
            );
        } else {
            return (
                <span className='empty'>
                    {contentOptimizerId
                        ? briefFormItem?.placeholder?.replaceAll('\n', ' ')
                        : briefFormItem?.placeholder}
                </span>
            );
        }
    };

    const handleEdit = () => {
        if (savedValue !== value) {
            if (briefFormItem.url) {
                const currentLinksValue = getLinkListFromString(
                    value,
                    !isExampleArticlesField(briefFormItem.name)
                );

                if (isExampleArticlesField(briefFormItem.name)) {
                    deletePageUrlIfExists(`${value}`);
                }

                if (isLinkListValid(currentLinksValue)) {
                    setAllowGreenMark(true);
                    handleEditBrief(briefFormItem.name);
                    setEditable(false);
                } else {
                    setAllowGreenMark(false);
                    setError(
                        briefFormItem.name,
                        'Please enter a valid URL (e.g. https://www.google.com)'
                    );
                }
            } else {
                handleEditBrief(briefFormItem.name);
                setEditable(false);
            }
        } else {
            if (briefFormItem.url) {
                const currentLinksValue = getLinkListFromString(
                    value,
                    !isExampleArticlesField(briefFormItem.name)
                );

                if (isLinkListValid(currentLinksValue)) {
                    setAllowGreenMark(true);
                    handleEditBrief(briefFormItem.name);
                    setEditable(false);
                }
            }
        }
    };

    const handleChange = () => {
        setError(briefFormItem.name, null);
        setAllowGreenMark(false);
    };

    const getCopiedText = () => {
        const title = `${briefFormItem?.label}`;
        const copiedText = `${title}\n${value}`;

        return copiedText;
    };

    const handleDoubleClickEdit = () => {
        setEditable(true);
    };

    let firstClickEvent = false;
    let singleClickTimer;

    const examplesClickHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();

        switch (e.detail) {
            case 1:
                if (!firstClickEvent) {
                    singleClickTimer = setTimeout(() => {
                        e.view.open(e.target.href);
                        firstClickEvent = false;
                    }, 500);
                }
                firstClickEvent = true;

                break;
            case 2:
            default:
                clearTimeout(singleClickTimer);
                handleDoubleClickEdit();
                firstClickEvent = false;

                break;
        }
    };

    const getExampleArticlesData = (exampleArticles = []) => {
        return exampleArticles.map(
            (link, index) =>
                link.trim() && (
                    <>
                        <Typography.Link
                            key={`${link + index}`}
                            href={
                                link.includes('https:') ||
                                link.includes('http:')
                                    ? link
                                    : `https://${link}`
                            }
                            rel='noreferrer'
                            target='_blank'
                            onClick={examplesClickHandler}
                        >
                            {link}
                        </Typography.Link>
                        <br />
                    </>
                )
        );
    };

    const renderReadOnlyLinks = () => {
        if (
            isContentInBrief &&
            !editable &&
            isExampleArticlesField(briefFormItem.name)
        ) {
            return (
                <div
                    className={classes.briefText}
                    onDoubleClick={handleDoubleClickEdit}
                >
                    {getExampleArticlesData(value?.split('\n'))}
                </div>
            );
        }

        return null;
    };

    const renderInput = () => {
        if (viewOnlyMode) {
            return (
                <Typography.Text className={classes.briefText}>
                    {value || getPlaceholderElement()}
                </Typography.Text>
            );
        }

        if (
            !isContentInBrief ||
            (isContentInBrief && value) ||
            (isContentInBrief && editable)
        ) {
            if (isContentInBrief && !editable) {
                return (
                    renderReadOnlyLinks() || (
                        <Typography.Text
                            className={classes.briefText}
                            onDoubleClick={handleDoubleClickEdit}
                        >
                            {value}
                        </Typography.Text>
                    )
                );
            } else {
                if (briefFormItem.textarea) {
                    return (
                        <Input.TextArea
                            className={briefFormItem.url && classes.linkArea}
                            placeholder={getPlaceholder()}
                            autoSize={briefFormItem.autosize}
                            onClick={handleSetEditable}
                            onBlur={!isContentInBrief ? handleEdit : null}
                            onChange={handleChange}
                        />
                    );
                } else if (briefFormItem.number) {
                    return (
                        <InputNumber
                            min={0}
                            type='number'
                            className={classes.briefInputNumber}
                            placeholder={getPlaceholder()}
                            onClick={handleSetEditable}
                            onBlur={!isContentInBrief ? handleEdit : null}
                        />
                    );
                } else {
                    return (
                        <Input
                            className={briefFormItem.url && classes.linkArea}
                            placeholder={getPlaceholder()}
                            onClick={handleSetEditable}
                            onBlur={!isContentInBrief ? handleEdit : null}
                            onChange={handleChange}
                        />
                    );
                }
            }
        } else {
            return (
                <div className={classes.briefText} onClick={handleSetEditable}>
                    <Typography.Text>{getPlaceholderElement()}</Typography.Text>
                </div>
            );
        }
    };

    return (
        <Form.Item
            key={briefFormItem.name}
            name={briefFormItem.name}
            label={
                <>
                    <div
                        className={`${classes.briefLabel} ${
                            !reduceInfo ? ' d-flex align-items-center' : ''
                        }`}
                    >
                        <div className='d-flex align-items-center'>
                            {getLabelTitle()}
                            {isContentInBrief && !editable && value && (
                                <>
                                    <CopyItem
                                        copyLabel='Copy text'
                                        copiedText={getCopiedText()}
                                    />
                                    {!viewOnlyMode && (
                                        <Tooltip
                                            overlayClassName={`${
                                                classes.tooltipOverlay
                                            }
                                                   ${
                                                       darkMode &&
                                                       classes.tooltipOverlayDark
                                                   }`}
                                            title='Edit'
                                        >
                                            <div
                                                className={classes.copyIcon}
                                                onClick={handleSetEditable}
                                            >
                                                <EditIcon
                                                    className={classes.copyIcon}
                                                    color={iconColor}
                                                />
                                            </div>
                                        </Tooltip>
                                    )}
                                </>
                            )}
                            {isContentInBrief && editable && (
                                <Button
                                    type='link'
                                    size='small'
                                    onClick={handleEdit}
                                >
                                    Save
                                </Button>
                            )}
                            {(reduceInfo || !briefFormItem?.hasSubtitle) &&
                                getCheckMark()}
                        </div>
                        {briefFormItem?.hasSubtitle && getLabelRequirements()}
                    </div>
                </>
            }
        >
            {renderInput()}
        </Form.Item>
    );
};

export default BriefFormItem;
