import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { BubbleMenu, useCurrentEditor } from '@tiptap/react';
import { Button, Input } from 'antd';

import EditIcon from '../../../../../../resources/icons/EditIcon';
import RemoveIcon from '../../../../../../resources/icons/outline/RemoveIcon';

import { selectDarkMode } from '../../../../../../userBrowserSettings/store/browserSettings.selectors';

import { truncateString } from '../../../../../../utils/helpers/truncateHelper';
import { useIconColor } from '../../../../../../utils/hooks/useIconColor';

const linkAttribute = 'link';
const MAX_LINK_LENGTH = 36;

const classes = {
    popup: 'edit-link-popup',
    editPopupLink: 'edit-popup-link',

    editLinkWrapper: 'edit-link-wrapper d-flex',
    editLinkButton: 'add-link-button',

    iconWrapper: 'edit-link-icon-wrapper',
};

const EditLinkContent = ({ element }) => {
    const { editor } = useCurrentEditor();

    const iconColor = useIconColor();

    const darkMode = useSelector(selectDarkMode);

    const [isEditMode, setIsEditMode] = useState(false);
    const [linkValue, setLinkValue] = useState('');

    const getLinkHref = (linkHref = '') => {
        const isHrefContainHttp =
            linkHref.startsWith('//') ||
            linkHref.startsWith('https:') ||
            linkHref.startsWith('http:') ||
            linkHref.startsWith('tel:');

        return isHrefContainHttp ? linkHref : `//${linkHref}`;
    };

    const handleOnChange = (event) => {
        setLinkValue(event?.target?.value || '');
    };

    const handleOnClick = () => {
        editor
            .chain()
            .focus()
            .extendMarkRange(linkAttribute)
            .setLink({ href: linkValue })
            .run();

        setIsEditMode(false);
    };

    const removeLink = () => {
        editor.chain().focus().unsetLink().run();
    };

    return editor ? (
        <BubbleMenu
            className='bubble-menu'
            tippyOptions={{ duration: 100 }}
            editor={editor}
            shouldShow={({ editor: tiptapEditor }) =>
                tiptapEditor?.isActive(linkAttribute)
            }
        >
            <div
                className={`${classes.popup} ${darkMode ? 'dark-mode' : ''}`}
                contentEditable={false}
            >
                {isEditMode ? (
                    <div className={`${classes.editLinkWrapper}`}>
                        <Input
                            placeholder={'Type or paste a URL'}
                            onChange={handleOnChange}
                            value={
                                linkValue ||
                                editor.getAttributes(linkAttribute).href
                            }
                            onKeyUp={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                    handleOnClick();
                                }
                            }}
                        />
                        <Button
                            type='primary'
                            className={classes.editLinkButton}
                            onClick={handleOnClick}
                        >
                            Save
                        </Button>
                    </div>
                ) : (
                    <a
                        href={getLinkHref(
                            editor.getAttributes(linkAttribute).href
                        )}
                        target='_blank'
                        rel='noreferrer'
                        className={classes.editPopupLink}
                    >
                        {truncateString(
                            editor.getAttributes(linkAttribute).href,
                            MAX_LINK_LENGTH
                        )}
                    </a>
                )}
                <div className={`${classes.iconWrapper}`}>
                    <div onClick={() => setIsEditMode((prev) => !prev)}>
                        <EditIcon color={iconColor} />
                    </div>
                    <div onClick={removeLink}>
                        <RemoveIcon color={iconColor} />
                    </div>
                </div>
            </div>
        </BubbleMenu>
    ) : null;
};

export default EditLinkContent;
